$darkBG: #0D0D15;
$purpleBG: #41527e;

@mixin middle {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.how-to-play-panel {
    position: fixed;
    background: $darkBG;
    color: white;
    z-index: 15;

    .how-to-play-header {
        background: $purpleBG;
        text-align: center;

        .game-name {
            background: $darkBG;
            border: 1px solid white;
        }
		
		.close {
			position: absolute;
			top: 0;
			right: 0;
		}
		
		.drop-down-arrow {
			position: absolute;
			right: 0;
		}
    }

    .how-to-play-drop-down {
        position: absolute;
        text-align: center;
        box-sizing: border-box;
        background: $darkBG;
        border: 1px solid $purpleBG;
        display: none;
        overflow-y: scroll;
        z-index: 1;

        &.active {
            display: block;
        }

        & > div {
			cursor: pointer;
            padding: 0.5vh 0;
        }

        & > .active {
            background: $purpleBG;
        }
    }

    .how-to-play-contents {
        position: relative;

        text-align: justify;
        overflow-y: scroll;

        table {
            border-collapse: collapse;
            border: 1px solid white;
            width: 100%;
            text-align: center;

            tr.title-bg {
                background-color: #38761D;
                color: #fff
            }

            td {
                border: 1px solid white;
                padding-top: 1vh;
                padding-bottom: 1vh;

                .stadium-shape {
                    display: inline-block;
                    width: 8%;
                    text-align: center;
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                    margin-right: 4px;
                }
    
                .tie {
                    background-color: green;
                }
    
                .tian {
                    background-color: yellow;
                    color: black;
                }
            
                .di {
                    background-color: #B83432;
                }
            }
        }

        .video-tutorial-howtoplay {
            width: 100%;
        }
    }
}
 
//GENERIC MOBILE
.how-to-play-panel.sl-single-mobile {
    & > div {
        width: 100vw;
    }
	
    .how-to-play-drop-down {
        &.active {
            height: 40vh;
        }
	}
}

//MOBILE PORTRAIT
.how-to-play-panel.sl-single-mobile.portrait {
	top: 0;
	
    .how-to-play-header,
    .game-name {
        height: 6.6vh;
        line-height: 6.6vh;
		font-size: 3.5vh;
    }

    .how-to-play-header {
		.close {
			right: 2vw;
			font-size: 4vh;
		}
	
		.drop-down-arrow {
			right: 2vw;
			top: 7vh;
		}
	}
	
    .how-to-play-drop-down {
        top: 12vh;

        &.active {
            height: 40vh;
        }

        & > div {
            padding: 0.5vh 0;
        }
    }

    .how-to-play-contents {
        width: 90vw;
        padding: 2vw 5vw;
        height: 84vh;
		top: 7vh;
        margin-bottom: 20vw;
		img {
			width: 100%;
		}

        table {
            img {
                width: unset;
                height: 10vh;
            }
            .stadium-shape {
                width: 20vw;
            }
        }
    }
}

/*
    CUSTOM CLASSES FOR MOBILE PORTRAIT
*/

.how-to-play-panel.sl-single-mobile.portrait.p6,
.how-to-play-panel.sl-single-mobile.portrait.p7,
.how-to-play-panel.sl-single-mobile.portrait.p9,
.how-to-play-panel.sl-single-mobile.portrait.p12,
.how-to-play-panel.sl-single-mobile.portrait.m8,
.how-to-play-panel.sl-single-mobile.portrait.m14,
.how-to-play-panel.sl-single-mobile.portrait.m31,
.how-to-play-panel.sl-single-mobile.portrait.m35 {
    .how-to-play-contents table {
        p {
            font-size: 70%;
        }

        img {
            height: 3vh;
        }
    }
}

.how-to-play-panel.sl-single-mobile.portrait.m30,
.how-to-play-panel.sl-single-mobile.portrait.m33 {
    .how-to-play-contents table {
        p {
            white-space: nowrap;
        }

        img {
            height: 3vh;
        }
    }
}

.how-to-play-panel.sl-single-mobile.portrait.m33 
.how-to-play-contents table {
    p {
        font-size: 80%;
    }
}

//MOBILE LANDSCAPE
.how-to-play-panel.sl-single-mobile.landscape {
    top: 0;

    .how-to-play-header,
    .game-name {
        height: 10vh;
        line-height: 10vh;
    }

    .how-to-play-header {
        height: 15vh;
        margin-bottom: 0.5vh;
		.close {
			right: 1vw;
			font-size: 6vh;
		}
	
		.drop-down-arrow {
			right: 1vw;
			top: 12vh;
		}
	}

    .how-to-play-drop-down {
        top: 19vh;
        &.active {
            height: 50vh;
        }

        & > div {
            padding: 0.5vh 0;
        }
    }

    .how-to-play-contents {
        top: 5vh;
        width: 98vw;
        padding: 4vh 1vw;
        height: 70vh;
        margin-bottom: 6vh;

		img {
			width: 100%;
		}
		
        table {
            img {
			    width: unset;
                height: 12vh;
            }
            .stadium-shape {
                width: 20vh;
            }
        }
    }
}


//DESKTOP
.how-to-play-panel.desktop {
	z-index: 15;
	background-color: #151624;
	@include middle;
	position: fixed;
	width: 80vw;
	height: 80vh;

    .how-to-play-header,
    .game-name {
        height: 4vh;
        line-height: 4vh;
    }
    .game-name{
        display: none;
    }

    .how-to-play-header {
        position: fixed;
        background: #6c66fb;
        left: 10%;
        width: 80%;

		.close {
            float: right;
            // margin-top: 0.5vh;
            margin-right: 0.5vw;
            cursor: pointer;
		}
	
		.drop-down-arrow {
            display: none;
		}
	}

    .how-to-play-drop-down {
        background-color: #222336;
        top: 14vh;
        display: block;
        position: fixed;
        width: 13%;
        height: 76%;
        left: 10%;
        overflow-y: scroll;
        
        & > div {
            border-bottom: 1px solid #8b8bb3;
            border-right: 1px solid #8b8bb3;
            height: 4vh;
            border-left: none;
            padding-top: 2vh;
            padding-bottom: 0;
            margin-left: 5px;
    }

    & > .active {
        border-right: 5px solid #21deab;
        color: #21deab;
    }
}
    .how-to-play-contents {
        background-color: #151624;
        position: fixed;
        padding: 1vh 5vw 5vh 0vw;
        height: 68vh;
        width: 60%;
		top: 14vh;
        left: 25%;
      
        overflow-y: scroll;
		// border: 1px solid orange;

        li, p {
            // margin-top: 8px;
            // margin-bottom: 8px;
            line-height: 1.5;
        }

        li > table,
        li > .list-img {
            margin-bottom: 14px;
            margin-top: 14px;
        }

        h2 {
            text-transform: uppercase;
        }
		
		img {
			max-width: 100%;
        }

        table {
            td img {
                height: 12vh;
            }

            td div.multi-img {
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 8px;

                img {
                    width: auto;
                    height: 5vh;
                }

                &.multi-img-sm {
                    img {
                        height: 3vh;
                    }
                }

                &.multi-img-big {
                    img {
                        height: 10vh;
                    }
                }
            }
			
            td p img {
                width: auto;
                height: 5vh;
            }
        } 
    }
    
}

// add border to img only red white
.how-to-play-panel.m11 {
    table {
        td p img {
           border: 2px solid #000;
        }
    } 
}

.how-to-play-panel.m39 {
    table {
        td {
            max-width: 270px;
        }
    }

    .bet-el {
        margin: auto;
        padding: 4px 4px;
        width: max-content;
        min-width: 70px;

        &.red {
            background-color: #b93332;
        }

        &.blue {
            background-color: #2155a8;
        }

        &.tie {
            background-color: #32972f;
        }

        &.any {
            background-color: #929292;
        }

        &.total {
            background-color: #D88326;
        }

        &.odd-even {
            background-color: #7242C9;
        }

        &.big-small {
            background-color: #CAAA33;
        }
    }
}