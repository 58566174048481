.bet-type {
	position: absolute;
	font-family: Lato-Regular;
	color: #b6cb64;
	top: 0;
	margin: auto;
	width: 100%;
	text-align: center;
	line-height: initial;
}

.sl-multi-table .bet-type {
	font-size: 0.8vh;
}

.sl-game.sl-single-mobile.landscape .bet-type {
	font-size: 2vh;
}

.sl-single-game .bet-type {
	font-size: 9px;
}

.sl-single-mobile.portrait .bet-type {
	font-size: 0.5em;
    color: #d6fb84;
    top: 0;
    position: absolute;
    width: 100%;
    line-height: initial;
}

.sl-single-mobile .bet-type {
	font-size: 2vh;
}

.sl-game.v2.sl-single-game-v2,
.sl-game.v4.sl-single-game-v4 {
	& > .table-container > .sl-table .bet-type {
		color: #b6cb64;
	}
}
