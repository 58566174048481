.sl-single-mobile.portrait {
	.change-chip-left,
	.change-chip-right {
		position: relative;
		display: inline-block;
		left: unset;
		right: unset;
		top: unset;
		/* bottom: 2vh; */
		vertical-align: top;

		margin-top: 2vh;
		width: 8vw;
		height: 8vw;

		border: none;
		background: transparent;

		div {
			width: 12px;
			height: 22px;
		}
	}

	.change-chip-left div {
		background-position: -111px 22px;
	}

	.change-chip-right div {
		background-position: -121px 22px;
	}

	.chips-container {
		z-index: 9;
		position: fixed;
		width: 100%;
		bottom: 9vh;
		height: 10vh;
		text-align: center;

		left: 0;
		right: 0;

		border-left: none;
		border-right: none;
		background: #26273c;

		& > .chips-container-inside {
			overflow-x: scroll;
			overflow-wrap: normal;
			white-space: nowrap;

			width: 75vw;
			height: 9vh;
			padding: 0 3vw;
			padding-top: 1vh;

			& > .chip {
				& > img {
					height: 6vh;
					padding-left: 4px;
					padding-right: 4px;
					margin-top: 3px;
				}

				&.active {
					& > p {
						font-size: 2vh;
						line-height: 7vh;
					}

					& > img {
						height: 7vh;
					}
				}
			}
		}
	}
}
