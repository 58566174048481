@mixin middle {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

// Mobile Portrait
.mobile-home .sl-roadmap-square,
.mobile-home .sl-roadmap-circle,
.mobile-home .sl-roadmap-diamond {
	@include middle;
}

.sl-single-mobile.portrait .sl-roadmap-square,
.sl-single-mobile.portrait .sl-roadmap-circle {
	font-size: 2vh;
	
	position: relative;
	top: 1vw;

	width: 7vw;
	height: 7vw;
}

.sl-single-mobile.portrait .roadmap-table.v3 .sl-roadmap-circle {
	top: 0;
	width: 6vw;
	height: 6vw;
}

//Mobile Landscape
.sl-single-mobile.landscape .roadmap-table.v3 .sl-roadmap-square,
.sl-single-mobile.landscape .roadmap-table.v3 .sl-roadmap-circle {
	font-size: 3vh;
}

//Mobile Lobby
.mobile-home .sl-roadmap-square,
.mobile-home .sl-roadmap-circle {
	font-size: 1.5vh;
}
