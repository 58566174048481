.sl-game.v2.sl-single-game-v2 .game-statistics.dark {
	background: #141623;
	color: white;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.dark thead .back-text > span {
	color: #444;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.dark.m22,
.sl-game.v2.sl-single-game-v2 .dark .roadmap-container {
	background: #141623;
	/* background: cornflowerblue; */
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.dark td,
.sl-game.v2.sl-single-game-v2 .roadmap-table.dark.small tr:nth-child(even) td {
	border-bottom-color: black;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.dark td,
.sl-game.v2.sl-single-game-v2 .roadmap-table.dark.small td:nth-child(even) {
	border-right-color: black;
}


.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-red {
	/* background: #e26c82; */
	background: #dc1e42;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-blue {
	/* background: #649ad0; */
	background: #1a77d4;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-green {
	background: #23be35;
	/* background: #8fbd8f; */
}

.sl-game.v2.sl-single-game-v2 .dark .sl-baccarat-diagonal-line.sl-roadmap-green {
	background: transparent;
	border-bottom-color: #74ef74;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-baccarat-diagonal-line.sl-roadmap-green.tie {
	color: white;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-circle-hollow.sl-roadmap-red {
	/* border-color: #e26c82; */
	border-color: #dc1e42;
	background: transparent;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-circle-hollow.sl-roadmap-blue {
	/* border-color: #649ad0; */
	border-color: #1a77d4;
	background: transparent;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-slash.sl-roadmap-red {
	/* border-bottom-color: #e26c82; */
	border-bottom-color: #dc1e42;
	background: transparent;
}

.sl-game.v2.sl-single-game-v2 .dark .sl-roadmap-slash.sl-roadmap-blue {
	/* border-bottom-color: #649ad0; */
	border-bottom-color: #1a77d4;
	background: transparent;
}

.sl-game.v2.sl-single-game-v2 .dark .baccarat-prediction-container .sl-roadmap-red {
	border-color: #e26c82;
}

.sl-game.v2.sl-single-game-v2 .dark .baccarat-prediction-container .sl-roadmap-blue {
	border-color: #649ad0;
}

