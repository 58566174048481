.p12 .sl-bet {
	border: 1px solid #083D23;
}

.sl-table.p12 .top-row > div {
	display: inline-block;
	vertical-align: top;
}

.sl-table.p12 .top-row > div:first-child,
.sl-table.p12 .top-row > div:last-child {
	width: 15%;
}


.sl-table.p12 .top-row > div:first-child > div,
.sl-table.p12 .top-row > div:last-child > div {
	display: inline-block;
	width: 50%;
}

.p12-any-triple {
	width: 10%;
	
	& > .sl-bet {
		width: 100%;
	}
}

.p12-two-three,
.p12-three-three {
	position: relative;
	width: 15%;
}

.p12-two-three > .sl-bet,
.p12-three-three > .sl-bet {
	border: none;
	position: relative;
	width: 100%;
	display: block;
}

.p12-three-three > div > img {
	position: relative;
}

.p12-two-three > div > img {
	position: relative;
}

.p12-any-triple > .sl-bet > div:first-child {
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}

.p12-any-triple > .bet-type {
	line-height: initial;
}


.sl-table.p12 .amount-row > .sl-bet {
    width: percentage(1/14);
}

.sl-table.p12 .double-dice-row > .sl-bet {
	width: percentage(1/15);
	display: inline-block;
}

.sl-table.p12 .single-dice-row > .sl-bet {
	display: inline-block;
}


.p12-medium {
	position: relative;
}

.p12-medium > img {
	position: relative;
}

.p12-bottom {
	width: percentage(1/6);
}

.p12-bottom > img {
	margin-top: 10%;
}

.p12-text-medium {
	color: white;
	text-align: center;
}

.p12-bottom-text {
	position: relative;
}

// DESKTOP SINGLE TABLE
.sl-single-game .sl-table.p12 {
	.top-row {
		display: flex;
		align-items: center;
		height: 73px;

		.top-col-1 {
			height: 100%;
			display: flex;
		}
	}

	.p12-two-three,
	.p12-three-three {
		border: 1px solid #083D23;
		box-sizing: border-box;
	}

	.p12-any-triple {
		height: 100%;

		.sl-bet {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.sl-single-game .p12-two-three > .sl-bet > img,
.sl-single-game .p12-three-three > .sl-bet > img{
	height: 17px;
}

// DESKTOP MULTI TABLE
.sl-multi-table .p12 .top-row > div:first-child > div,
.sl-multi-table .p12 .top-row > div:last-child > div,
.landscape .p12 .top-row > div:first-child > div,
.landscape .p12 .top-row > div:last-child > div {
	display: block;
	width: 100%;
	
	& > div {
		width: 100%;
	}
}

.sl-multi-table .p12-top-half {
	font-size: 1.3vh;
}

.sl-multi-table .p12-top-half > div:first-child {
	line-height: 3.5vh;
}

.sl-multi-table .p12-two-three {
	line-height: 1.5vh;
}

.sl-multi-table .p12-two-three .sl-bet:first-child,
.sl-multi-table .p12-three-three .sl-bet:first-child {
	margin-top: 1vh;
}

.sl-multi-table .p12-two-three > div > img,
.sl-multi-table .p12-three-three > div > img {
	width: 0.9vw;
}

.sl-multi-table .p12-text-medium {
	font-size: 1em;
	line-height: 4.5vh;
}

.sl-multi-table .p12-three-three {
	width: 2.77vw;
	line-height: 1.5vh;
}

.sl-multi-table .p12-any-triple {
	line-height: 7vh;
}

.sl-multi-table .p12-medium {
	height: 4.5vh;
}

.sl-multi-table .p12-medium > img {
	width: 0.8vw;
	top: 0.8vh;
}

.sl-multi-table .p12-bottom > img { 
	width: 0.85vw;
}

.sl-multi-table .p12-medium-text-only {
	height: 3.7vh;
}

.sl-multi-table .p12-bottom-text {
	height: 1.2vh;
}

.sl-multi-table .p12-bottom .bet-type {
	font-size: 50%;
}



/*
#36322f grey
#fe0000 bright red
#901213 dark red
#164e67 dark blue
*/

/* SINGLE GAME */

.sl-single-game .amount-row > .sl-bet {
	height: 36px;
}

.sl-single-game .p12-two-three > div:first-child,
.sl-single-game .p12-three-three > div:first-child {
	margin-top: 10px;
}

.sl-single-game .p12 div:not(.double-dice-row) .sl-bet:not([group="d"]):not([group="t"]) {
	font-size: 24px;
}

.sl-single-game .p12 > div:first-child {
	vertical-align: top;
}

.sl-single-game .p12-top-half {
	width: 100%;
	height: 100%;
}

.sl-single-game .p12-top-half > div:first-child {
	line-height: 66px;
}

.sl-single-game .p12-medium-text-only {
	width: 100%;
	line-height: 45px;
}


.sl-single-game .p12-bottom {
    line-height: 28px;
}

.sl-single-game .p12-bottom > div:first-child > img {
	position: absolute;
	width: 20px;
	top: 4px;
	right: 8px;
}

.p12-bottom-text {
	position: relative;
	display: inline-block;
	width: percentage(1/3);
	vertical-align: top;
	
	& > .bet-type {
		position: relative;
	}
}

.sl-single-game .p12-medium > img {
	width: 20px;
	top: 0;
}

.sl-single-game .p12 .p12-medium .bet-type {
	line-height: 40px;
	text-align: right;
}


/* SINGLE MOBILE PORTRAIT */
.sl-single-mobile.portrait {
	.p12-portrait {
		.sl-bet {
			&.p12-top {
				height: 5vh;
			}
			&.p12-main-bets {
				height: 7vh;
			}
			&.p12-stacks {
				height: 6.5vh;
			}
			&.p12-tall {
				height: 12vh;
			}
		}
	}
}

// .portrait .p12-portrait .p12-top {
// 	height: 5vh;
// }

.portrait .p12-portrait .p12-top > img:first-child {
	margin-top: 1vh;
}

// .portrait .p12-portrait .p12-main-bets {
// 	height: 7vh;
// }

.portrait .p12-portrait .p12-main-bets > img {
	height: 5vh;
}

.portrait .p12-portrait .p12-top-bet-types {
	height: 3vh;
}

.portrait .p12-portrait .p12-top-bet-types .bet-type {
	line-height: 3vh;
}


// .portrait .p12-portrait .p12-tall {
// 	height: 12vh;
// }

.portrait .p12-portrait .sl-bet.p12-stacks > img {
	display: inline-block;
	height: 3.5vh;
	margin-top: 2vh;
	margin-left: -4vw;
}

.portrait .p12-portrait .sl-bet.p12-stacks > img:first-child {
	margin-left: 0;
}

.portrait .p12-portrait .p12-tall > img:first-child {
	margin-top: 2vh;
}

.portrait .p12-portrait .p12-tall > img:nth-child(2) {
	margin-top: 0.5vh;
}
.portrait .p12-portrait .p12-tall > img {
	display: block;
	left: 0;
	right: 0;
	height: 4vh;
	margin: auto;
}


/* SINGLE MOBILE LANDSCAPE */

.landscape .sl-table.p12 {
	.top-row {
		display: flex;
	}
}

.landscape .sl-table.p12 > div:nth-child(2) {
	height: 32px;
}

.landscape .p12-top-half {
	height: 36px;
	font-size: 10pt;
}

.landscape .p12-top-half > div:first-child {
	line-height: 34px;
}

.landscape .p12-two-three > div > img,
.landscape .p12-three-three > div > img {
	width: 17px;
}

.landscape .p12-two-three > div:first-child,
.landscape .p12-three-three > div:first-child {
	margin-top: 10px;
}

.landscape .p12-any-triple > .sl-bet {
	line-height: 70px;
}

.landscape .p12-any-triple > .sl-bet > div:first-child {
	display: inline-block;
	line-height: initial;
}


.landscape .p12-medium {
	height: 46px;
}

.landscape .p12-medium > img {
	width: 15px;
	
	&:first-child {
		margin-top: 40%;
	}
}

.landscape .p12-bottom > img {
	margin-top: 5%;
	width: 16px;
}

.landscape .p12-medium-text-only {
	height: 37px;
}

.landscape .sl-flex > div {
	width: percentage(100/14);
}

.landscape .sl-flex > div > div:first-child { 
	width: 100%;
}

.landscape .p12-text-medium {
	font-size: 1em;
	line-height: 37px;
}

.landscape .p12-bottom-text {
	height: 12px;
}
