// for use in tabs.scss
// custom tabs for 5D

.sl-single-game.machine-game .tabs.left .sl-machine-tab {
	line-height: 35px;
	border-radius: 0;

	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.left-wing,
	.right-wing {
		display: none;
	}
}


.landscape .tabs.left,
.landscape .tabs.right {
	width: 15%;
	display: inline-block;
	vertical-align: top;

	.left-wing,
	.right-wing {
		display: none;
	}

	.bet-label {
		font-size: 10px;
	}

	& > .sl-machine-tab {
		line-height: 21px;
		width: 100%;

		border-radius: 0;
		vertical-align: top;
	}

	& > .sl-machine-tab:first-child {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	& > .sl-machine-tab:last-child {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}

.landscape .tabs.right > .sl-machine-tab {
	line-height: 25px;
}
