// Hover tooltip
.tip span {
	opacity: 0;
	transition: 0.25s;

	position: absolute;
	white-space: nowrap;
	z-index: 10;
	padding: 5px 10px 5px 5px;
	font-size: 10px;
	background-color: #3e4058;
	color: white;

	// enable this while debugging
	pointer-events: none;
}

.lobby-game-preview .how-to-play > .tip > img {
	height: 1.6vh;
}

/* Tip Span Hover or Active Tooltips */
.tip:not(.roadmap-options):hover span,
.lobby-history-container .roadmap-options.tip span.active {
	opacity: 1;
}

.lobby-top-buttons,
.lobby-tooltip {
	z-index: 1;
}

.tip:not(.roadmap-options):hover .lobby-tooltip {
	left: 0;
	right: 0;
	margin: auto;
	width: fit-content;
}

.footer-left {
	.tip span {
		left: 0;
		bottom: 6vh;
	}

	.tip:hover {
		.settings + span {
			top: 6vh;
		}

		.info + span {
			top: 6vh;
		}

		.exit + span {
			position: absolute;
			top: 6vh;
			right: 0;
		}
	}
}

.tip:hover .fullscreen + span {
	top: 6vh;
}

/*Roadmap tooltips*/
.lobby-history-container .tip span.roadmap {
	position: absolute;
	left: 34px;
	top: -25px;
	width: 170px;
	text-align: center;
}

.lobby-history-container .roadmap-options.tip span {
	position: absolute;
	left: 0;
	// top: -25px;
	bottom: 100%;
	width: 170px;
}

.sl-tooltip {
	height: 20px;
	line-height: 20px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.8);
	border: 1px solid #870fff;

	z-index: -1;
	border-radius: 8px;
	position: absolute;
	width: 86px;
	left: -86px;
	right: -86px;
	margin: 0 auto;
	top: -20px;
	font-size: 11px;

	opacity: 0;
	transition: 0.4s all;

	pointer-events: none;

	.sl-single-game-v2 & {
		font-size: 2vh;
	}

	.sl-single-mobile-v2 & {
		font-size: 4vh;
	}

	.sl-bet:hover & {
		z-index: 3;
		opacity: 0.8;
	}

	&:after,
	&:before {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(0, 0, 0, 0);
		border-top-color: #000000;
		border-width: 5px;
		margin-left: -5px;
	}

	&:before {
		border-color: rgba(135, 15, 255, 0);
		border-top-color: #870fff;
		border-width: 6px;
		margin-left: -6px;
	}

	.transparent-chips .sl-bet:hover & {
		opacity: 0.25;
	}
}
