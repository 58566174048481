$path: "../assets/fonts";

@font-face {
    font-family: "OpenSans-Regular";
    src: url($path + "/open-sans/OpenSans-Regular.woff2");
}

@font-face {
	font-family: "Lato-Light";
	src: url($path + "/lato/Lato-Light.woff2");
}

@font-face {
	font-family: "Lato-Regular";
	src: url($path + "/lato/Lato-Regular.woff2");
}

@font-face{
	font-family: "Lato-Heavy";
	src: url($path + "/lato/Lato-Heavy.woff2");
}

@font-face {
	font-family: "Lato-Bold";
	src: url($path + "/lato/Lato-Bold.woff2");
}

@font-face {
	font-family: "CircularStd-Black";
	src: url($path + "/circular-std/CircularStd-Black.woff2");
}
