@import "./styles/mobile_lobby/game_box/index.scss";

@mixin middle {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.hide {
	display: none;
}

.portrait .mobile-home-spacer {
	height: 10vh;
}

.landscape .mobile-home-spacer {
	height: 25vh;
}

.running-text-box {
	background: #10111c;
	width: 100%;
}

.running-text-box .label-info {
	color: #3ea1c8;
	width: auto;
	display: inline-block;
}

.running-text-box .content-info {
	width: 85%;
	color: #8b8bb3;
	max-width: 85%;
	overflow: hidden;
	position: relative;
	display: inline-block;
}

.running-text-content {
	overflow: hidden;
	position: absolute;
	display: inline-block;
	width: 100vw;
}

.mobile-home .hamburger {
	display: inline-block;
	position: relative;
}

.mobile-home.landscape .hamburger {
	left: 1vw;
	top: 3.5vh;

	& > img {
		height: 10vh;
	}
}

.mobile-home.landscape.iphone .hamburger {
	top: 7vh;
}

.mobile-home.portrait .hamburger {
	left: 1vw;
	top: 0.5vh;

	& > img {
		height: 5vh;
	}
}

.mobile-home.portrait .running-text-box {
	font-size: 2vh;
	line-height: 4vh;
}

.mobile-home.landscape .running-text-box {
	font-size: 4vh;
	line-height: 8vh;
}

.running-text-content > div {
	color: white;
	white-space: nowrap;
}

.box-header {
	background-color: #1d1e2f;
	width: 100%;
	position: fixed;
	// z-index: 10;
}

//LOGO
.logo .img-logo {
	position: absolute;
}

.landscape .logo,
.portrait .logo {
	display: inline-block;
	vertical-align: top;
}

.landscape .logo .img-logo {
	position: relative;
	top: 0;
	left: 2vw;

	height: 15vh;
}

.portrait .logo .img-logo {
	position: relative;
	top: 0;
	left: 2vw;

	height: 5vh;
}

.user-info {
	color: #21deab;
	position: absolute;
	right: 1vw;
	top: 1vh;
}

.portrait .user-info {
	font-size: 1.4vh;
	width: 30vw;
	// min-width: 30vw;
}

.landscape .user-info {
	font-size: 4vh;
	width: 20vw;
	// min-width: 20vw;
}

.portrait .user-info.main-user-info {
	min-width: 30vw;
}

.landscape .user-info.main-user-info {
	min-width: 190px;
}

// Mobile Lobby
#wrapper {
	position: fixed;
	left: 0;
	transition: 0.5s ease-in-out;
	width: 100%;

	&.toggled {
		left: 312px;

		#sidebar-wrapper {
			left: 0;
		}
	}

	.scrollable-container {
		position: relative;
		// bottom: 0;
		// left: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		display: grid;
		padding: 5px;
		padding-bottom: 10vh;
	}

	.mobile-home.portrait {
		.scrollable-container {
			grid-template-columns: 1fr 1fr;
			max-height: 70vh;
			gap: 5px;

			&.game-group {
				grid-template-columns: 1fr;
			}
		}
	}

	.mobile-home.landscape {
		.scrollable-container {
			grid-template-columns: repeat(3, 1fr);
			max-height: 50vh;
		}
	}
}

#sidebar-wrapper {
	left: -312px;
	// width: 0;
	width: 312px;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background: #0d0d15;
	border-color: transparent;
	transition: 0.5s ease-in-out;
	z-index: 10;

	&::-webkit-scrollbar {
		display: none;
	}
}

#overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5;
}

.sidebar-nav {
	position: absolute;
	top: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;

	li {
		position: relative;
		line-height: 20px;
		display: inline-block;
		width: 100%;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			height: 100%;
			width: 0px;
			background-color: #24273c;
			transition: width 0.2s ease-in;
		}

		&:hover:before,
		& .link.active {
			background-color: #24273c;
			width: 100%;
			transition: width 0.2s ease-in;
		}

		& > .link {
			display: block;
			color: #dddddd;
			text-decoration: none;
			text-transform: capitalize;

			& > img,
			& > div {
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}

.portrait .sidebar-nav li > .link {
	padding: 1vh 4vw;
	& > img {
		margin-right: 2vw;
	}
}

.landscape .sidebar-nav li > .link {
	padding: 1vh 2vw;

	& > img {
		margin-right: 1vw;
	}
}

.sidebar-nav li .link:hover,
.sidebar-nav li .link:active,
.sidebar-nav li .link:focus {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
}

.sidebar-header {
	height: 60px;
	background-color: #1d1e2f;
}

.sidebar-left {
	top: 0;
	border-width: 0 0 1px;
	position: fixed;
	right: 0;
	left: 0;
}

.sidebar-decoration {
	height: 1px;
	background-color: #2b2b44;
	margin-right: 15px;
	margin-left: 15px;
}

.sidebar-nav .user-info {
	color: #21deab;
	right: 0px;
	font-size: 14px;

	width: 20px;
	height: 20px;
}

.user-info.user-icon {
	left: 15px;
	top: 30px;
	width: 22px;
	height: 22px;
}

.sidebar-nav .sidebar-user-info {
	color: #21deab;
	font-size: 16px;
}

.sidebar-nav .sidebar-online-user {
	color: #8c8bb3;
	font-size: 15px;
}

.portrait .sidebar-nav .sidebar-online-user {
	padding-left: 6vw;
}

.landscape .sidebar-nav .sidebar-online-user {
	padding-left: 3vw;
}

.sidebar-nav .sidebar-username,
.sidebar-nav .sidebar-user-balance {
	padding-left: 50px;
	width: 150px;
}

.dot-online {
	width: 9px;
	height: 9px;
	background: #22deab;
	border-radius: 50%;
	display: inline-block;
}

.lobby-sidebar-icon {
	width: 22px;
	height: 22px;
	display: inline-block;
}

.mobile-home .top-buttons {
	margin: 1vh 0;
	text-align: center;
	position: relative;
	display: flex;
}

.mobile-home > .top-buttons,
.mobile-home > .category-container {
	& > .button {
		font-weight: bold;
		background-color: #353750;
		border-radius: 1vh;
		border: 1px solid #847fff;
		color: white;
		margin: 0.5vh 1vw;
		// white-space: nowrap;

		display: flex;
		align-items: center;

		&.active {
			background-color: #6c66fa;
			border: 1px solid #847fff;
		}

		& > img {
			width: 30%;
		}

		& > span {
			flex-grow: 1;
			text-align: center;
		}
	}
}

.mobile-home.portrait > .top-buttons {
	& > .button {
		width: 25%;
		min-height: 3.5vh;
		font-size: 1.4vh;
	}
}

.mobile-home.landscape > .top-buttons {
	& > .button {
		width: 17%;
		min-height: 7vh;
		font-size: 3vh;
	}
}

.mobile-home > .category-container {
	z-index: 8;
	display: flex;
	flex-direction: column;
	line-height: initial;
	position: absolute;
}

.mobile-home.portrait > .category-container {
	top: 16vh;
	right: 0;
	width: calc(25% - 1px);
}

.mobile-home.landscape > .category-container {
	top: 26vh;
	right: 7vw;
	font-size: 3vh;
	width: 17%;
}

.mobile-home .game-hover-overlay {
	width: 65%;
	height: 80%;
	left: 20%;

	background: transparent;
	// background: rgba(255, 204, 12, 0.4);

	// z-index: 5;
	position: absolute;
	top: 0;

	&.group {
		background: transparent;
		// background: rgba(16, 255, 16, 0.4);
	}
}

.mobile-home .game-hover-overlay-phaser {
	background: transparent;
	// z-index: 5;
	position: absolute;
	top: 0;
	left: 20%;
	bottom: 0;
	right: 0;
}

.mobile-home.portrait .history-circle {
	width: 7vw;
	height: 3.5vh;
	margin: 1vh 0.5vw;
	line-height: 3.5vh;
	font-size: 2vh;
}

.mobile-home.landscape .history-circle {
	width: 4vw;
	height: 7.5vh;
	margin: 1.7vh 0.4vw;
	line-height: 7.5vh;
	font-size: 4vh;
}

.mobile-home.portrait .lobby-roadmap-table tbody > tr {
	border: none;
}

.mobile-home.portrait .lobby-roadmap-table tbody td {
	vertical-align: top;
	border: none;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}

/* LANDSCAPE */
.mobile-home.landscape {
	.sl-roadmap-square,
	.sl-roadmap-circle {
		font-size: 3vh;
		width: 2vw;
		height: 4vh;
	}
}

.mobile-home.landscape .sl-roadmap-diamond {
	width: 1.5vw;
	height: 3vh;
}

.mobile-home.portrait .roadmap-description .sl-roadmap-diamond,
.mobile-home.landscape .roadmap-description .sl-roadmap-diamond {
	display: inline-block;
}

.mobile-home.landscape .lobby-roadmap-table {
	& > tbody > tr {
		border: none;
	}

	tbody td {
		border: none;
		border-right: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
	}
}

.mobile-home.landscape .full-width .roadmap-description {
	border: 0;
	padding-left: 5%;

	& > div > div {
		position: relative;
		margin: unset;
		top: unset;
		left: unset;
		right: unset;
		bottom: unset;
		display: inline-block;
	}
}

.mobile-home.landscape .roadmap-table.v3 .sl-roadmap-circle {
	width: 100%;
	height: 100%;
}

/* END OF LANDSCAPE */

.sl-single-mobile.portrait .bet-label {
	top: 10%;
	position: relative;
}

.sl-single-mobile.portrait .sl-bet.notype .bet-label {
	top: 0;
}

.sl-single-mobile.landscape .sl-machine-tab .bet-label {
	font-size: 12px;
}

.sl-single-mobile.landscape .table-unsubmitted-tickets .bet-label {
	font-size: 16px;
}

/* 3D Previews */
.prev-3d-game {
	position: absolute;
	text-align: center;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.mobile-home.landscape .prev-3d-game img {
	height: 22vh;
}

.mobile-home.portrait .prev-3d-game img {
	height: 11vh;
}

.mobile-home .roadmap-description {
	color: white;
}

.mobile-home .roadmap-description .sl-roadmap-black {
	border: 1px solid white;
	box-sizing: border-box;
}

.mobile-home.landscape .roadmap-description {
	position: absolute;
	bottom: 0;
	left: 6vw;
	text-align: left;
	font-size: 4vh;
	height: 7vh;

	border-left: 1px solid #6b66ff;
	padding-left: 1vw;
	// border: 1px solid #6b66ff;
}

.mobile-home.portrait .roadmap-description {
	position: absolute;
	font-size: 1.6vh;
	bottom: 0;
	right: 12vw;
	line-height: 6vh;
}

.game-statistics.mobile-home {
	box-sizing: border-box;

	& > div {
		position: relative;
		display: inline-block;
		text-align: center;
		color: white;

		&.sl-roadmap-circle.sl-roadmap-red,
		&.sl-roadmap-circle.sl-roadmap-blue {
			position: relative;
		}
	}
}

.mobile-home.portrait .full-width {
	.game-statistics.mobile-home {
		& > div {
			width: 4vh;
			height: 4vh;
			line-height: 4vh;
		}
	}
}

.landscape .game-statistics.mobile-home {
	// width: 18vw;
	right: unset;
	left: 6vw;
	border-left: 1px solid #847fff;

	padding-left: 1vw;
	padding-bottom: 1vh;

	.sl-baccarat-bead-value {
		font-size: 4vh;
	}

	& > div {
		width: 7vh;
		height: 7vh;
		line-height: 7vh;

		font-size: 4vh;
	}
}

.portrait .scrollable-content {
	position: fixed;
	top: 13vh;

	width: 100vw;
	height: 68vh;
	overflow-x: hidden;
	overflow-y: scroll;

	// border: 1px solid white;
}

// Rounded corners for v2 games
.sl-single-mobile.portrait .table-container .sl-table.m22 {
	& .row:last-child .sl-bet:first-child {
		border-radius: 0 0 0 4vh;
	}

	& .row:last-child .sl-bet:last-child {
		border-radius: 0 0 4vh 0;
	}
}

.sl-single-mobile.portrait .table-container .sl-table.m22 {
	border-radius: 1vh 1vh 5vh 5vh;
}

.invisible-curtain {
	width: 100vw;
	height: 100vh;
	position: fixed;
}

.initial-size {
	width: initial !important;
	height: initial !important;
}

#gameStreaming {
	&.hidden {
		// display reloads the streaming
		display: none;
		// visibility: hidden;
	}

	&.portrait {
		position: fixed;
		top: 13vh;
		left: 0;
		width: 100vw;
		height: 26vh;
		opacity: 1;
		transition: 0.5s opacity;

		&.transparent {
			opacity: 0;
			// height: 20vh;
		}

		&.m22,
		&.m28,
		&.m38 {
			transform: scale(1.5) translateY(4vh);
		}

		&.m23 {
			transform: scale(1.5) translateY(3vh);
		}

		&.m24 {
			transform: scale(1.6) translate(1vw, 2vh);
		}

		&.m25 {
			transform: scale(1.5) translate(0, 4vh);
		}

		&.m27 {
			transform: scale(1.6) translate(0, 2vh);
		}
	}

	&.show-how-to-play {
		display: none;
	}

	&.iphone {
		position: fixed;
		transform: translate3d(0, 0, -100px);
		-webkit-transform: translate3d(0, 0, -100px);
		transform-style: preserve-3d;
		pointer-events: none;
	}

	&.landscape.iphone {
		width: 37vw;
		height: 37vh;
	}

	&.landscape.iphone.v2v4 {
		position: fixed;
		top: 10vh;
		height: 90vh;
	}

	&.landscape.iphone.chrome.v2v4 {
		top: 5vh;
		height: 95vh;
	}

	&.landscape {
		// position: absolute;
		position: fixed;

		width: 35vw;
		height: 19.6875vw;

		&.left-pane-hidden {
			left: -70vw;
		}

		&.left-pane-hidden.hugestreaming {
			left: 0;
			top: 10vh;
		}

		&.left-pane-visible {
			left: 0;
			top: 10vh;
		}

		&.hugestreaming {
			width: 70vw;
			height: 90vh;
		}

		&.v2v4 {
			position: fixed;

			z-index: -1;
			width: 100vw;
			height: 90vh;
			left: 0;
			top: 10vh;
		}
	}
}

// new css
.mobile-home {
	// header
	// landscape android
	&.landscape.android {
		// header
		.box-header {
			& > .header-top {
				overflow: hidden;
				height: 15vh;

				.hamburger {
					float: left;
					margin-top: 2.5vh;
					margin-left: 1vw;

					// todo : unset
					display: unset;
					position: unset;

					& > img {
						height: 10vh;
						margin-bottom: -5px;
					}
				}

				.logo {
					float: left;
					margin-left: 2vw;

					// TODO : Unset
					display: unset;
					margin-top: 0;
					vertical-align: unset;

					& > img {
						height: 15vh;
						margin-top: -4px;

						// TODO : unset
						position: static;
						top: unset;
						left: unset;
					}
				}

				.user-info.main-user-info {
					float: right;
					height: 14vh;
					margin-top: 0.5vh;
					margin-right: 2vw;

					// unset
					min-width: unset;
					width: unset;

					.username,
					.user-balance {
						height: 7vh;

						span {
							display: inline-block;
							height: 7vh;
							line-height: 7vh;
						}
					}
				}

				// TODO : unset .user-info
				.user-info {
					position: unset;
					right: unset;
					top: unset;
					top: unset;
					transform: unset;
				}
			}

			.running-text-box {
				padding: 0 1vw;

				.running-text-content {
					padding: 0 4px;
				}
			}
		}
	}

	// portrait android
	&.portrait.android {
		// header
		.box-header {
			& > .header-top {
				height: 5.5vh;
				overflow: hidden;

				.hamburger {
					float: left;
					margin-top: 0.5vh;

					// unset
					display: unset;
					position: unset;

					& > img {
						height: 4.5vh;
						margin-bottom: -5px;
					}
				}

				.logo {
					float: left;
					margin-top: 0.25vh;
					margin-left: 2vw;

					// unset
					display: unset;
					vertical-align: unset;

					& > img {
						height: 5vh;
						margin-top: -3px;

						// unset
						position: static;
						top: unset;
						left: unset;
					}
				}

				.user-info.main-user-info {
					float: right;
					margin-right: 2vw;
					height: 5.5vh;
					margin-top: 0.5vh;

					// unset
					min-width: unset;
					width: unset;

					.username,
					.user-balance {
						height: 2.5vh;

						span {
							height: 2.75vh;
						}
					}

					.username {
						span {
							line-height: 2.5vh;
						}
					}

					.user-balance {
						span {
							vertical-align: top;
						}

						.button-refresh-balance {
							top: 0;
						}
					}
				}

				// TODO : unset .user-info
				.user-info {
					position: unset;
					right: unset;
					top: unset;
				}
			}
		}
	}


	// android & iphone
	&.landscape.android,
	&.landscape.iphone {
		& > .box-header {
			& > .header-top {
				height: 15vh;
				display: flex;
				align-items: center;

				.hamburger {
					display: unset;
					position: unset;
					float: unset;
					height: 10vh;
					margin-left: 1vw;
					margin-right: 1vw;
					margin-top: 0;

					& > img {
						height: 100%;
						margin-bottom: 0;
					}
				}

				.logo {
					float: unset;
					margin-left: 0;
					flex: 1;
					height: 15vh;

					.img-logo {
						position: unset;
						height: 100%;
						margin-top: -5px;
					}
				}

				.user-info.main-user-info {
					float: unset;
					height: unset;
					position: unset;
					width: unset;
					min-width: unset;
					margin-top: 0;
					margin-right: 2vw;
				}
			}

			.running-text-box {
				padding: 0 1vw;
			}
		}
	}

	&.portrait.android,
	&.portrait.iphone {
		& > .box-header {
			& > .header-top {
				height: 5.5vh;
				display: flex;
				align-items: center;

				.hamburger {
					position: unset;
					display: unset;
					margin-left: 4px;
					margin-right: 4px;

					& > img {
						height: 4vh;
						margin-bottom: -4px;
					}
				}

				.logo {
					float: unset;
					display: unset;
					margin-top: 0;
					margin-left: 0;
					flex: 1;

					& > .img-logo {
						position: unset;
						height: 4vh;
						margin-top: 0;
					}
				}

				.user-info.main-user-info {
					float: unset;
					height: unset;
					width: unset;
					min-width: unset;
					margin-top: 0;
					margin-right: 4px;
					
				}
			}

			// .running-text-box {
			// 	padding: 0 1vw;
			// }
		}
	}
}
