@font-face {
  font-family: 'seamless glyph';
  src: url("fonts/icomoon.eot?fqin6t");
  src: url("fonts/icomoon.eot?fqin6t#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?fqin6t") format("truetype"), url("fonts/icomoon.woff?fqin6t") format("woff"), url("fonts/icomoon.svg?fqin6t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="sl-ic-"], [class*=" sl-ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'seamless glyph' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sl-ic-maximise:before {
  content: "\e900";
  color: #fff;
}

.sl-ic-minimize:before {
  content: "\e901";
  color: #fff;
}

.sl-ic-chevron-left:before {
  content: "\e902";
  color: #fff;
}

.sl-ic-close:before {
  content: "\e903";
  color: #fff;
}

.sl-ic-game-m6:before {
  content: "\e904";
  color: #fff;
}

.sl-ic-game-m7:before {
  content: "\e905";
  color: #fff;
}

.sl-ic-game-m8:before {
  content: "\e906";
  color: #fff;
}

.sl-ic-game-m10:before {
  content: "\e907";
  color: #fff;
}

.sl-ic-game-m11:before {
  content: "\e908";
  color: #fff;
}

.sl-ic-game-m13:before {
  content: "\e909";
  color: #fefffd;
}

.sl-ic-game-m14:before {
  content: "\e90a";
  color: #fff;
}

.sl-ic-game-m16:before {
  content: "\e90b";
  color: #fff;
}

.sl-ic-game-m19:before {
  content: "\e90c";
  color: #fff;
}

.sl-ic-game-p6:before {
  content: "\e90d";
  color: #fff;
}

.sl-ic-game-p7:before {
  content: "\e90e";
  color: #fff;
}

.sl-ic-game-p9:before {
  content: "\e90f";
  color: #fff;
}

.sl-ic-game-p12:before {
  content: "\e910";
  color: #fff;
}

.sl-ic-game-m20:before {
  content: "\e911";
  color: #fff;
}

.sl-ic-game-m21:before {
  content: "\e912";
  color: #fff;
}
