#errorPage .lostauth img {
	width: 99vw;
	height: 99vh;
}

.errorPage {
     z-index: -1;
}

.error {
     background-color: #171723;
     position: absolute;
}

.error:not(.box) img {
    width: 99vw;
	height: 98vh;
}

.box {
    color: #FFFFFF;
}

.message {
    font-size: 4vh;
    text-align: center;
    font-family: calibri;
}

.image {
    text-align: center;
	width: 99vw;
	height: 99vh;
	
	img {
		width: 40vw;
	}
}
