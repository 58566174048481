// GENERIC AND MULTI TABLE
.modal-container {
	position: absolute;
	background-color: rgba(0,0,0,0.3);
	z-index: 25;
}

.modal-win-amount {
	position: absolute;
	text-align: right;
	color: #ffef63;
	font-size: initial;
}

.betmodal-gradient {
	vertical-align: middle;
	position: absolute;
	background: rgb(1,3,13);
	top: 34%;
	width: 100%;
}

.betmodal-status-false {
	vertical-align: middle;
	position: absolute;
	background: rgb(1,3,13);
	
	color: white;
	text-align: center;
	padding: 3% 0 3% 0;
	top: 34%;
}

.betmodal-status-false button {
	border: none;
	border-radius: 4px;
	border-bottom: 4px solid rgb(13,70,79);
	color: white;
	background: rgb(30,159,189);
	cursor: pointer;
}

.betmodal-status-true {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#bfd255+0,8eb92a+50,72aa00+51,9ecb2d+100;Green+Gloss */
	background: #bfd255; /* Old browsers */
	background: -moz-linear-gradient(top, #bfd255 0%, #8eb92a 50%, #72aa00 51%, #9ecb2d 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #bfd255 0%,#8eb92a 50%,#72aa00 51%,#9ecb2d 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #bfd255 0%,#8eb92a 50%,#72aa00 51%,#9ecb2d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd255', endColorstr='#9ecb2d',GradientType=0 ); /* IE6-9 */

	font-family: Lato-Heavy;

	background: -webkit-linear-gradient(#16d2bf, #4af52e);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

// MULTI TABLE DESKTOP
.sl-multi-table .modal-container {
	width: 20vw;
	height: 24vh;
	top: 3vh;
	right: 0;
}

.sl-multi-table .modal-container img {
	width: 360px;
	height: 200px;
}

.sl-multi-table .modal-win-amount {
	width: 8vw;
	right: 7vw;
	bottom: 9.5vh;
}

.sl-multi-table .betmodal-status-false {
	width: 360px;
}

.sl-multi-table .betmodal-status-false button {
	width: 4vw;
	font-size: 3vh;
}

.sl-multi-table .betmodal-status-true {
	font-size: 32px;
}


// SINGLE GAME DESKTOP
.sl-single-game .modal-container img {
	width: 360px;
	height: 200px;
}

.sl-single-game .modal-container {
	position: absolute;
    width: 100%;
    height: 31%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    top: unset;
}

.sl-single-game .modal-container.win {
	height: 95%;
	bottom: 0;
	pointer-events: none;
}

.sl-single-game.machine-game .modal-container.win {
	background: transparent;
    height: 300px;
    width: 760px;
    left: 326px;
    top: 120px;
    bottom: unset;
    right: unset;
    pointer-events: none;
}

.sl-single-game .modal-container img {
	width: 554px;
	height: 303px;
}

.sl-single-game .modal-container .modal-win-amount {
	position: absolute;
    top: 175px;
	bottom: unset;
	left: 45px;
	right: 0;
	margin: auto;
	
	width: 135px;
	height: 35px;
	text-align: right;
	color: #ffef63;
	font-size: 30px;
}

.sl-single-game .modal-container .betmodal-status-true {
	font-size: 300%;
	padding-top: 8px;
	padding-bottom: 8px;
}

.sl-single-game .betmodal-status-false button {
	width: 100px;
	font-size: 32px;
}


// DESKTOP V2 SINGLE GAME
.sl-single-game-v2 .modal-container,
.sl-single-game-v4 .modal-container {
    position: fixed;
    top: unset;
    bottom: 5vh;
    left: 30.5vw;
    width: 39vw;
    height: 22vh;
    transform: perspective(68vw) rotateX(25deg);
	background: transparent;
	
	&.win {
		background: transparent;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 40vh;
	}
	
	& > img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		
		width: 40vw;
		height: auto;
	}
	
	& > .modal-win-amount {
		font-size: 200%;
		left: 21vw;
		top: 24vh;
	}
	
	
	&.maximised > .modal-win-amount {
		width: 12vw;
		height: 4vh;
		left: 15vw;
		bottom: 12vh;
		font-size: 3vh;
	}
}

.sl-single-game-v2.ipad .modal-container,
.sl-single-game-v4.ipad .modal-container {
	transform: translateZ(1000px);
	transform-style: preserve-3d;
}

.sl-single-game-v4 .modal-container.maximised > .modal-win-amount {
	left: 22vw;
	bottom: 7vh;
}

//Custom V2 game modals
.sl-single-game-v2 .modal-container.win,
.sl-single-game-v4 .modal-container.win {
	transform: unset;
}


.sl-single-game-v2 .betmodal-status-true {
	font-size: 6vh;
}

.sl-single-game-v2 .betmodal-status-false {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	
	height: 80px;
	font-size: 150%;
	
	& > button {
		width: 100px;
		height: 40px;
	}
}

//Custom V4 game modals
.sl-single-game-v4 .modal-container {
	transform: perspective(68vw) rotateX(45deg);

    width: 55vw;
    left: 22.5vw;
}

.sl-single-game-v4 .modal-container[pcode="m25"] {
	height: 30vh;
}

.sl-single-game-v4 .betmodal-status-true {
	font-size: 6vh;
}

.sl-single-game-v4 .betmodal-status-false {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	transform: scale(1.5);
}

// GENERIC MOBILE
.sl-single-mobile .betmodal-gradient {
	text-align: center;
}

.sl-single-mobile.portrait .betmodal-status-true {
	font-size: 5vh;
}

.sl-single-mobile-v2.landscape .betmodal-status-true {
	font-size: 12vh;
}

.sl-single-mobile .betmodal-status-false {
	width: 100%;
}

// MOBILE LANDSCAPE
/*
.sl-single-mobile.landscape .max .modal-container {
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	
	width: 360px;
	height: 200px;
	
	transition: all 0.4s;


	&.pane-visible {
		left: unset;
		right: 0;
		
		text-align: center;
		
		width: 59.8vw;
	}
	
	&.pane-hidden {
		transform: scale(1.7);
	}
	
	&.huge-streaming {
		transform: scale(0.65);
	}


	.betmodal-status-true {
		font-size: 10vh;
	}
}

.sl-single-mobile.landscape .max .modal-win-amount {
    // width: 160px;
    // right: 120px;
	width: 120px;
	left: 0;
	right: 0;
    bottom: 60px;
	
	margin: auto;
}

.sl-single-mobile.landscape .betmodal-gradient {
    height: 6vw;
    top: 0;
    bottom: 0;
    margin: auto;
}

.sl-single-mobile.landscape .modal-container {
	position: absolute;
 	top: 0;
	right: unset;
}
*/

.landscape .betmodal-gradient {
	font-size: 10vh;
}

// MOBILE PORTRAIT
.portrait .betmodal-status-false button {
	width: 25vw;
	font-size: 3vh;
}

.sl-single-mobile.portrait .betmodal-gradient {
    height: 6vh;
    top: 0;
    bottom: 0;
    margin: auto;
}

.sl-single-mobile.portrait .modal-container {
	position: fixed;
	top: 0;
	left: 0;
	right: unset;
	width: 100vw;
	height: 100vh;
	background: transparent;
}

.sl-single-mobile.portrait .modal-container img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 100%;
	height: auto;
}

.sl-single-mobile.portrait .modal-container .modal-win-amount {
	position: absolute;
	top: 9.5vh;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: right;
	width: 35vw;
	height: 6vh;
	font-size: 3vh;
}


// MOBILE V2 LANDSCAPE
.sl-single-mobile-v2.landscape .modal-container,
.sl-single-mobile-v4.landscape .modal-container {
    top: unset;
    bottom: 22.5vh;
    left: 21vw;
    width: 56.5vw;
    height: unset;
    text-align: center;
    transform: perspective(60vw) rotateX(27deg);

    & > img {
        width: unset;
        height: 50vh;
    }

    &.win {
        position: fixed;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 20vw;
        right: unset;
        width: 60vw;
        height: 40vh;
        background: transparent;

        & > .modal-win-amount {
            position: fixed;
            font-size: initial;
            width: 20vw;
            top: unset;
            bottom: 10.5vh;
            right: 23vw;
            left: unset;
        }
    }
}

.modal-container.borders {
	border: 1px solid cyan;
}

.modal-container.borders img {
	border: 1px solid orange;
}

.modal-container.borders .modal-win-amount {
	border: 1px solid red;
}

.sl-single-mobile-v4.landscape .m25 .modal-container.win > .modal-win-amount {
	left: 17vw;
	right: unset;
	top: 24vh;
}