@import
	"./colours",
	"./desktop",
	"./mobile_landscape",
	"./mobile_portrait",
	"./v2_landscape",
	"./v4_landscape",
	"./v2_v4_landscape";

.sl-single-mobile .chips-container {
	box-shadow: unset;
}

.change-chip-left,
.change-chip-right {
	position: absolute;
	margin: auto;
	border: 1px solid #4e4aae;
	background-color: #11101d;
	border-radius: 50%;
	width: 1.7vw;
	height: 3.2vh;
	cursor: pointer;

	div {
		width: 0.64vw;
		height: 2.2vh;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		margin: auto;
	}

	& > img {
		width: 1.5vw;
	}
}

.change-chip-left {
	left: 0;
	right: 25.5vw;
	top: 0;
	bottom: 0;

	div {
		background: url(icon-footer.png);
		background-position: -5.9vw 2.2vh;
		left: -2px;
	}
}

.change-chip-right {
	left: 29%;
	right: 0;
	top: 0;
	bottom: 0;

	div {
		background: url(icon-footer.png);
		background-position: -6.44vw 2.2vh;
		left: 2px;
	}
}

.chips-container > .chip {
	display: inline-block;
	position: relative;
	height: 7vh;
	vertical-align: top;
	margin-top: 1vh;

	& > p {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		line-height: 6vh;
		text-align: center;
	}

	& > img {
		width: 2.93vw;
		height: 6vh;
		padding-left: 0.2vw;
		padding-right: 0.2vw;
		margin-top: 0.3vh;
	}

	&.active {
		margin-top: 0;

		& > p {
			font-size: 2vh;
			line-height: 7vh;
		}

		& > img {
			width: 3.46vw;
			height: 7vh;
		}
	}
}

// used in MobileGame
.chips-container > .chips-container-inside {
	display: inline-block;
	vertical-align: top;

	.chip {
		display: inline-block;
		position: relative;
		height: 7vh;
		vertical-align: top;
		margin-top: 1vh;

		& > p {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			line-height: 6vh;
			text-align: center;
		}

		&.active {
			margin-top: 0;
		}
	}

	&::-webkit-scrollbar {
		height: 4px;
	}
}
