.tooltip-settings {
	// bottom: 9.18vh;
    // left: 0;
    // right: 54.5vw;
	// margin: auto;

    bottom: 9.18vh;
    left: 16.7vw;
    right: 0;
}

.tooltip-settings {
    position: fixed;
    text-align: left;
    width: 17vw;
    /* height: 30vh; */
	
    color: #8b8bb3;
    background-color: #2f2f4b;
    font-weight: normal;
    font-size: 1.3vh;
	border-radius: 0.8vh;
    box-sizing: border-box;
    /*border:1px solid #1d1e2f;box-shadow:0 1px 8px rgba(0,0,0,0.5);*/

    display: block;
	z-index: 15;
}

.tooltip-settings:after {
    content:'';
    position:absolute;
    width: 0.64vw;
    height: 1.2vh;
    left: 50%;
	// bottom: -12%;
    transform: translate(-50%,-50%) rotate(45deg);
    background-color: #2f2f4b;
    /*border:1px solid #1d1e2f;box-shadow:0 1px 8px rgba(0,0,0,0.5);*/
	z-index: -1;
}

.tooltip-settings:after, .tooltip-settings:before {
	border: solid transparent;
	content: " ";
	height: 3.2vh;
	width: 1.7vw;
	position: absolute;
}

.tooltip-settings .setting-label {
	width: 80%;
	margin: 6px 0;
	white-space: nowrap;
}

.tooltip-settings .separator {
	height: 10px;
}

.tooltip-settings .thick-separator {
	height: 30px;
}

.portrait .tooltip-settings .thick-separator {
	height: 1vh;
}

.tooltip-settings-header{
	background: #1d1e2f;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 0.8vh 0.64vw;
}

.tooltip-settings-header .label {
	color:#8b8bb3;
	font-size: 1.6vh;
	left: 5px;
	top: -6px;
	position: relative;
	display: inline-block;		
	vertical-align: middle;	
	font-weight: 600;
}

.tooltip-settings-header .close {
	position: absolute;
	cursor: pointer;
	right: 1vw;
	font-size: 2vh;
}

.sl-single-mobile.portrait .tooltip-settings-header .close {
	font-size: 3vh;
}

.body-category .label {
	color:#8b8bb3;
	font-size: 1.6vh;
	left: 5px;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.body-category select {
	position: absolute;
	right: 1.1vw;
}


// $settings_icon_path : "../../assets/img/settings_icons/img_icon_";
/* Generic and desktop icons */
.tooltip-settings .sl-icon-footer {
    height: 2.2vh;
    width: 1.17vw;
    display: inline-block;
    cursor: initial;
    margin-right: 0;
}


.tooltip-settings .btn-switch {
	font-size: 0.8vh;
	position: relative;
	display: inline-block;
}


.tooltip-settings .btn-switch {
	right: 7%;
	position: absolute;
	display: inline-block;
}

.tooltip-settings .btn-switch__radio {
	display: none;
}

.tooltip-settings .btn-switch__label {
	display: inline-block;	
	padding: .75em .5em .75em .75em;
	vertical-align: top;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5em;
	color: #666;
	cursor: pointer;
	transition: color .2s ease-in-out;
}

.tooltip-settings .btn-switch__label + .btn-switch__label {
	padding-right: .75em;
	padding-left: 0;
}

.tooltip-settings .btn-switch__txt {
	position: relative;
	z-index: 2;
	display: inline-block;
	min-width: 1.5em;
	opacity: 1;
	pointer-events: none;
	transition: opacity .2s ease-in-out;
}

.btn-switch__radio_no:checked ~ .btn-switch__label_yes .btn-switch__txt,
.tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label_no .btn-switch__txt {
	opacity: 0;
}

.tooltip-settings .btn-switch__label:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #a1a1a9;
	border-radius: 1.5em;
	box-shadow: inset 0 .0715em .3572em rgba(43,43,43,.05);
	transition: background .2s ease-in-out;
}

.tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label:before {
	/* background: #515179; */
	background: #57db7c;
}

.tooltip-settings .btn-switch__label_no:after {
	content: "";
	position: absolute;
	z-index: 2;
	top: .5em;
	bottom: .5em;
	left: .5em;
	width: 2em;
	background: #fff;
	border-radius: 1em;	
	pointer-events: none;
	box-shadow: 0 .1429em .2143em rgba(43,43,43,.2), 0 .3572em .3572em rgba(43,43,43,.1);
	transition: left .2s ease-in-out, background .2s ease-in-out;
}

.tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label_no:after {
	left: calc(100% - 2.5em);
	background: #fff;
}

.popup-v2 .tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label_no:after {
	background: #45CAF5;
}

.btn-switch__radio_no:checked ~ .btn-switch__label_yes:before,
.tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label_no:before {
	z-index: 1;
}

.tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label_yes {
	color: #fff;
}



/* MOBILE */
.sl-single-mobile .tooltip-settings {
	width: 220px;
	height: 300px;
	
	font-size: 13px;
	border-radius: 8px;
}

.sl-single-mobile.portrait .tooltip-settings {
	height: unset;
}

.sl-single-mobile .tooltip-settings:after {
	width: 9px;
	height: 12px;
}

.sl-single-mobile .tooltip-settings:after, .sl-single-mobile .tooltip-settings:before {
	height: 32px;
	width: 24px;
}

.sl-single-mobile .tooltip-settings .separator {
	height: 0;
}

.sl-single-mobile .tooltip-settings-header span.label {
	font-size: 16px;
}

.portrait .tooltip-settings .setting-label {
	margin: 0.5vh 0;
}

.landscape .tooltip-settings .setting-label {
	width: 40%;
}

.sl-single-mobile .body-category .label {
	font-size: 16px;
}

.sidebar-left .tooltip-settings-header span.close {
	right: 10px;
	position: absolute;
	display: inline-block;
	cursor: pointer;
}

.landscape .tooltip-settings-header span.close i {
	font-size: 3vw;
}

.sidebar-left .tooltip-settings-header span.close i {
	font-size: 3vh;
}

.tooltip-settings-body {
	padding: 1vh 1vw;
	font-size: 1.4vh;
}

.sl-single-mobile .tooltip-settings-body {
	padding: 10px 15px;
	font-size: 14px;
}

.landscape .tooltip-settings.btn {
	width: unset;
	height: unset;
	position: relative;
	background: transparent;
}

.landscape .tooltip-settings-body {
	width: 80vw;
	height: 85vh;
}


//Special classes for iOS
.tooltip-settings.landscape.iphone .sl-icon-footer {
	width: auto;
	height: 5vh;
}

.tooltip-settings.landscape.iphone .thick-separator {
	height: 3vh;
}

.landscape.iphone .tooltip-settings-body,
.sl-single-mobile .iphone .body-category .label {
	font-size: 3vh;
}



.sl-single-mobile .tooltip-settings .sl-icon-footer,
.sl-game-v2.landscape .tooltip-settings .sl-icon-footer {
	background-size: cover;
	height: 22px;
	width: 22px;
}



.sl-single-mobile .tooltip-settings .btn-switch {
	font-size: 8px;
}

.tooltip-settings.mobile {
	background: #0D0D15;
	color: #7784A4;
	top: 0;
	left: 0;
	bottom: initial;
	right: initial;
	width: 100%;
	border-radius: initial;
	box-shadow: 0 10px 16px rgba(0,0,0,0.5);
}

.landscape .tooltip-settings.mobile {
	left: unset;
	right: -50%;
	width: 50%;
	height: 100%;
	transition: 0.4s;
}

.landscape .tooltip-settings.mobile.active {
	right: 0;
}

.landscape .tooltip-settings.mobile.active.iphone {
	top: 10vh;
	height: 90vh;
}

.landscape .tooltip-settings .setting-label select {
	right: 3vw;
}

.portrait .tooltip-settings .setting-label select {
	right: 6vw;
}

.tooltip-settings.mobile:after,
.tooltip-settings.mobile:before {
	border: initial;
    content: initial;
    height: initial;
    width: initial;
    position: initial;
}

.mobile .tooltip-settings-header {
	background: #0D0D15;
	border-radius: initial;
	border-bottom: 1px solid #96A8D6;
}

.mobile .tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label:before {
	/* background: #3F4660; */
	background: lime;
}

.popup-v2 .tooltip-settings .btn-switch__radio_yes:checked ~ .btn-switch__label:before {
	background: #3F4660;
}



/* V2 LANDSCAPE */
.sl-game-v2.landscape .body-category .label {
	font-size: initial;
}

.sl-game-v2.landscape .tooltip-settings {
	position: relative;
	width: initial;
	height: initial;
}

.sl-game-v2.landscape .tooltip-settings .sl-icon-footer {
	width: 22px;
	height: 22px;
	background-size: cover;
}

.sl-game-v2.landscape .tooltip-settings .btn-switch__label:before {
	border-radius: 3vw;
}

.sl-game-v2.landscape .tooltip-settings .btn-switch__label {
	font-size: 3vh;
	margin: 0;
	padding: 1vw 1vh;
}

.sl-game-v2.landscape .tooltip-settings .btn-switch__txt {
	width: 2vw;
}

/* V4 LANDSCAPE */
/* Clone of V2 LANDSCAPE */
.sl-game-v4.landscape .body-category .label {
	font-size: initial;
}

.sl-game-v4.landscape .tooltip-settings {
	position: relative;
	width: initial;
	height: initial;
}

.sl-game-v4.landscape .tooltip-settings .sl-icon-footer {
	width: 22px;
	height: 22px;
	background-size: cover;
}

.sl-game-v4.landscape .tooltip-settings .btn-switch__label:before {
	border-radius: 3vw;
}

.sl-game-v4.landscape .tooltip-settings .btn-switch__label {
	font-size: 3vh;
	margin: 0;
	padding: 1vw 1vh;
}

.sl-game-v4.landscape .tooltip-settings .btn-switch__txt {
	width: 2vw;
}

/* .mobile */
/* B6C7F2 */



/* Lobby Mobile */
.sidebar-left .tooltip-settings {
	position: relative;
	text-align: left;
    width: 100%;
    height: unset;
	
    color: #8b8bb3;
    background-color: inherit;
    font-weight: initial;
    font-size: inherit;
	border-radius: initial;
    box-sizing: border-box;

    display: block;
	z-index: initial;

	bottom: 0;
	left: 0;
}

.sidebar-left .tooltip-settings .tooltip-settings-header {
	display: none;
}

.sidebar-left .tooltip-settings:after,
.sidebar-left .tooltip-settings:before {
	display: none;
}

.sidebar-left .tooltip-settings .sl-icon-footer {
    height: 22px;
    width: 22px;
}

.sidebar-left .tooltip-settings .setting-label {
	width: 80%;
}

.sidebar-left .tooltip-settings-body {
	width: unset;
	height: unset;
	padding: 1vh 2vw;
	font-size: 1.4vh;
}


/* LANDSCAPE */
.sidebar-left .body-category .label {
	font-size: 14px;
	color: #dddddd;
}

.sidebar-left.landscape .tooltip-settings .btn-switch {
	font-size: 2vh;
}

.sidebar-left.landscape .tooltip-settings .setting-label select {
	right: 2.5vw;
}


/* PORTRAIT */
.sidebar-left.portrait .tooltip-settings-body {
	padding: 1vh 4vw;
}

.sidebar-left.portrait .tooltip-settings .setting-label select {
	right: 5vw;
}
