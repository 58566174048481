.lotto-virtual-balls {
	z-index: 11;

    & > div {
        color: white;
        display: inline-block;
    }

    img {
        width: 70%;
    }
}

.sl-single-game .lotto-virtual-balls {
    position: absolute;
    left: 8px;
    top: 8px;

    width: 300px;

    & > div {
        width: 20%;
        font-size: 12px;
    }
}

.sl-single-game .lotto-virtual-balls.m31 {
	left: 460px;
    top: 200px;
    transform: scale(2.5);
	
	& > div {
		width: 25%;
	}
}



.sl-multi-table .lotto-virtual-balls {
	position: absolute;
	width: 100%;
}

.sl-multi-table .lotto-virtual-balls.m31 {
	line-height: 5vh;
}

.sl-multi-table .lotto-virtual-balls.m31 > div {
	width: 30%; //percentage(1/3);
}


.landscape .lotto-virtual-balls {
	position: fixed;
	text-align: center;
	
    top: 40vh;
    width: 45vw;
    left: 48vw;
	
	& > div {
		width: 20%;
	}

	&.hugestreaming {
		top: 28vh;
		left: 5vw;
		width: 60vw;
	}
	
	//Rotation: off & bet is closed
	&.bet-closed {
		transform: scale(0.65) translate(-23vw, -5vh);
	}
	

}


.portrait .lotto-virtual-balls {
    position: fixed;
    top: 39vh;
	
	width: 100%;

    & > div {
        width: 18vw;
        font-size: 2vh;
    }
}

.portrait .lotto-virtual-balls.m31 > div {
	width: percentage(1/3);
}

