.sl-single-game-v2 .virtual-cards {
	.cards {
		top: 14.4vh;
	}

	.cards > div {
		margin: 0 0.8vw;
		width: 5.21vw;
		height: 14vh;
	}

	.cards > div.lie {
		box-sizing: border-box;
		border: 4px solid white;
		margin: 0 1.2vw;
		border-radius: 4px;
		width: 4.9vw;
		height: 13.2vh;
	}

	.cards > div.lie.active {
		transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg) translate(-3.5vw, 7vh);
	}

	.cards > div.lie > img {
		margin-top: -0.4vh;
		margin-left: -0.21vw;
		height: 14vh;
	}

	.cards > div > img.backside {
		border-radius: 0.5vh;
	}

	.left.cards {
		left: 10vw;
	}

	.right.cards {
		right: 10vw;
	}

	.left-letter,
	.right-letter {
		font-size: 4.2vh;
		width: 3.2vw;
		height: 6vh;
		top: 7.6vh;
	}

	.left-letter.win,
	.right-letter.win {
		padding-left: 0.4vw;
		padding-right: 0.4vw;
		width: unset;

		& > img {
			margin-top: 1.2vh;
			width: 14.63vw;
			height: 4.8vh;
		}
	}

	.tie-container {
		top: 7vh;
		left: 0;
	}

	.tie-container > img {
		width: 36.17vw;
		height: 6vh;
	}

	.left-value,
	.right-value {
		top: 6.4vh;
		font-size: 6.4vh;
	}

	.left-letter {
		left: 10vw;
	}

	.left-value {
		left: 15vw;

		&.win {
			left: 27vw;
		}
	}

	.right-letter {
		right: 10vw;
	}

	.right-value {
		right: 15vw;

		&.win {
			right: 27vw;
		}
	}
}

.sl-single-game-v2 .virtual-cards.m23 .cards > div {
	margin: 0 8vw;
}

//Custom for Shio Fights
.sl-single-game-v2 .virtual-cards.m27 {
	.left-letter,
	.right-letter {
		border-radius: 4vh;
		width: 7vw;
	}
}
