@import "./game_game_portrait", "./game_game_landscape";

.mobile-home .game-game {
	width: 100%;
	height: 6vh;
	position: absolute;
	bottom: 0;
	background: #1d1e2f;
	border-top: 1px solid #847fff;

	display: flex;
	align-items: center;
	justify-content: space-between;

	p {
		color: #ffffff;
		font-size: 1.6vh;
		padding-left: 1%;
		line-height: 6vh;

		span {
			display: inline-block;
			vertical-align: middle;
			line-height: initial;
		}
	}

	.icon-game,
	p {
		display: inline-block;
		position: relative;
	}

	.icon-game {
		width: 28px;
		vertical-align: middle;
		padding-left: 4%;
		max-width: 5vw;
	}

	.game-game-right {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 12.8vh;

		flex-grow: 1;

		& > p {
			flex-grow: 1;
		}

		& > .fav-box {
			border-right: 1px solid #847fff;
			height: 100%;
	
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

}
