.table-unsubmitted-tickets {
	position: absolute;
	box-sizing: border-box;
	color: white;

	border: 1px solid #41527e;
	background: #26273d;
	z-index: 10;

	& > .header {
		position: relative;
		top: 0;
		width: 100%;
		text-align: center;
	}

	& > .scrollable-area {
		position: relative;
		overflow-y: scroll;
		width: 100%;
		height: 100%;
	}

	table {
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;

		thead td:first-child {
			width: 5%;
		}

		tr, td {
			position: relative;
			box-sizing: border-box;
			border: 1px solid #41527e;
		}

		tr.active {
			background: #41527e;
		}

		.blink {
			animation-name: blink-background;
			animation-duration: 1s;
			animation-iteration-count: infinite;
		}

		//Give white border if the row is selected
		tr.active .empty-guess > .empty {
			box-sizing: border-box;
			border: 1px solid #9aa8cb;
		}

		td.sl-bet {
			width: 100%;
			height: 100%;
		}

		td:not(.sl-bet) > div {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		td:not(.sl-bet) > .toggle-child {
			position: absolute;
			right: 0;
			cursor: pointer;

			&:hover {
				background: #26273d;
			}
		}


		img, .empty {
			vertical-align: middle;
		}

		img.delete {
			position: absolute;
			cursor: pointer;

			top: 0;
			right: 0;

			margin: 0;
		}

		.empty {
			border-radius: 50%;
			display: inline-block;
			background: #151624;
		}
	}

	.ticket-index {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		font-size: 80%;

		background: #24a74a;

		border-radius: 8px;
	}

	.add-ticket {
		cursor: pointer;
	}
}

.sl-single-game .table-unsubmitted-tickets {
	top: 48px;
	left: 325px;

	width: 760px;
    height: 415px;

	table {
		td.sl-bet {
			line-height: 42px;
			font-size: 25px;
		}

		td:not(.sl-bet) > div {
			width: 40px;
			height: 40px;
			line-height: 40px;

			margin: 2px;
		}

		img, .empty {
			width: 32px;
			height: 32px;
		}

		img.delete {
			width: 20px;
			height: 20px;
		}

		tr > td > .ticket-index {
			width: 24px;
			height: unset;
			line-height: unset;
		}
	}

	.add-ticket {
		height: 40px;
	}

	&[pcode="m30"] {
		left: 420px;
		top: 8px;
		width: 665px;
		height: 450px;
	}
}

// custom .table-unsubmitted-tickets
.sl-single-game .table-unsubmitted-tickets {
	&[pcode="m26"] table td:not(.sl-bet) > div {
		margin: 2px 0;
	}

	&[pcode="m26"][betgroup="order"] {
		table-layout: unset;
	
		thead > tr > td:nth-child(2) {
			width: 40%;
		}
	}

	&[pcode="m42"] {
		top: 8px;
		height: 456px;
	}
}


.landscape .tabs.left + .table-unsubmitted-tickets {
	width: 70%;
	height: 150px;
	display: inline-block;
	vertical-align: top;

	thead td:first-child {
		width: 12%;
	}

	thead td:last-child {
		width: 30%;
	}
}

.landscape .tabs.left + .table-unsubmitted-tickets table td:not(.sl-bet) > div {
	width: 22px;
	height: 22px;
	line-height: 22px;

	&:nth-child(5) {
		margin-right: 20px;
	}

	&.bet-label {
		width: 100%;
	}
}

.landscape .table-unsubmitted-tickets {
	position: relative;

    top: 0;
    left: 0;
    width: 100%;
    height: 120px;

	& > .header {
		display: none;
	}

	table {
		thead td {
			font-size: 10px;
		}

		text-align: center;

		td {
			font-size: 12px;
		}

		td.sl-bet {
			display: block;

			border: none;
		}

		td:not(.sl-bet) > div {
			width: 22px;
			height: 22px;
			line-height: 22px;
		}

		img, .empty {
			width: 80%;
			height: 80%;
		}

		img.delete {
			width: 10px;
			height: 10px;
		}

		tr > td > .ticket-index {
			width: 20px;
			height: unset;
			line-height: unset;
		}
	}

}

.table-unsubmitted-tickets[pcode="m26"][betgroup="order"] {
	table-layout: unset;

	thead > tr > td:nth-child(3) {
		font-size: 80%;
		width: 15%;
	}
}

.landscape .table-unsubmitted-tickets[pcode="m26"] thead td:first-child,
.landscape .table-unsubmitted-tickets[pcode="m29"] thead td:first-child {
	width: 8%;
}

.portrait .table-unsubmitted-tickets[pcode="m30"][betgroup="5dset"] thead > tr > td:nth-child(3) {
	width: 30%;
}

.portrait .table-unsubmitted-tickets {
	// position: fixed;

	// top: 13vh;
	// left: 0;

	width: 100vw;
	height: 26vh;
	
	& > .header {
		display: none;
	}

	table thead td:first-child {
		width: 10%;
	}

	table td.sl-bet {
		border: none;
	}

	// & > .scrollable-area {
		// height: 90%;
	// }



	table {
		td.sl-bet {
			line-height: 4.5vh;
			font-size: 2vh;
		}

		td:not(.sl-bet) > div {
			width: 3.5vh;
			height: 3.5vh;
			line-height: 3.5vh;

			margin: 0 0.1vh;

			&.bet-label {
				width: 100%;
			}
		}

		img, .empty {
			width: 3.5vh;
			height: 3.5vh;
		}

		img.delete {
			width: 2vh;
			height: 2vh;
		}

		tr > td > .ticket-index {
			width: 5vw;
			height: unset;
			line-height: unset;
		}
	}

	.add-ticket {
		height: 6vh;
	}
}

//Custom styles
.portrait .table-unsubmitted-tickets[pcode="m26"] {
	height: 40vh;

	&[betgroup="random"] {
		table > tr > td:not(.sl-bet) > div {
			margin: 1vh 0.5vw;
		}
	}

	&[betgroup="order"] {
		table-layout: unset;

		tr > td:not(.sl-bet) > div {
			margin: 1vh 0.2vw;
		}
	}
}

.portrait .table-unsubmitted-tickets[pcode="m29"] {
	height: 36vh;
}

.portrait.iphone .table-unsubmitted-tickets[pcode="m29"] {
	height: 31vh;
}

.portrait .table-unsubmitted-tickets[pcode="m30"] {
	height: 40vh;
}

.portrait .table-unsubmitted-tickets[pcode="m42"] {
	height: 32vh;
}
