.sl-single-game-v4 .m24-roadmap-container {
	position: fixed;
	left: -45vw; // hide before activated
	top: 32vh;

	width: 47vw;
	height: 20vh;

	background: white;

	transition: 0.5s all;
	z-index: 2;

	text-align: left;

	div {
		text-align: center;
	}

	.enlarged .fantan .sl-baccarat-bead-value {
		line-height: 3.5vh;
	}

	& > .roadmap-toggle-button {
		position: absolute;
		top: 18.5vh;
		right: -3.5vw;
		width: 20vh;
		height: 3vh;
		line-height: 3vh;
		cursor: pointer;
		display: block;
		transform: rotate(90deg) translate(-50%, -50%);
		// border: 1px solid orange;
	}

	& > div {
		position: relative;
		display: inline-block;
		vertical-align: top;
		transition: 0.5s all;
	}

	& > .left-group {
		opacity: 0;
		height: 100%;
	}

	.right-group > div:nth-child(2) > div {
		display: inline-block;
		vertical-align: top;
		text-align: left;
	}

	// .sl-game.v4.sl-single-game-v4 .m24-roadmap-container
	&.active {
		left: 0;

		& > .left-group {
			opacity: 1;
		}

		& > .right-group {
			border-right: 0.1vw solid black;
		}
	}

	&.dark {
		background: #141623;
		color: white;

		.game-statistics {
			background: #141623;
			color: white;
		}
	}
}
