.sl-multi-table .sl-table.m10,
.sl-single-mobile.landscape .sl-table.m10 {
	.m10-top-row > .m10-corners,
	.m10-middle-row > .big-coin {
		width: 20%;
	}

	.m10-bottom-row {
		height: 20%;
	}
}

.sl-multi-table .sl-table.m10 {
	// .sl-bet,
	// .m10-top-row > div,
	// .m10-middle-row > div {
	// 	height: 100%;
	// }

	.m10-top-row {
		.m10-top-text {
			font-size: 1.2vh;
		}

		.sl-bet > img {
			height: 3vh;
		}

		.m10-corners .bet-label > img {
			margin: 0 0.1vw;
		}
	}

	// .m10-top-row > .m10-corners,
	// .m10-middle-row > .big-coin {
	// 	width: 20%;
	// }

	.m10-middle-row > .big-coin > img {
		height: 3.5vh;
	}

	// .m10-bottom-row {
	// 	height: 20%;
	// }

	.sl-bet > img,
	.bet-label > img {
		height: 1.5vh;
	}

	// .m10-row-top > .sl-bet:first-child,
	// .m10-row-top > .sl-bet:last-child {
	// 	width: 3.94vw;
	// 	height: 6.4vh;
	// }

	// .m10-row-top > .sl-bet:first-child > img,
	// .m10-row-top > .sl-bet:last-child > img {
	// 	margin-left: 0.11vw;
	// }

	// .m10-row-middle > div > .sl-bet {
	// 	width: 1.06vw;
	// }

	// .m10-row-middle > div > img {
	// 	width: 1.6vw;
	// }

	// .big-coin {
	// 	width: 3.94vw;
	// 	height: 8.4vh;
	// }

	// .big-coin > img {
	// 	margin-top: 0.7vh;
	// }

	// .m10-row-middle .sl-bet .m10-row .sl-bet {
	// 	width: 2.93vw;
	// 	height: 4.1vh;
	// }

	// .m10-row-middle .sl-bet .m10-row:last-child {
	// 	border-bottom: none;
	// }

	// .m10-row-middle .sl-bet .m10-row .sl-bet > img {
	// 	margin-left: 0.1vw;
	// 	width: 0.74vw;
	// }

	// .m10-top-three > img {
	// 	width: 0.8vw;
	// }

	// .inner-top > div > img {
	// 	margin-top: 0.4vh;
	// 	width: 1.49vw;
	// }

	// .inner-top:first-of-type {
	// 	border-right: 0.05vw solid #083d23;
	// }

	// .inner-top > .m10-top-text {
	// 	position: relative;
	// 	color: white;
	// 	height: 2vh;
	// 	border-bottom: 0.1vh solid #083d23;
	// 	font-size: 1vh;
	// }

	// .inner-top > .m10-top-text div:not(.bet-type) {
	// 	position: relative;
	// 	top: 0.8vh;
	// }

	// .inner-top > div:nth-child(2),
	// .inner-top > div:nth-child(3) {
	// 	width: 1.91vw;
	// 	height: 4.2vh;
	// }

	// .inner-top > div:last-child {
	// 	border-right: none;
	// }

	// .m10-row-bottom {
	// 	border-bottom: none;
	// }

	// .m10-row-bottom > .sl-bet {
	// 	width: 9.84vw;
	// 	height: 4vh;
	// }

	// .m10-row-bottom > .sl-bet:last-child {
	// 	border-right: none;
	// }
}

/* SINGLE MOBILE LANDSCAPE */
.sl-single-mobile.landscape .sl-table.m10 {
	height: 190px;

	.m10-top-row {
		.m10-top-text {
			font-size: 10px;
		}

		.sl-bet > img {
			height: 30px;
		}

		.m10-corners .bet-label > img {
			margin: 0 2px;
		}
	}

	.m10-middle-row > .big-coin > img {
		height: 35px;
	}

	.sl-bet > img,
	.bet-label > img {
		height: 15px;
	}
}

// .sl-single-mobile.landscape .m10-row {
// 	border-bottom-width: 1px;
// }

// .sl-single-mobile.landscape .inner-top > .m10-top-text {
// 	height: 20px;
// 	font-size: 10px;
// }

// .sl-single-mobile.landscape .m10-row-top > .sl-bet:first-child,
// .sl-single-mobile.landscape .m10-row-top > .sl-bet:last-child {
// 	width: 74px;
// 	height: 64px;
// }

// .sl-single-mobile.landscape .m10-row-top > .sl-bet:first-child > img,
// .sl-single-mobile.landscape .m10-row-top > .sl-bet:last-child > img {
// 	margin-left: 2px;
// }

// .sl-single-mobile.landscape .m10-row-middle > div > .sl-bet {
// 	width: 20px;
// }

// .sl-single-mobile.landscape .m10-row-middle > div > img {
// 	width: 30px;
// }

// .sl-single-mobile.landscape .big-coin {
// 	width: 74px;
// 	height: 84px;
// }

// .sl-single-mobile.landscape .big-coin > img {
// 	margin-top: 7px;
// }

// .sl-single-mobile.landscape .m10-row-middle .sl-bet .m10-row .sl-bet {
// 	width: 55px;
// 	height: 41px;
// }

// .sl-single-mobile.landscape .m10-row-middle .sl-bet .m10-row .sl-bet > img {
// 	margin-left: 2px;
// 	width: 14px;
// }

// .sl-single-mobile.landscape .m10-top-three > img {
// 	width: 15px;
// }

// .sl-single-mobile.landscape .inner-top > div > img {
// 	margin-top: 4px;
// 	width: 28px;
// }

// .sl-single-mobile.landscape .inner-top:first-of-type {
// 	border-right-width: 1px;
// }

// .sl-single-mobile.landscape .inner-top > .m10-top-text {
// 	height: 20px;
// 	border-bottom-width: 1px;
// 	font-size: 0.7em;
// }

// .sl-single-mobile.landscape .inner-top > .m10-top-text div:not(.bet-type) {
// 	top: 8px;
// }

// .sl-single-mobile.landscape .inner-top > div:nth-child(2),
// .sl-single-mobile.landscape .inner-top > div:nth-child(3) {
// 	width: 36px;
// 	height: 42px;
// }

// .sl-single-mobile.landscape .m10-row-bottom > .sl-bet {
// 	width: 185px;
// 	height: 40px;
// }

// .sl-single-mobile.landscape .sl-bet {
// 	border-right-width: 1px;
// }
