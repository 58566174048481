.sl-header {
	position: relative;
	background: #141623;
	height: 6.2vh;
	color: #fff;
	border-bottom: 0.1vh solid #4e4aae;
}

.desktop-header {
	position: fixed;
	background: #1d1e2f;
	width: 100vw;
	top: 0;
	height: 11vh;
	z-index: 1;

	padding-left: 2vw;
	padding-right: 2vw;
	box-sizing: border-box;

	display: flex;
	align-items: center;

	.img-logo {
		// display: inline-block;
		// vertical-align: top;
		// margin: 3vh 2vw;
		width: 12vw;
		height: auto;
		margin-top: -5px;
	}

	.desktop-header-right {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.desktop-header-info {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.box-button-header {
			position: static;
			display: flex;
			align-items: center;
			margin-left: 2vw;

			.tip.lang-small {
				display: flex;
				align-items: center;
			}
		}

		.box-header-desc  {
			display: flex;
			align-items: center;
			margin-top: 0;
			width: unset;

			&:first-child {
				margin-right: 16px;
			}
		}
	}

	.button-refresh-balance {
		top: 3px;
	}
}

.desktop.machine .desktop-header {
	width: 95vw;
	// padding-right: 1vw;
	// padding-left: 1vw;

	& > .box-running-text-header {
		width: 30vw;
	}

	.desktop-header-right {
		.box-button-header {
			margin-left: 4vw;
		}
		.box-header-desc  {
			&:first-child {
				margin-right: 32px;
			}

			& > div {
				p.nickname,
				p.balance {
					font-size: 40px;
				}
	
				.button-refresh-balance {
					top: 0;
				}
			}
		}
	}
}

.sl-header-top {
	background: #2f2f4b;
	height: 5vh;

	div {
		font-size: 1.2vh;
	}

	& > .box-running-text-header {
		height: 4vh;
		padding-top: 1vh;
		padding-bottom: 1vh;
		width: 62%;
		margin-left: 20px;
		margin-right: 56px;
	
		display: inline-block;
		overflow: hidden;

		& > .content-running-text-header {
			border-radius: 2vh;
			background: #26273c;
			border: 1px solid #1e202f;
			height: 3vh;
			position: relative;
			overflow: hidden;
		}
	}
}

.desktop-header > .box-running-text-header {
	display: inline-block;
	width: 35vw;
	margin-left: 2vw;
	margin-right: 0.5vw;
	overflow: hidden;
	// margin-top: 3.4vh;

	& > .content-running-text-header {
		border-radius: 20px;
		background-color: #26273c;
		border: 1px solid #1e202f;
		height: 4vh;
		position: relative;
		overflow: hidden;
	}
}

.box-header-desc {
	display: inline-block;
	vertical-align: top;
	width: 14vw;
	margin-top: 3.5vh;

	&.machine-lobby {
		width: unset;
		margin-right: 20px;
	}

	.FullScreen.desktop.ipad & {
		width: 16vw;
	}

	& > img {
		// width: 32px;
		display: inline-block;
		margin-right: 8px;
	}

	& > div {
		display: inline-block;
		line-height: normal;
		vertical-align: top;

		& > h4 {
			color: #8b8bb3;
			font-size: 1.2vh;
			margin: 0;
			top: -4px;
		}

		& > p {
			color: #21deab;
			font-size: 1.4vh;
			margin: 0;
			right: 8px;

			&.balance {
				font-size: 1.2vh;
				display: inline-block;
			}
		
			&.nickname {
				display: inline-block;
			}
		}
	}
}

// TIME ON HEADER
.sl-header {
	position: fixed;

	.box-time-header {
		position: absolute;
		color: #8685ad;
		padding: 1.5vh 0.8vw;
		border-right: 1px solid #24273e;
		right: 11vw;
		top: 0;
	}
}

body.machine {
	.desktop-header {
		.box-button-header {
			right: 7vw;
		}
	}
}

// .sl-header.v3 {
// 	.box-button-header {
		
// 	}
// }

// HEADER BUTTONS
.box-button-header {
	position: absolute;
	top: 1.5vh;
	right: 3vw;

	.tip {
		background: transparent;
		border: none;
		width: 2.5vw;
		height: 5vh;
		cursor: pointer;
		display: inline-block;
		vertical-align: top;
	}

	&.game {
		top: 0;
	}

	& > .lang-small {
		z-index: 1;
		position: relative;

		button {
			background: transparent;
			border: none;
		}

		img {
			width: 2vw;
			cursor: pointer;
			border-radius: 50%;
			border: 1px solid white;
		}

		button[disabled] > img {
			filter: saturate(0);
			cursor: not-allowed;
		}

		div {
			position: absolute;
			background: #1d1e2f;

			top: 0;
		}
	}
}

.box-button-header.v5 {
	right: 2vw;

	.tip {
		width: 2.8vw;

		.sl-icon-header {
			margin-right: 0;
		}
	}
}

.desktop.machine {
	.box-button-header {
		right: 2vw;
	}	

	.box-button-header.v5 {
		right: 3vw;
	}

	.sl-header.v3 .box-time-header {
		right: 8vw;
	}
}

.desktop-header .box-button-header {
	top: 3vh;

	.tip {
		width: 2.8vw;

		&.lang-small {
			margin-right: 8px;
		}
	}
}

.FullScreen.desktop.ipad .box-button-header {
	top: 3.5vh;
}

// RUNNING TEXT
.box-running-text-header {
	.label-running-text-header {
		color: #47c8f6;
		padding: 0.6vh 0.64vw;
		font-weight: 600;
		background-color: #26273c;
	}

	.running-text-header {
		color: #8b8bb3;
		padding: 1vh 0;
		white-space: nowrap;
		left: 0;
		top: -0.3vh;

		width: 100%;
	}
}

.label-running-text-header,
.running-text-header {
	position: absolute;
}

.desktop-header {
	.label-running-text-header,
	.running-text-header {
		font-size: 2vh;
	}
}

// Any Header Item
.sl-header > div:not(.game) {
	background: none;
	border: none;
	// display: inline-block;
}

/*------------------- POP UP GAME ----------------------*/
/* Popup container - can be anything you want */
.gameBox {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: 58vw;
	overflow: hidden;
	white-space: nowrap;

	& > div {
		white-space: normal;
	}

	.game {
		position: relative;

		width: 3.6vw;
		vertical-align: top;
		display: inline-block;
		margin-right: 0.5vw;
		background: transparent;
		border: none;
		cursor: pointer;
		box-sizing: border-box;

		& > div:nth-child(2) {
			color: #8685ad;
			font-weight: initial;
			font-size: 1.2vh;
			height: 3.2vh;
			line-height: 3.2vh;
		}

		& > div > div {
			display: inline-block;
			line-height: initial;
		}

		&.active {
			border-bottom: 0.3vh solid #6c66fb;
		}

		&.background {
			border-bottom: 0.3vh solid #21deac;
		}

		&.active > p {
			color: #6c66fb;
		}

		& > img {
			height: 3vh;
		}
	}

	.badge-new {
		position: absolute;
		top: 0;
		right: 0;

		background: #d44c49;
		font-size: 0.8vh;
		color: white;
		width: 1.49vw;
		height: 1.4vh;
		line-height: 1.4vh;

		border-radius: 0.6vh;
	}

	.has-group {
		// border: 1px solid white;
		overflow: visible;
	}

	.header-game-group-container {
		z-index: 15;

		position: relative;
		border: 1px solid white;
		box-sizing: border-box;

		top: 0.2vh;

		background: #141623;

		border-bottom-left-radius: 2vh;
		border-bottom-right-radius: 2vh;

		border-color: #4e4aae;

		width: 15vw;
	}

	.close-header-overlay {
		// background: orange;
		position: fixed;
		top: 8.5vh;
		left: 17vw;
		width: 63vw;
		height: 8vh;
	}
}

.cursor {
	color: #21deac;
	display: inline-block;
	line-height: 8vh;
	padding: 0 1vw;
	cursor: pointer;
	font-size: 4vh;
}

.roundedCyan {
	border-radius: 100%;
	background-color: #21ddab;
	height: 1vh;
	width: 0.53vw;
	display: inline-block;
	vertical-align: top;
	margin: 0.4vh 0px;
	margin-right: 0.37vw;
}

.v5-button-header {
	position: absolute;
	left: 1vw;
	height: 100%;
	display: flex !important;
	align-items: center;
	justify-content: space-between;

	& > button {
		background: transparent;
		border: none;
	}
}

.sl-box-game {
	position: relative;
}

.sl-game-container {
	display: inline-block;
	position: relative;
	vertical-align: top;
}

.sl-header.v3 {
	position: relative;

	width: 100vw;
	height: 8.5vh;
	z-index: 1;

	top: 0;

	transition: 0.5s;

	&.autohide {
		position: fixed;
	}

	&.hidden {
		top: -8vh;
	}

	.sl-box-game {
		text-align: center;
		position: absolute;
		right: 20vw;
		top: 0;

		.cursor {
			line-height: 8.5vh;
		}
	}

	.auto-hide-eye {
		height: 3vh;
		cursor: pointer;
		position: relative;
		top: 2.75vh;
		left: 12vw;
	}

	.box-time-header {
		right: 7vw;
		width: 11vw;
		line-height: 3vh;
		top: 2.75vh;

		padding: 0 0.8vw;
	}

	.box-time-header.v5 {
		right: 8vw;
	}

	.game > img {
		height: 3vh;
		max-height: 3vh;
		max-width: 4vw;

		margin-top: 1.5vh;
	}

	.box-time-header {
		margin: auto;
		font-size: 1.2vh;
	}

	.box-button-header {
		top: 1.75vh;

		.tip {
			width: 2.8vw;
		}
	}

	.stream-control-header {
		position: absolute;
		top: 1.5vh;
		bottom: 0;
		right: 7vw;
		cursor: pointer;
		vertical-align: middle;

		& > * {
			display: inline-block;
			vertical-align: middle;
		}

		& > img {
			width: 1vw;
			position: relative;
			right: 0.5vw;
		}

		& > div {
			font-size: 1.1vh;
			color: #8685ad;
		}

		& > div > .active {
			color: #57db7c;
		}
	}
}

.sl-header {
	.gameBox .badge-new {
		top: 1vh;
	}

	.box-button-header.game {
		width: unset;
	}
}
