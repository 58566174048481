.video-tutorial-container {
    position: relative;
    width: 13vw;
}

.video-tutorial {
    object-fit: initial;
    width: 13vw;
}

.btn-close {
    font-family: 'seamless glyph' !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    position: absolute;
    top: 4px;
    right: 6px;
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.btn-close::before {
    content: "\e903";
    color: #151624;;
}

.video-tutorial-container:hover .btn-close {
    display: block;
}