//Todo: put all custom .iphone classes here

/* .iphone-swipe-container video { */
.iphone-swipe-container {
	position: relative;
	top: 0;
	left: 0;
	
	//Desktop Chrome simulator
	z-index: 250;
	
	//iPhone device
	transform: translate3d(0, 0, 250px);
	transform-style: preserve-3d;

	//iPhone X 375
	//iPhone 6/7/8 375
	//iPhone 6/7/8 Plus 414
	
	img {
		width: 100vw;
		height: 500px;
		display: block;
		
		opacity: 0.8;
	}
}

//Todo: turn off scroll after swipedUp: true
.iphone-swipe-container.portrait {
	position: absolute;

	img {
		height: 900px;
	}
}





.sl-game-v2.landscape.iphone > .max .table-container.relaxed {
	//Dev
	-webkit-transform: scale(0.8) perspective(60vw) rotateX(27deg) translate3d(0, 0, 15px) scale(0.9);
	transform: scale(0.8) perspective(60vw) rotateX(27deg) translate3d(0, 0, 15px) scale(0.9);
	transform-style: preserve-3d;
}

.sl-game-v4.landscape.iphone > .max .table-container.relaxed {
	//Dev
	-webkit-transform: scale(0.8) perspective(60vw) rotateX(27deg) translate3d(0, 0, 15px);
	transform: scale(0.8) perspective(60vw) rotateX(27deg) translate3d(0, 0, 15px);
	transform-style: preserve-3d;
}


//Mobile landscape
.iphone.landscape .sl-mobile-icon-header {
	width: 3.5vw;
	background-size: 6vh;
}


//for iPhone only
// .sl-game.sl-single-mobile.iphone.landscape > .toggle-left-pane.visible {
	// transform: rotate(90deg) translateX(-40vh) translateY(-1vw);
// }



//Mobile portrait
.iphone.portrait .scrollable-content #game {
	padding-bottom: 9vh;
}

.iphone.portrait .chips-container {
	top: 73vh;
	bottom: unset;
}

.iphone.portrait .controller {
	top: 83vh;
	height: 30vh;
	bottom: unset;
}

.iphone.portrait .sl-mobile-icon-header {
	width: 7vw;
	height: 3.5vh;
	background-size: 3.5vh;
}

//Lotto & SB Special
.iphone.portrait .landscape-bottom-left-container[pcode="m26"],
.iphone.portrait .landscape-bottom-left-container[pcode="m29"] {
	height: 43vh;
}

.iphone.portrait .table-unsubmitted-tickets[pcode="m26"] {
	height: 33vh;
}

.iphone.portrait .lotto-virtual-balls {
	top: 33vh;
}

.iphone.portrait .lotto-drawing {
	top: 26vh;
}

.iphone.portrait .landscape-bottom-left-container[pcode="m26"] .history-container,
.iphone.portrait .landscape-bottom-left-container[pcode="m26"] .min-max-container,
.iphone.portrait .landscape-bottom-left-container[pcode="m29"] .history-container,
.iphone.portrait .landscape-bottom-left-container[pcode="m29"] .min-max-container {
	height: 39vh;
}




