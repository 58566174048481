.table-m36 {
    .sl-bet {
        border: 1px solid #0a3c21;
    }

    .big-centre,
    .side {
        display: inline-block;
        vertical-align: top;
    }

    .big-centre {
        width: 85%;
    }

    .big-centre > div > div {
        width: percentage(1 / 12);
    }

    .big-centre > .bottom > div {
        width: percentage(1 / 3);
    } 

    .big-centre > .bottom-6 > div {
        width: percentage(1 / 6);
    }

    .side {
        width: 15%;

        & > .sl-bet {
            display: block;
        }
    }
}

// single table
.sl-single-game .table-m36 .sl-bet {
    font-size: 24px;
    height: calc(200px / 5);
    line-height: calc(200px / 5);
}

// multi table
.sl-multi-table .table-m36 .sl-bet {
    height: calc(19vh / 5);
    line-height: calc(19vh / 5);
}

// landscape
.landscape .table-m36 .sl-bet {
    height: calc(190px / 5);
    line-height: calc(190px / 5);
}

// portarit
// only for portrait, set bet label taller in the middle
.portrait .table-m36 .taller {
    width: 100%;
    height: 24vh;
    line-height: 24vh;

    & > .bet-label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        line-height: inherit;
    }
}