::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background: #2e2f4b;
}

::-webkit-scrollbar-thumb {
	background: #41527e;
}

::-webkit-scrollbar-thumb:hover {
	background: #ccc;
}

body.machine {
	&::-webkit-scrollbar {
		width: 5vw;
	}

	&::-webkit-scrollbar-thumb {
		border: 5px solid #26273c;
		// box-shadow: inset 0 0 10px black;
		border-radius: 10px;
		background: linear-gradient(to right, #51679e, #41527e);

		// &::after {
		// 	content: "heh";
		// }
	}
}

.popup::-webkit-scrollbar {
	height: 0.6vh;
}

// .portrait .scrollable-content::-webkit-scrollbar {
	// width: 0;
// }

.history-m14::-webkit-scrollbar {
	width: 0;
}

.history-m14 .box-results::-webkit-scrollbar {
	width: 6px;
}

.portrait .chips-container .chips-container-inside::-webkit-scrollbar {
	height: 0; 
}

.roadmap-table.m25 tbody::-webkit-scrollbar {
	height: 0.4vh;
}

.lobby-history-roadmap4 .number-container::-webkit-scrollbar,
.sl-game.v4:not(.sl-single-game-v4) .m24_roadmap .number-container::-webkit-scrollbar {
	height: 0.4vh;
}

.sl-game.sl-single-mobile.portrait .m24-history .number-container::-webkit-scrollbar,
.sl-game.v4.sl-single-game-v4 .m24-history-container .number-container::-webkit-scrollbar {
	height: 0;
}

.sl-game.v4.sl-single-game-v4 .m24-history-container::-webkit-scrollbar-track {
	background: #2e2f4b;
	
}

.sl-game.v4.sl-single-game-v4 .m24-history-container::-webkit-scrollbar-thumb {
	background: #41527e;
	
}

.sl-game.v4.sl-single-game-v4 .m24-history-container::-webkit-scrollbar-thumb:hover {
	background: #ccc;
}

.sl-single-game .history-m14 .box-results::-webkit-scrollbar {
	width: 10px;
}

.sl-single-mobile-v2.landscape .chips-container-inside::-webkit-scrollbar,
.sl-single-mobile-v4.landscape .chips-container-inside::-webkit-scrollbar {
	width: 0;
}

.sl-game.v4:not(.sl-single-game-v4) .roadmap-table.m25::-webkit-scrollbar {
	height: 0.4vh;
}

.sl-game-v4.landscape .roadmap-container.m24-history .number-container::-webkit-scrollbar,
.sl-game-v4.landscape .expanded .roadmap-container.big-road.m24-history .number-container::-webkit-scrollbar {
	height: 0;
}


/* Todo: apply custom scrollbar for Firefox */

/* FIREFOX */
.history-m14 {
	scrollbar-width: thin;
}

.lobby-history-roadmap4 .number-container,
.sl-game.v4:not(.sl-single-game-v4) .m24_roadmap .number-container {
	/* overflow: -moz-scrollbars-none; */
	scrollbar-width: none;
	/* scrollbar-width: thin; */
}

.lobby-history-container .history-m14.history-container {
	scrollbar-width: thin;
}

.sl-single-game.machine-game .history-statistics-container.m26,
.sl-single-game.machine-game .history-statistics-container.m29 {
	scrollbar-width: thin;
}

.table-unsubmitted-tickets > .scrollable-area {
	scrollbar-width: thin;
}

#scrollable_m24_roadmap {
	scrollbar-width: none;
}

/* m26, m29, m30 Running Tickets Container */
.sl-single-game.machine-game .history-statistics-container.m26,
.sl-single-game.machine-game .history-statistics-container.m29,
.sl-single-game.machine-game .history-statistics-container.m30 {
	scrollbar-width: thin;
}
