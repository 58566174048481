.sl-single-mobile-v2.landscape > .max > .chips-container {
	& > .change-chip-left,
	& > .change-chip-right {
		height: 4vh;
		padding: 2vh 0;

		left: 0;
		right: 0;
		background: transparent;
		border: unset;
		transform: rotate(90deg);
		height: 6vh;

		div {
			width: 1vw;
			height: 4vh;

			//Todo: crop image from this
			background: url("../icon-footer-hv.png");
			background-size: 20.41vw 4vh;
		}
	}

	& > .change-chip-left {
		top: 0;
		bottom: unset;
		width: 5vw;

		div {
			background-position: calc(100% - 12.5vw);
		}
	}

	& > .change-chip-right {
		top: unset;
		bottom: 0;
		width: 6vw;

		div {
			background-position: calc(100% - 13.5vw);
		}
	}

	& > .chips-container-inside {
		position: relative;
		top: 6vh;
		height: 56vh;
		overflow-y: scroll;
		padding: 1vh 0;

		& > .chip {
			margin-top: 0;
			display: block;
			z-index: 1;
			height: unset;

			& > img {
				width: 6vw;
				height: 12vh;
				margin-top: 1vh;
			}

			& > p {
				position: absolute;
				line-height: 12vh;
				font-size: 3vh;
			}
		}

		& > .chip.active {
			& > img {
				width: 7vw;
				height: 14vh;
			}

			& > p {
				line-height: 14vh;
				font-size: 4vh;
			}
		}
	}

}
