.sl-multi-table .virtual-cards.overlay {
	.cards {
		top: -1vh;
	}

	.left-value,
	.right-value {
		top: unset;
		bottom: 3vh;
	}
}

.sl-multi-table .virtual-cards.overlay.m23 {
	.left-letter.win > img,
	.right-letter.win > img {
		height: 4vh;
	}

	.flipping-card {
		width: 3vw;
		height: 8vh;
	}
}

//Shio Fights
.sl-multi-table .virtual-cards.overlay.m27 {
	position: absolute;

	.left.cards,
	.right.cards {
		//position: absolute;
		top: 0;

		div img {
			width: 3vw;
			height: auto;
		}
	}

	.left.cards {
		left: 45%;
	}

	.right.cards {
		right: 7%;
	}

	.left-letter,
	.right-letter {
		top: 55%;
		font-size: 125%;
		width: 10%;
		height: 20%;
		border-radius: 40%;
		padding-top: 0;
		text-align: center;
		vertical-align: baseline;

		span {
			position: absolute;
			top: 35%;
			left: 10%;
			right: 10%;
		}
	}

	.left-letter {
		left: 42%;

		span {
			margin-top: -35%;
		}
	}

	.right-letter {
		right: 8%;

		span {
			margin-top: -35%;
		}
	}

	.left-letter.win,
	.right-letter.win {
		position: absolute;
		top: 55%;
		width: 80%;
	}

	.tie-container {
		top: 50%;

		& > img {
			width: 90%;
			height: 2vw;
		}
	}

	.left-letter.win {
		left: 20%;

		img {
			width: 50%;
			height: 80%;
		}
	}

	.right-letter.win {
		right: -15%;

		img {
			width: 50%;
			height: 80%;
		}
	}
}
