.sl-footer > .chips-container {
	height: 8vh;
	width: 35vw;
	$border: 1px solid #665fd8;
	border-right: $border;
	border-left: $border;
	border-top: $border;
	border-radius: 3vh 3vh 0 0;
	box-shadow: 1px 1px 29px 10px #3a366f;
	z-index: 2;

	&,
	& > .heading {
		background: linear-gradient(to bottom, #344268 0%, #222c47 73%, #222c47 100%);
	}
}
