.niuniu-virtual-card-container {
	position: absolute;
	opacity: 1;
	text-align: left;
	background: rgba(21, 22, 36, 0.8);
}

.niuniu-virtual-card-container > .first-card-container {
	position: absolute;
	text-align: center;
	background: rgba(21, 22, 36, 0.8);
}

.niuniu-virtual-card-container > .card-row {
	position: relative;
	width: 100%;
	border-bottom: 0.1vh solid #726e91;
}

.niuniu-virtual-card-container > .card-row > div {
	display: inline-block;
	text-align: center;
	color: white;
	vertical-align: top;
}

.niuniu-virtual-card-container .name {
	box-sizing: border-box;
	border-right: 0.05vw solid #726e91;
}

.niuniu-virtual-card-container .value {
	box-sizing: border-box;
}

.niuniu-virtual-card-container .card {
	background: #444;
	border: 1px solid #434150;
	border-radius: 0.5vh;
}



/* TOP RIGHT CONTAINER */
.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container {
	top: 0;
	right: 12vw;
}

.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container > .first-card-container {
	left: -3vw;
	top: 0;
	width: 3vw;
	height: 8vh;
	line-height: 8vh;
}

.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container .name {
	line-height: 8vh;
	width: 4vw;
	font-size: 1.3vh;
}

.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container .value {
	line-height: 8vh;
	font-weight: bold;
	width: 1.5vw;
}

.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container .card,
.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container .flipping-card {
	width: 2vw;
	height: 5.4vh;
}

.sl-game.v4.sl-single-game-v4 > .niuniu-virtual-card-container .card {
	margin: 1vh 0.4vw;
}



/* MOBILE LANDSCAPE */
.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container {
	width: 100%;
	z-index: 1;
	// transform: translate3d(0, 0, 100px);
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container > .left-side-container,
.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container > .right-side-container {
	position: fixed;
	width: 30vw;
	bottom: 0;
	background: rgba(21, 22, 36, 0.8);
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container > .left-side-container {
	left: 0;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container > .right-side-container {
	right: 0;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .card-row {
	position: relative;
	display: inline-block;
	width: 30vw;
	height: 20vh;
	
	border-top: 1px solid #726e91;
	border-bottom: 1px solid #726e91;
}



.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .card-row > div {
	vertical-align: top;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .first-card-container {
	width: 10vw;
	height: 19vh;
	line-height: 19vh;

	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;

	border-bottom: 1px solid #726e91;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .name,
.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .value {
	color: white;
	display: inline-block;
	text-align: center;
	margin: 0;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .name {
	width: 8vw;
	height: 10vh;
	line-height: 10vh;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .value {
	font-size: 10vh;

	position: absolute;
	bottom: 0;
	left: 0;
	width: 8vw;

	border-right: 1px solid #726e91;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .card {
	display: inline-block;
	margin: 4vh 0;
}

.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .card,
.sl-single-mobile-v4.landscape > .niuniu-virtual-card-container .flipping-card {
	width: 4vw;
	height: 12vh;
}



/* MOBILE PORTRAIT */
.sl-single-mobile.portrait .niuniu-virtual-card-container {
	position: fixed;
	top: 44vh;
	width: 100%;
}

.sl-single-mobile.portrait .niuniu-virtual-card-container > .first-card-container {
	top: -15vh;
	width: 14vw;
	height: 10vh;
	line-height: 10vh;
}


.sl-single-mobile.portrait .niuniu-virtual-card-container .name {
	width: 20vw;
	height: 4vh;
	text-align: center;
}

.sl-single-mobile.portrait .niuniu-virtual-card-container .value {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 20vw;
	font-size: 4vh;
	
	text-align: center;
	border-right: 1px solid #726e91;
}

.sl-single-mobile.portrait .niuniu-virtual-card-container .card-row {
	padding: 0.5vh 0;
}

.sl-single-mobile.portrait .niuniu-virtual-card-container .card,
.sl-single-mobile.portrait .niuniu-virtual-card-container .flipping-card {
	width: 12vw;
	height: 8vh;
}

.sl-single-mobile.portrait .niuniu-virtual-card-container .card {
	margin: 0 1.5vw;
}


//CUSTOM CLASSES FOR IPHONE
.sl-single-mobile.portrait.iphone .niuniu-virtual-card-container .card {
	margin: 0 3vw;
}

.sl-single-mobile.portrait.iphone .niuniu-virtual-card-container .card,
.sl-single-mobile.portrait.iphone .niuniu-virtual-card-container .flipping-card {
	width: 9vw;
	height: 6vh;
}