@import "./custom_5d";

.sl-single-game > .min > .tabs {
	top: 8px;
	left: 325px;

	&.left {
		width: min-content;
	}
}

.sl-single-mobile.portrait .landscape-bottom-left-container {
	.tabs.left {
		& > .sl-bet {
			height: 4vh;
			line-height: 4vh;
		}

		.sl-machine-tab .bet-label {
			width: 13.5vw;

			font-size: 60%;
			line-height: 4vh;
		}
	}

	@for $a from 8 through 13 {
		&[pcode="m30"] .tabs.left .sl-machine-tab:nth-child(#{$a}) .bet-label {
			width: 16vw;
		}
	}
}

.sl-machine-tab {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background: #41527e;

	.left-wing,
	.right-wing {
		display: none;

		z-index: 1;
		position: absolute;
		width: 21px;
		height: 100%;

		top: 0;

		pointer-events: none;
	}

	&[disabled] {
		filter: brightness(0.5);
		cursor: not-allowed;
	}

	&.active {
		background: white;
		color: #41527e;

		.left-wing {
			left: -15px;
			display: block;
		}

		.right-wing {
			right: -15px;
			display: block;
		}

		&:first-child .left-wing,
		&:last-child .right-wing {
			display: none;
		}

		&:hover {
			background: white !important;
		}
	}

	.ticket-count {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: -25%;

		background: #23a448;
		color: white;

		width: 60%;

		z-index: 1;
	}
}

.sl-single-game.machine-game .sl-machine-tab {
	line-height: 40px;
	font-size: 20px;
	width: 95px;

	&[pcode="m26"] {
		width: 380px;
	}

	.ticket-count {
		font-size: 10px;

		border-radius: 10px;
		height: 20px;
		line-height: 20px;

		min-width: 80px;
	}
}

.landscape .tabs {
	width: 100%;

	.sl-machine-tab {
		line-height: 30px;
		font-size: 18px;
		width: percentage(1/6);

		.ticket-count {
			font-size: 9px;

			border-radius: 8px;
			height: 16px;
			line-height: 16px;
		}

		&[pcode="m26"] {
			width: 50%;
		}

		&[pcode="m29"] {
			width: percentage(1/8);

			.ticket-count {
				width: 90%;
				font-size: 7px;
			}

			.bet-label {
				font-size: 8px;
			}
		}

		&[pcode="m30"] .ticket-count {
			width: 100%;
			font-size: 7px;
			height: unset;
			line-height: 10px;
		}
	}
}

.portrait .sl-machine-tab .ticket-count {
	font-size: 1vh;
	border-radius: 1.5vh;
	height: 2vh;
	line-height: 2vh;
	top: 0;

	width: 80%;
}
