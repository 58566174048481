.enlargement-area {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
}

.enlargement-area .hover-area {
	position: absolute;
	width: 100%;
	height: 100%;
	/* background: rgba(100, 149, 237, 0.5); */
	
	cursor: zoom-in;
}

.enlargement-area.enlarged .hover-area {
	cursor: zoom-out;
}

.enlargement-area .toggle-enlarge-roadmap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	width: unset;
	height: 100%;
	margin: auto;
	
	opacity: 0;
}

.enlargement-area.enlarged .toggle-enlarge-roadmap {
	height: 12vh;
}


@keyframes hovering {
	0%   { opacity: 0.1; }
	50%  { opacity: 0.4; }
	100% { opacity: 0.1; }
}

.enlargement-area .toggle-enlarge-roadmap.hovering {
	animation: hovering 0.5s infinite;
}