@import "./v2_dark";

.sl-multi-table.v2 > .table {
	position: absolute;
	// top: 4vh;
	top: 3vh;
	right: 0;
	width: 20vw;
}

.sl-game.v2 > .controller {
	position: absolute;
	left: 12.13vw;
	bottom: 0.8vh;
}

.sl-multi-table.v2 .controller {
    width: 19.15vw;
    padding-top: 0.6vh;
    display: grid;
    grid-gap: 0.5vw;
    grid-template-columns: repeat(4, 1fr);
}

.sl-multi-table.v2 .controller > button {
	font-family: "Lato-Regular";
    width: 4.36vw;
    height: 2.8vh;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 0.85vw;
    cursor: pointer;
    text-shadow: 1px 2px 2px #262626;
    font-size: 1.2vh;
}

.sl-game.v2 .controller > button.sl-red-sh[disabled],
.sl-game.v2 .controller > button.sl-blue-sh[disabled],
.sl-game.v2 .controller > button.sl-yellow-sh[disabled],
.sl-game.v2 .controller > button.sl-green-sh[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}


.sl-multi-table.v2 .group-timer {
	position: absolute;
	top: 15.2vh;
	left: 0.6vw;
    width: 11.3vw;
	
	font-size: 1.2vh;
}

.sl-multi-table.v2 .group-timer > .timer {
	position: absolute;
	
	font-size: 1.8vh;
	color: #e04f4b;
	text-align: center;
	vertical-align: top;
	line-height: 2.5vh;
	
	right: 0.3vw;
}

.sl-multi-table.v2 .group-timer > .timer.bet-closed {
	color: rgb(255,160,160);
	font-size: 1.3vh;
	font-weight: bold;
}

.sl-multi-table.v2 .group-timer > .total-bet {
	line-height: 3vh;
}

.sl-multi-table.v2 .group-timer > .total-bet .desc-heading {
	position: absolute;
}

.sl-multi-table.v2 .group-timer > .total-bet .desc-content {
	position: absolute;
	left: 0;
	right: 2vw;
}


/* SINGLE GAME DESKTOP */
.sl-game.v2.sl-single-game-v2 {
	position: relative;
	left: 0;
	top: 0;
	width: 100vw;
	height: unset;
	margin: 0;

	&.header-autohide .stream-container-fullscreen {
		top: 0;
		height: 100vh;
	}
	
	.stream-container-fullscreen {
		position: fixed;
		/* left: -4.5vw; */
		top: 8vh;
		width: 100vw;
		height: 92vh;

		z-index: -1;
		transform: translateZ(-100px);
		transform-style: preserve-3d;
	}
	
	.stream-container-fullscreen > object {
		width: 100%;
		height: 100%;
	}
}



.sl-game.v2.sl-single-game-v2 .roadmap-container {
	position: relative;
	background: white;
	box-sizing: border-box;
	border-bottom: 1px solid black;
}

.sl-game.v2.sl-single-game-v2 .dark .roadmap-container {
	border-bottom: 1px solid #B41B37;
}

.sl-game.v2.sl-single-game-v2 > .baccarat-left-container {
	position: fixed;
	left: 0;
	bottom: 7vh;
	border-top: 1vh solid #0c325c;
	border-bottom: 1vh solid #0c325c;
	border-right: 1vw solid #0c325c;
	border-top-right-radius: 1vh;
	z-index: 1;
}

.sl-game.v2.sl-single-game-v2 > .roadmap-container {
	position: fixed;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.big-road {
	width: 100%;
	height: 8.6vh;
	border-bottom: 1px solid black;
}

.sl-game.v2.sl-single-game-v2 .dark .roadmap-container.big-road {
	border-bottom: 1px solid #B41B37;
}

.sl-game.v2.sl-single-game-v2 > .roadmap-container.bead-road {
	bottom: 18vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.big-eye-road {
	position: relative;
	text-align: center;
	height: 6vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.predictions {
	width: 3.8vw;
	height: 12.1vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.small-road {
	position: relative;
	display: inline-block;
	border-right: 0.05vw solid black;
	height: 6vh;
}

.sl-game.v2.sl-single-game-v2 .dark .roadmap-container.small-road {
	border-right: 0.05vw solid #B41B37;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.cockroach-road {
	position: relative;
	display: inline-block;
	width: 12.5vw;
	height: 6vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.predictions .roadmap-table td {
	width: unset;
	height: unset;
}

.sl-game.v2.sl-single-game-v2 .baccarat-roadmap-container {
	position: fixed;
	right: 0;
	bottom: 9vh;
	width: 30.2vw;
}

.sl-game.v2.sl-single-game-v2 .baccarat-roadmap-container > * {
	position: relative;
	display: inline-block;
}

.sl-game.v2.sl-single-game-v2 .baccarat-roadmap-container > .roadmap-container.group {
	white-space: nowrap;
	width: 26.4vw;
	height: 12vh;
}

.sl-game.v2.sl-single-game-v2 .baccarat-roadmap-container > .roadmap-container.predictions tr {
	white-space: normal;
}

.sl-game.v2.sl-single-game-v2 .baccarat-roadmap-container > .roadmap-container {
	position: relative;
	display: inline-block;
	padding: 0;
	margin: 0;
	vertical-align: top;
}

/* NEW IN V2 DESIGN */
.sl-game.v2.sl-single-game-v2 .baccarat-right-container {
	position: fixed;
	right: 0;
	bottom: 7vh;
	/* border-radius: 1vh; */
	border-top: 1vh solid #0b315b;
	border-bottom: 1vh solid #0b315b;
	border-left: 1vw solid #0b315b;
	border-top-left-radius: 1vh;
}

.sl-game.v2.sl-single-game-v2 .baccarat-right-container > div {
	position: relative;
	display: inline-block;
}

.sl-game.v2.sl-single-game-v2 .baccarat-right-container > .roadmap-container.group {
	white-space: nowrap;
	// width: 26.4vw;
	// width: 86.5%;
	height: 12vh;
}

.sl-game.v2.sl-single-game-v2 .baccarat-right-container > .roadmap-container.predictions tr {
	white-space: normal;
}

.sl-game.v2.sl-single-game-v2 .baccarat-right-container > .roadmap-container {
	position: relative;
	display: inline-block;
	padding: 0;
	margin: 0;
	vertical-align: top;
}


.sl-game.v2.sl-single-game-v2 .roadmap-table {
	display: inline-block;
	overflow: visible;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.small-road .back-text,
.sl-game.v2.sl-single-game-v2 .roadmap-container.cockroach-road .back-text {
	width: 100%;
	font-size: 2vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table .row,
.sl-game.v2.sl-single-game-v2 .roadmap-table .row > div {
	height: 1.4vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table .row > div {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 0.7vw;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.enlarged .roadmap-table .row {
	height: 3.3vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.enlarged .roadmap-table .row > div {
	width: 1.53vw;
	height: 3.3vh;
}

.sl-single-game-v2 .roadmap-container.enlarged .sl-roadmap-slash {
	transform: translate(-40%, -40%) rotate(-45deg);
	border-bottom-width: 1vh;
}

.sl-single-game-v2 .roadmap-container.enlarged .sl-baccarat-diagonal-line.sl-roadmap-green.tie {
	font-size: 2vh;
	line-height: 2vh;
	height: 60%;
	top: 1vh;
	left: 0.3vw;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.bead-road .roadmap-table .row,
.sl-game.v2.sl-single-game-v2 .roadmap-container.bead-road .roadmap-table .row > div {
	height: 2vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container.bead-road .roadmap-table .row > div {
	width: 1vw;
}


.sl-game.v2.sl-single-game-v2 .roadmap-container .roadmap-table.shio .row,
.sl-game.v2.sl-single-game-v2 .roadmap-container .roadmap-table.shio .row > div {
	height: 4vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container .roadmap-table.shio.m27 .row,
.sl-game.v2.sl-single-game-v2 .roadmap-container .roadmap-table.shio.m27 .row > div {
	height: 3vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-container .roadmap-table.shio.m27 .row > div {
	width: 1.5vw;
}


.sl-game.v2.sl-single-game-v2 .roadmap-table.small .row {
	position: relative;
	display: flex;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.small .row,
.sl-game.v2.sl-single-game-v2 .roadmap-table.small .row > div {
	height: 0.9vh;
}

.sl-game.v2.sl-single-game-v2 .roadmap-table.small .row > div {
	width: 0.45vw;
}


$border-colour: #e9e9e9;

.sl-game.v2.sl-single-game-v2 .roadmap-table.small,
.sl-game.v4.sl-single-game-v4 .roadmap-table.small {
	.row > div:nth-child(even) {
		border-right: 1px solid $border-colour;
	}

	.row:nth-child(even) {
		border-bottom: 1px solid $border-colour;
	}
}


$dark-border-colour: #333;

.sl-game.v2.sl-single-game-v2 .roadmap-table.small.dark,
.sl-game.v4.sl-single-game-v4 .roadmap-table.small.dark {
	.row > div:nth-child(even) {
		border-right: 1px solid $dark-border-colour;
	}

	.row:nth-child(even) {
		border-bottom: 1px solid $dark-border-colour;
	}
}




.sl-game.v2.sl-single-game-v2 .small-road .roadmap-table.small td,
.sl-game.v2.sl-single-game-v2 .cockroach-road .roadmap-table.small td {
	width: 0.46vw;
}



.sl-game.v2.sl-single-game-v2 .details {
	position: absolute;
	width: 80%;
	height: 5.4vh;
	line-height: 5.4vh;
	left: 0;
	right: 0;
	margin: auto;
	
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0+0,0.85+30,0.85+70,0+99 */
	background: -moz-linear-gradient(left,  rgba(21,22,36,0) 0%, rgba(21,22,36,0.85) 30%, rgba(21,22,36,0.85) 70%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(21,22,36,0) 0%,rgba(21,22,36,0.85) 30%,rgba(21,22,36,0.85) 70%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(21,22,36,0) 0%,rgba(21,22,36,0.85) 30%,rgba(21,22,36,0.85) 70%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00151624', endColorstr='#00151624',GradientType=1 ); /* IE6-9 */
}

.sl-game.v2.sl-single-game-v2 .details .total-bet,
.sl-game.v2.sl-single-game-v2 .details .total-win {
	display: inline-block;
}

.sl-game.v2.sl-single-game-v2 .details .total-bet {
	margin-right: 15vw;
}

.sl-game.v2.sl-single-game-v2 .details .desc-heading {
	display: inline-block;
	color: #ffffff;
}

.sl-game.v2.sl-single-game-v2 .details .desc-content {
	display: inline-block;
	color: #2bbfa0;
}



.sl-game.v2.sl-single-game-v2 .group-timer {
	position: absolute;
	right: 0;
	width: 12vw;
	height: 58vh;
	text-align: center;
	
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0.9+0,0.9+50,0+99 */
	background: -moz-linear-gradient(top,  rgba(21,22,36,0.9) 0%, rgba(21,22,36,0.9) 50%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6151624', endColorstr='#00151624',GradientType=0 ); /* IE6-9 */
}

.sl-game.v2.sl-single-game-v2 .group-timer .bet-open-text,
.sl-game.v2.sl-single-game-v2 .group-timer .bet-closing-text,
.sl-game.v2.sl-single-game-v2 .group-timer .bet-closed-text {
	font-size: 3vh;
	color: white;
	margin-top: 3vh;
}


.sl-game.v2.sl-single-game-v2 .group-timer .timer {
	font-size: 11vh;
}

.sl-game.v2.sl-single-game-v2 .group-timer .timer.bet-open {
	color: #0ed503;
}

.sl-game.v2.sl-single-game-v2 .group-timer .timer.bet-closing {
	color: #d52b2b;
}

.sl-game.v2.sl-single-game-v2 .group-timer .timer.bet-closed {
	font-size: 4.8vh;
	text-align: center;
	color: #d52b2b;
}

.sl-game.v2.sl-single-game-v2 .group-timer .total-win .desc-heading,
.sl-game.v2.sl-single-game-v2 .group-timer .total-bet .desc-heading {
	color: white;
	font-size: 1.8vh;
}

.sl-game.v2.sl-single-game-v2 .group-timer .total-win,
.sl-game.v2.sl-single-game-v2 .group-timer .total-bet {
	margin-top: 2vh;
}

.sl-game.v2.sl-single-game-v2 .group-timer .total-win .desc-content,
.sl-game.v2.sl-single-game-v2 .group-timer .total-bet .desc-content {
	color: #2bbfa0;
	font-size: 1.8vh;
}


.sl-game.v2.sl-single-game-v2 .table-desc-container {
	position: absolute;
	left: 0;
	top: 0;
	height: 58vh;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0.9+0,0.9+50,0+99 */
	background: -moz-linear-gradient(top,  rgba(21,22,36,0.9) 0%, rgba(21,22,36,0.9) 50%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6151624', endColorstr='#00151624',GradientType=0 ); /* IE6-9 */
}

.sl-game.v2.sl-single-game-v2 .table-desc-container .table-desc {
	margin-top: 8vh;
	height: 25vh;
	text-align: left;
}

.sl-game.v2.sl-single-game-v2 .table-desc-container .desc-heading {
	padding: 1vh 1.36vw;
	color: white;
}

.sl-game.v2.sl-single-game-v2 .table-desc-container .desc-content {
	text-align: right;
	padding: 0 1vw 0 0;
	color: #22deae;
}

.sl-game.v2.sl-single-game-v2 .table-desc span {
	display: block;
	height: 2vh;
}

.sl-game.v2.sl-single-game-v2 .border-bottom {
	border-bottom: 1px solid #353343;
}

.sl-game.v2.sl-single-game-v2 .table .sl-table,
.sl-game.v2.sl-single-game-v2 .table .sl-table-dim {
	position: fixed;
	width: 40vw;
	height: 17vh;
	text-align: center;
	display: block;
	
	margin: auto;
	left: 0;
	right: 3vw;
	top: 57vh;
	bottom: 0;
	
	opacity: 0.85;

	transition: 1s all;
    transform-origin: 50% 100%;
    
	background: transparent;
	/* background-color: #0a3c21; */
}

.sl-game.v2.sl-single-game-v2 .table .sl-table.relaxed,
.sl-game.v2.sl-single-game-v2 .table .sl-table-dim.relaxed {
	top: 64vh;
	transform: perspective(68.1vw) rotateX(45deg);
}

/* New in Baccarat V2 */

.sl-game.v2.sl-single-game-v2 .table-container {
	position: fixed;
	margin: auto;
	top: unset;
	left: 0;
	right: 0;
	bottom: 8.6vh;

	/* transition: 1s all; */
    transform-origin: 50% 100%;

	border: 1vh solid #0c325c;
	background: #0c325c;
	
	width: 40vw;
	height: 22vh;
    
	text-align: center;
}

.sl-game.v2.sl-single-game-v2.ipad .table-container.relaxed {
	-webkit-transform: perspective(68vw) rotateX(25deg) translateY(22%) translateZ(70px) scale(0.9);
	transform: perspective(68vw) rotateX(25deg) translateY(22%) translateZ(70px) scale(0.9);
	
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.sl-game.v2.sl-single-game-v2.ipad > .baccarat-left-container,
.sl-game.v2.sl-single-game-v2.ipad > .baccarat-right-container {
	transform: translateZ(200px);
	transform-style: preserve-3d;
}

.sl-game.v2.sl-single-game-v2.ipad > .stream-container-fullscreen {
	transform: translateZ(0px);
	transform-style: preserve-3d;
}

/* LEFT AND RIGHT WINGS */
.sl-game.v2.sl-single-game-v2 .table-container .left-wing,
.sl-game.v2.sl-single-game-v2 .table-container .right-wing {
    position: absolute;
    background: transparent;
	top: 4vh;
    width: 0;
    height: 19vh;
    border-top: 8vh solid transparent;
	
	
	opacity: 0;
	/* transition: 1s all; */
}

.sl-game.v2.sl-single-game-v2 .table-container .left-wing {
    left: -3.4vw;
    border-left: 0.1vw solid #0c325c;
}

.sl-game.v2.sl-single-game-v2 .table-container .right-wing {
	right: -3.4vw;
    border-right: 0.1vw solid #0c325c;
}

.sl-game.v2.sl-single-game-v2 .table-container.relaxed .left-wing {
	border-left-width: 3.5vw;
}

.sl-game.v2.sl-single-game-v2 .table-container.relaxed .right-wing {
	border-right-width: 3.5vw;
}

.sl-game.v2.sl-single-game-v2 .table-container.relaxed .left-wing,
.sl-game.v2.sl-single-game-v2 .table-container.relaxed .right-wing {
    top: -8vh;
	opacity: 1;
}

/* SL-TABLE AGAIN */
.sl-game.v2.sl-single-game-v2 .table-container .sl-table,
.sl-game.v2.sl-single-game-v2 .table-container .sl-table-dim {
	position: relative;
	width: 40vw;
	height: 18vh;
}

.sl-game.v2.sl-single-game-v2 .table-container .sl-table-dim {
	top: 0;
	/* background-color: rgba(0,0,0,0.5); */
	background: transparent;
}

.sl-game.v2.sl-single-game-v2 .table-container.relaxed {
	height: 18vh;
	bottom: 7vh;
	transform: perspective(68vw) rotateX(25deg);
}

//.sl-game.v2.sl-single-game-v2 .table-container.relaxed .bet-toggle {
//	/* opacity: 0; */
//	cursor: default;
//}

.sl-game.v2.sl-single-game-v2 .table-container .bet-toggle {
    position: absolute;
    padding: 0.5vh 1vw 0.5vh 1vw;
    box-sizing: border-box;
    right: 2vw;
    top: -4.2vh;
    transition: 1s all;
	cursor: pointer;
}

.sl-game.v2.sl-single-game-v2 .table-container .bet-toggle:after {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	width: 100%;
	height: 120%;
	border-top-left-radius: 1vh;
	border-top-right-radius: 1vh;
	content: "";
	transition: all .2s ease;
	transform: perspective(1vh) rotateX(2deg);
	transform-origin: bottom;
	background: white;
}

.sl-game.v2.sl-single-game-v2 .table-container .bet-toggle > div {
	display: inline-block;
	vertical-align: top;
	font-size: 1.5vh;
}

.sl-game.v2.sl-single-game-v2 .table-container .bet-toggle > img {
	height: 1.5vh;
}

/* Start of stream-container */
.sl-multi-table.v2 .stream-container {
	left: 2%;
	top: 4vh;
	
	&:hover {
		left: 2%;
		z-index: 10;
	}

	& > object {
		border: 2px solid #333d5e;
		left: 0px;
	}

	& > object:hover {
		border: 1px solid #41527e;
		box-shadow: 0 8px 20px 8px rgba(0,0,0,0.5);
	}
}

/* End of stream-container */


/* Bet controls start here */
.sl-game.v2.sl-single-game-v2 .controller {
    width: 20vw;
    position: fixed;
    bottom: 24vh;
    display: grid;
    
	left: 0;
    right: 0;
    margin: auto;
    grid-gap: 0.5vw;
	
	grid-template-columns: repeat(4, 1fr);
	
	background: #0c325c;
	border-top-left-radius: 1vh;
	border-top-right-radius: 1vh;
	
	transition: 1s ease-in-out all;
}

.sl-game.v2.sl-single-game-v2 .controller button {
	margin-left: auto;
	margin-right: auto;
}

.sl-game.v2.sl-single-game-v2 .controller button[disabled] {
	opacity: 0.5;
}

.sl-game.v2.sl-single-game-v2 .controller.relaxed {
	bottom: 0;
}

/* BACCARAT V2 */
.sl-game.v2.sl-single-game-v2 > .table-container > .sl-table,
.sl-game.v2.sl-single-game-v2 > .table-container > .controller {
	position: absolute;
	background: #0b315b;
	top: 0;
	bottom: 0;
	margin: auto;
}

.sl-game.v2.sl-single-game-v2 > .table-container > .controller {
	top: 1vh;
	bottom: unset;
}

.sl-game.v2.sl-single-game-v2 > .table-container > .controller > button {
	border-radius: 3vh;
	background-color: #727198;
	background-size: unset;
	background-position: unset;
    background-repeat: no-repeat;
    background-position: center;

	width: 4.2vw;
}

.sl-game.v2.sl-single-game-v2 > .table-container.relaxed > .controller {
	opacity: 0;
}

.sl-game.v2.sl-single-game-v2 > .table-container > .controller .sl-controller-v2 {
	&.undo {
		background-image: url("../../assets/img/svg/controller/undo.svg");
	}
	
	&.clear {
		background-image: url("../../assets/img/svg/controller/clear.svg");
	}
	
	&.rebet {
		background-image: url("../../assets/img/svg/controller/rebet.svg");
	}
	
	&.submit {
		background-image: url("../../assets/img/svg/controller/submit.svg");
	}
}


/* Bet controls end here */


/* Multibet */
.sl-multi-table.v2 .table {
	padding-top: 1vh;
}

.sl-multi-table.v2 .controller {
	width: 19.15vw;
	padding-top: 0.6vh;
	display: grid;
	grid-gap: 0.5vw;
	grid-template-columns: repeat(4, 1fr)
}

.sl-multi-table.v2 .controller > button {
	font-family: "Lato-Regular";
    width: 4.36vw;
    border: none;
    color: white;
    font-weight: bold;
    height: 2.8vh;
    border-radius: 16px;
    cursor: pointer;
	
	text-shadow: 1px 2px 2px #262626;
}

.sl-game.v2.sl-single-game-v2 .desc-heading,
.sl-game.v2.sl-single-game-v2 .desc-content {
	font-size: 1.4vh;
}
 
.sl-game.v2.sl-single-game-v2 .switch-container {
	position: absolute;
	right: 0.2vw;
	bottom: 0.2vh;
}
 
 
 
/* NEW SHOE INDICATOR */
.new-shoe-indicator {
    top: 0;
    color: white;
	z-index: 2;
	background: rgba(0,0,0,0.8);
	white-space: pre;
}

.portrait .new-shoe-indicator {
	position: fixed;
	top: 43vh;
	width: 100%;
	height: 38vh;
	line-height: 38vh;
	
	margin: auto;
	font-size: 4vh;
	
	& > div {
		display: inline-block;
		vertical-align: middle;
		line-height: normal;
	}
}

.sl-game.v2.sl-single-game-v2 .new-shoe-indicator {
    position: relative;
	font-size: 6vh;
	height: 100%;
}

.sl-multi-table.v2 .new-shoe-indicator {
	position: absolute;
	font-size: 3vh;
	line-height: 9vh;
	width: 100%;
	height: 100%;
}

.sl-single-mobile-v2.landscape .new-shoe-indicator {
    position: absolute;
	width: 100%;
	height: 100%;
	font-size: 12vh;
}



.roadmap-table.v3 .row {
	height: fit-content;
	display: block;
	white-space: nowrap;
	
	& > div {
		box-sizing: border-box;
	}
}


.roadmap-table.v3 .back-text {
	display: block;
	
	position: absolute;
	height: fit-content;
	top: 0;
	bottom: 0;
	margin: auto;
	
	font-weight: normal;
	text-align: center;
	width: 100%;

	color: #ddd;
}

.roadmap-table.v3.dark .back-text {
	color: #333;
}



/* LOBBY ROADMAP & HISTORY */
.lobby-history-roadmap .roadmap-table.v3 {
	border-collapse: collapse;
	background: white;
}

.lobby-history-roadmap .roadmap-table.v3.fan {
 	position: absolute;
	top: 0;
	bottom: 0;
	// margin: auto;
	text-align: center;
	width: 12vw;
	// height: 6.6vh;
	box-sizing: border-box;

	display: flex;
    justify-content: center;
    align-items: center;
}
 
.lobby-history-roadmap .roadmap-table.v3 .back-text {
	font-size: 3vh;
}

.lobby-history-roadmap .roadmap-table.v3 .row {
	display: block;
}

.lobby-history-roadmap .roadmap-table.v3 .row,
.lobby-history-roadmap .roadmap-table.v3 .row > div {
	height: 1.6vh;
}

.lobby-history-roadmap .roadmap-table.v3 .row > div {
	white-space: nowrap;
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 0.64vw;
	border: 1px solid #e9e9e9;
	border-width: 0.1vh 0.05vw 0.1vh 0.05vw;
}

.lobby-history-roadmap .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 1vh;
}


/* SINGLE GAME DESKTOP */
.sl-game .roadmap-table.v3 {
	position: relative;
	width: unset;
	left: 0;
	bottom: 0;
}

.sl-game .roadmap-table.v3 .back-text {
	font-size: 3vh;
}

//Mobile Landscape
.sl-single-mobile.landscape .roadmap-table.v3 {
	background: white;
	width: 100%;
	height: 100%;
	
	.roadmap {
		position: absolute;

		top: 0;
		left: 0;
		right: 0;
		bottom: 22px;
		margin: auto;
		
		height: fit-content;
	
		.row,
		.row > div {
			height: 17px;
		}
		
		.row > div {
			width: 17px;
			
			box-sizing: border-box;
			border: 1px solid #e9e9e9;
		}
	}
}

.sl-single-mobile.landscape .hugestreaming.history-big-container .roadmap-table.v3 .roadmap {
	bottom: 0;
}

.sl-single-game-v2 .roadmap-table.v3 .row > div,
.sl-single-game-v4 .roadmap-table.v3 .row > div {
	display: inline-block;
	position: relative;
	width: 0.4vw;
	border: 1px solid #e9e9e9;
	vertical-align: top;
}

.sl-single-game-v2 .dark .roadmap-table.v3 .row > div,
.sl-single-game-v4 .dark .roadmap-table.v3 .row > div {
	border: 1px solid #333;
}


.sl-game .roadmap-table.v3.small .row > div,
.sl-game .roadmap-table.v3.small .row {
	text-align: center;
	border: none;
}


$border-const: 1px solid #B41B37;

.sl-single-mobile.landscape .hugestreaming .roadmap-table.v3 .roadmap {
	.row:first-child > div {
		border-top: $border-const;
	}
	
	.row > div:first-child {
		border-left: $border-const;
	}
	
	.row > div:last-child {
		border-right: $border-const;
	}

	.row:last-child > div {
		border-bottom: $border-const;
	}
}

.sl-single-mobile.landscape .hugestreaming .roadmap-table.v3.m32 .sl-baccarat-diagonal-line.sl-roadmap-green.tie {
	line-height: 350%;
}



.sl-multi-table .roadmap-table.v3.small .row > div {
	border: 1px solid #e9e9e9;
	box-sizing: border-box;
}

.sl-multi-table .roadmap-table.v3.dark.small .row > div {
	border: 1px solid #333;
}


//Custom Classes for SamGong
$samgong-default-border: 1px solid #e9e9e9;

.sl-single-game .roadmap-table.v3.m32 .row:nth-child(even) > div {
	border-bottom: $samgong-default-border;
}

.sl-single-game .roadmap-table.v3.m32 {
	border: 1px solid black;
}

.sl-single-game .roadmap-table.v3.m32 .row > div {
	border: none;
	
	&:nth-child(even) {
		border-right: $samgong-default-border;
	}
}

.sl-single-game .roadmap-table.v3.m32.bead-road .row > div,
.sl-single-game .roadmap-table.v3.m32.big-road .row > div {
	border-right: $samgong-default-border;
	border-bottom: $samgong-default-border;
}

.sl-single-game .roadmap-table.v3.m32.bead-road .row {
	height: 20px;
	
	& > div {
		width: 20px;
		height: 20px;
	}
}

.sl-single-game .roadmap-table.v3.m32.big-road .row {
	height: 15px;
	
	& > div {
		width: 15px;
		height: 15px;
	}
}

.sl-single-game .roadmap-table.v3.m32 .back-text {
	font-size: 20px;
}

.sl-single-game .roadmap-table.v3.m32.bead-road .back-text,
.sl-single-game .roadmap-table.v3.m32.big-road .back-text {
	font-size: 30px;
}

.sl-single-game .roadmap-table.v3.m32.small-road,
.sl-single-game .roadmap-table.v3.m32.cockroach-road {
	display: inline-block;

}

.sl-single-game .roadmap-table.v3.m32.big-eye-road,
.sl-single-game .roadmap-table.v3.m32.small-road,
.sl-single-game .roadmap-table.v3.m32.cockroach-road {
	.row {
		height: 10px;
	}
	
	.row > div {
		width: 10px;
		height: 10px;
	}
}



//Portrait roadmap custom borders

.sl-game .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 1.4vh;
}

// for hilo
.sl-game.sl-single-game-v2 .roadmap-table.v3.m34 .sl-baccarat-bead-value {
	font-size: 1.2vh;
	line-height: 2vh;
}

.sl-single-game-v4 .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 1.2vh;
}


.sl-single-game-v4 .roadmap-table.v3.fan .row,
.sl-single-game-v4 .roadmap-table.v3.fan .row > div {
	height: 2vh;
}

.sl-single-game-v4 .roadmap-table.v3.fan .row > div {
	width: 1vw;
}


/* MULTI TABLE FANTAN */
.sl-multi-table > .roadmap-table.v3.fan .row,
.sl-multi-table > .roadmap-table.v3.fan .row > div {
	height: 1.36vh;
}

.sl-multi-table > .roadmap-table.v3.fan .row > div {
	width: 0.68vw;
}



.sl-game .enlarged .roadmap-table.v3 .row,
.sl-game .enlarged .roadmap-table.v3 .row > div {
	height: 3vh;
}

.sl-game .enlarged .roadmap-table.v3 .row > div {
	width: 1.5vw;
}


.sl-game .m25-roadmap-container .bead-road > .roadmap-table.v3 .row,
.sl-game .m25-roadmap-container .bead-road > .roadmap-table.v3 .row > div {
	height: 1.6vh;
}

.sl-game .m25-roadmap-container .bead-road > .roadmap-table.v3 .row > div {
	width: 0.7vw;
}


/* MULTI TABLE DESKTOP */
.sl-multi-table > .roadmap-table.v3,
.sl-multi-table > .min > .roadmap-table.v3 // SamGong
{
	position: absolute;
	bottom: 1.4vh;
	left: 1.8vw;
	width: 10vw;
	height: 7.2vh;
	background: white;
	
	.back-text {
		font-weight: normal;
		width: 100%;
		height: 7vh;
		line-height: 7vh;
		font-size: 3vh;
		color: #ddd;
	}
	
	.sl-roadmap-circle-hollow {
		border-width: 1px;
	}
	
	.row,
	.row > div {
		height: 1.2vh;
	}
	
	.row > div {
		display: inline-block;
		position: relative;
		width: 0.57vw;
		border-right: 0.05vw solid #e9e9e9;
		border-bottom: 0.1vh solid #e9e9e9;
		vertical-align: top;
	}
	
	.sl-baccarat-bead-value {
		font-size: 0.8vh;
	}
}

.sl-multi-table > .min > .roadmap-table.v3 {
	bottom: 1.1vh;
	left: 1.8vw;
	height: 7.5vh;
}

.roadmap-table.v3 .row > div {
	position: relative;
	display: inline-block;
	vertical-align: top;
}


/*START MULTI TABLE DESKTOP SHIOFIGHT*/
.sl-game > .roadmap-table.v3.m27.small.shio {
	position: absolute;
	bottom: 1.4vh;
	left: 1.8vw;
	width: 10vw;
	max-width: 10vw;
	height: 6.8vh;
	overflow-x: hidden;
	overflow-y: hidden;
	background: white;
	padding-top: 0.4vh;

	.row,
	.row > div {
		height: 1.6vh;
	}

	.row > div {
		display: inline-block;
		width: 0.8vw;
		border-right: 0.05vw solid #e9e9e9;
		border-bottom: 0.1vh solid #e9e9e9;
	}
	.row div div {
		width: 100%;
		height: 100%;
	}
	.row div div img{
		width: 95%;
		height: 95%;
	}

}
/* END MULTI TABLE DESKTOP SHIOFIGHT*/


/* LOBBY MOBILE */
/* BACCARAT */
.mobile-home .roadmap-table.v3 {
	position: relative;
	background: white;
}

.mobile-home .roadmap-table.v3 .row {
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	height: auto;
	border-left: 1px solid #e9e9e9;
}

.mobile-home .roadmap-table.v3 .row > div {
	white-space: nowrap;
	display: inline-block;
	position: relative;
	vertical-align: top;
	
	box-sizing: border-box;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}



/* LOBBY MOBILE PORTRAIT */
.mobile-home.portrait .roadmap-table.v3 .back-text {
	width: 100%;
	font-size: 3vh;
}

.mobile-home.portrait .roadmap-table.v3 .row,
.mobile-home.portrait .roadmap-table.v3 .row > div {
	height: 1.7vh;
}

.mobile-home.portrait .roadmap-table.v3 .row > div {
	width: 3vw;
}


.mobile-home.portrait .roadmap-table.v3.fan .row,
.mobile-home.portrait .roadmap-table.v3.fan .row > div {
	height: 2.7vh;
}

.mobile-home.portrait .roadmap-table.v3.fan .row > div {
	width: 5vw;
}



.mobile-home.portrait .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 1.2vh;
}

.mobile-home.portrait .roadmap-table.v3.m27.shio .roadmap {
	height: auto;

	.row {
		height: 2.5vh;

		div {
			height:2.5vh;
			width: 4.5vw;
		}
	}
}


/* LOBBY MOBILE LANDSCAPE */
.mobile-home.landscape .roadmap-table.v3 .back-text {
	width: 100%;
	font-size: 8vh;
}

.mobile-home.landscape .roadmap-table.v3 .row,
.mobile-home.landscape .roadmap-table.v3 .row > div {
	height: 3.5vh;
}

.mobile-home.landscape .roadmap-table.v3 .row > div {
	width: 1.6vw;
}

.mobile-home.landscape .roadmap-table.v3.fan .row,
.mobile-home.landscape .roadmap-table.v3.fan .row > div {
	height: 5.5vh;
	line-height: 5.5vh;
}

.mobile-home.landscape .roadmap-table.v3.fan .row > div {
	width: 2.75vw;
}

.mobile-home.landscape .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 2.2vh;
}

.mobile-home.landscape .roadmap-table.v3.m27.shio .roadmap .row {
	height: 5.2vh;

	div {
		height: 5.2vh;
		width: 2.5vw;
	}
}

/* BACCARAT SINGLE MOBILE */
.baccarat-roadmap .roadmap-table.v3 {
	border-collapse: collapse;
	top: 0;
	position: relative;
	background: white;
}

.baccarat-roadmap .roadmap-table.v3 .row > div {
	white-space: nowrap;
	display: inline-block;
	position: relative;
	vertical-align: top;

	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}



/* MOBILE GAME LANDSCAPE */
.landscape .roadmap-table.v3 .back-text {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 100%;
	height: fit-content;
	font-size: 4vh;
}

.landscape .roadmap-table.v3 .row,
.landscape .roadmap-table.v3 .row > div {
	height: 2vh;
}

.landscape .roadmap-table.v3 .row > div {
	width: 1vw;
}

.landscape .baccarat-roadmap .left-group .roadmap-table.v3.m25 {
	transform: scale(1.14);
}

.landscape.iphone .baccarat-roadmap .left-group .roadmap-table.v3.m25 {
	transform: unset;
}

.landscape .baccarat-roadmap .left-group .roadmap-table.v3 .row,
.landscape .baccarat-roadmap .left-group .roadmap-table.v3 .row > div {
	height: 4vh;
}

.landscape .baccarat-roadmap .left-group .roadmap-table.v3 .row > div {
	width: 2vw;
}

.landscape .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 2.5vh;
}

// for hilo
.landscape .roadmap-table.v3.m34 .sl-baccarat-bead-value {
	font-size: 1.6vh;
}

.landscape .baccarat-prediction-container {
	width: 12.7vw;
}


.landscape .baccarat-prediction-container > .baccarat-prediction-letter {
	display: inline-block;
}

.landscape .roadmap-container.predictions .roadmap-table.v3 .player-container, 
.landscape .roadmap-container.predictions .roadmap-table.v3 .banker-container {
	display: inline-block;
	width: 9vw;
	height: 7vh;
}

.landscape .roadmap-container.predictions .roadmap-table.v3 .player-container {
	border-bottom: 1px solid rgb(204,204,204);
}

.landscape .roadmap-container.predictions .roadmap-table.v3 .player-container > div, 
.landscape .roadmap-container.predictions .roadmap-table.v3 .banker-container > div {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 2.5vw;
	height: 5vh;
	margin-left: 0.5vw;
	margin-top: 1vh;
	white-space: nowrap;
}

.landscape .roadmap-container.predictions .roadmap-table.v3 .player-container > .sl-roadmap-slash,
.landscape .roadmap-container.predictions .roadmap-table.v3 .banker-container > .sl-roadmap-slash {
	margin-top: 1.5vh;
}





/* MOBILE GAME PORTRAIT */
.sl-single-mobile.portrait .roadmap-table.v3 {
	background: white;

	position: absolute;
	height: fit-content;
	top: 0;
	bottom: 3vh;
	margin: auto;

	left: 0;
	right: 0;
}

.sl-single-mobile.portrait .roadmap-table.v3.m25 {
	transform: scale(1.1);
}

.sl-single-mobile.portrait .roadmap-table.v3 .row > div {
	box-sizing: border-box;
	border: 1px solid #e9e9e9;

	width: 6vw;
	height: 3vh;
}

.sl-single-mobile.portrait .roadmap-table.v3 .back-text {
	font-size: 3vh;
}

.sl-single-mobile.portrait .roadmap-table.v3 .sl-baccarat-bead-value {
	font-size: 2vh;
	line-height: 3vh;
}

// for hilo
.sl-single-mobile.portrait .roadmap-table.v3.m34 .sl-baccarat-bead-value {
	font-size: 1.6vh;
}
