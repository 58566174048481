/* SINGLE MOBILE PORTRAIT */

.sl-single-mobile.portrait .m10-portrait {
	.sl-bet {
		height: 8vh;
		line-height: 8vh;

		& > img {
			height: 7vh;
			margin-top: 0.5vh;
		}

		& > div:not(.bet-type) {
			display: inline-block;
			vertical-align: top;
		}

		& > img.smaller:first-child {
			margin-top: 1.5vh;
		}

		& > img.smaller {
			height: 5vh;
		}

		& > img.smallest {
			height: 3vh;
		}
	}

	.col-xs-3 > img {
		height: 3.5vh;
		margin-top: 2.8vh;
	}

	.col-40p > img {
		height: 5.5vh;
		margin-top: 1.8vh;
	}

	.col-xs-6 > img {
		height: 5vh;
		margin-top: 1.8vh;
	}

	.col-20p {
		text-align: center;
		height: 8vh;
		line-height: 8vh;
	}
}
