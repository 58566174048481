.game-statistics .new-statistics {
	position: relative;
	justify-content: center;
	display: flex;

	.red-bg {
		background: #dc1e42;
		border: 3px solid white;
		color: white;
	}

	.blue-bg {
		background: #1a77d4;
		border: 3px solid white;
		color: white;
	}

	.green-bg {
		background: #23be35;
		border: 3px solid white;
		color: white;
	}

	.black-bg {
		background: black;
		border: 3px solid white;
		color: white;
	}

	.yellow-bg {
		background: yellow;
		border: 3px solid white;
		color: black;
	}

	.white-bg {
		background: lightgray;
		border: 3px solid white;
		color: white;
	}

	.red-circle {
		background: transparent;
		border: 3px solid #dc1e42;
		color: #dc1e42;
	}

	.blue-circle {
		background: transparent;
		border: 3px solid #1a77d4;
		color: #1a77d4;
	}

	.sl-roadmap-red,
	.sl-roadmap-black {
		border: 3px solid white;
	}
}

.game-statistics .new-statistics.top {
	& > sl-roadmap-circle {
		border-radius: 50%;
	}

	& > div > img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}
}

/* SINGLE GAME DESKTOP */
.sl-game.sl-single-game .game-statistics .new-statistics {
	top: 12px;

	& > div {
		width: 40px;
		height: 40px;
		line-height: 35px;

		margin: 5px;
		font-size: 16px;
		text-align: center;
	}

	&.top > div {
		width: 34px;
		height: 34px;
	}
}

.sl-game.sl-single-game .game-statistics.m7 .new-statistics.top > div {
	width: 40px;
	height: 40px;
}

/* SINGLE GAME DESKTOP V4 */
.sl-game.sl-single-game-v4 .game-statistics .new-statistics {
	top: 0;

	& > div {
		width: 3vh;
		height: 3vh;
		line-height: 2.5vh;

		margin: 0.5vh;
		font-size: 2vh;
		text-align: center;
	}

	&.top > div {
		width: 2.4vh;
		height: 2.4vh;
	}
}

/* V4 MULTI TABLE */
.sl-game.v4:not(.sl-single-game-v4) .game-statistics .new-statistics {
	top: 0;

	& > div {
		width: 2.4vh;
		height: 2.4vh;
		line-height: 2vh;

		margin: 0.4vh;
		font-size: 1.2vh;
		text-align: center;
	}

	&.top > div {
		width: 2vh;
		height: 2vh;
	}
}

/* SINGLE MOBILE LANDSCAPE */
.sl-single-mobile.landscape .game-statistics .new-statistics {
	top: 5vh;

	& > div {
		width: 4vw;
		height: 4vw;
		line-height: 3.5vw;

		margin: 0.5vw;
		font-size: 4vh;
		text-align: center;
	}

	&.top > div {
		width: calc(4vw - 6px);
		height: calc(4vw - 6px);
	}
}

.sl-single-mobile.landscape .game-statistics.m7 .new-statistics.top > div {
	width: 4vw;
	height: 4vw;
}

/* SINGLE MOBILE V4 LANDSCAPE */
/* CLONE OF V1 */
.sl-single-mobile-v4.landscape .game-statistics .new-statistics {
	top: 0;

	& > div {
		width: 3vw;
		height: 3vw;
		line-height: 2.5vw;

		margin: 0.4vw;
		font-size: 3vh;
		text-align: center;
	}

	&.top > div {
		width: calc(3vw - 6px);
		height: calc(3vw - 6px);
	}
}

.sl-single-mobile-v4.landscape .game-statistics.m7 .new-statistics.top > div {
	width: 3vw;
	height: 3vw;
}

/* SINGLE MOBILE PORTRAIT */
.sl-single-mobile.portrait .game-statistics .new-statistics {
	top: 6vh;

	&.top > div {
		width: calc(7vh - 6px);
		height: calc(7vh - 6px);
	}

	& > div {
		width: 7vh;
		height: 7vh;
		line-height: 6.5vh;

		margin: 0.5vh;
		font-size: 3vh;
		text-align: center;

		color: white;
	}
}

.sl-single-mobile.portrait .game-statistics.m7 .new-statistics.top > div {
	width: 7vh;
	height: 7vh;
}

/* END NEW STATISTICS */

.sl-single-mobile.landscape .game-statistics {
	table {
		width: 100%;
		border-collapse: collapse;
	}

	tr {
		height: 5vh;
	}

	tr:nth-child(even) {
		background: #26273d;
	}

	td {
		width: 50%;
		border: none;
	}

	tr {
		height: unset;
	}
}

.sl-single-mobile .game-statistics {
	position: relative;
	width: 100%;
	height: 100%;
	bottom: unset;
	text-align: left;
}

.landscape .game-statistics {
	text-align: center;
	background: #151624;
}

.sl-game.v2.sl-single-game-v2 .game-statistics {
	position: absolute;
	left: 0;
	bottom: 9.5vh;
	background: white;
	height: 8vh;

	& > table {
		width: 100%;
		height: 100%;
	}

	tr {
		display: block;
		text-align: center;
	}

	tr:first-child {
		// margin-top: 2vh;
		height: 2vh;
	}

	td {
		display: inline-block;
		position: relative;
		width: 1.4vw;
		height: 2.8vh;

		margin: 0 1vw;
		vertical-align: top;
	}
}

// statistik m34
.sl-game.v2.sl-single-game-v2 .game-statistics.m34 tr:first-child {
	margin: 0;
}
.sl-game.v2.sl-single-game-v2 .game-statistics.m34 td {
	margin: 0 1.5vw;
}

.sl-game.v2.sl-single-game-v2 .game-statistics.m23 tr:first-child {
	margin: 0;
}

.sl-game.v2.sl-single-game-v2 .game-statistics.m23 td {
	margin: 0 0.25vw;
}

.sl-game.v2.sl-single-game-v2 > .table-container .game-statistics td {
	margin: 0 1.4vw;
}

.sl-game.v2.sl-single-game-v2 .game-statistics tr:nth-child(2) td div {
	font-size: 2vh;
}

.sl-game.v2.sl-single-game-v2 > .baccarat-left-container {
	width: 27.7vw;

	& > .game-statistics {
		position: relative;
		bottom: unset;
	}

	& > .game-statistics .sl-baccarat-bead-value {
		font-size: 1.5vh;
	}

	& > .game-statistics td {
		width: 1.1vw;
		height: 2.2vh;
	}

	.game-statistics {
		height: 8.4vh;

		tr:nth-child(2) td div {
			font-size: 1.6vh;
		}
	}
}

.sl-game.v2.sl-single-game-v2 .baccarat-prediction-container {
	background: white;
	display: inline-block;
	width: 3.72vw;

	& > .baccarat-prediction-letter {
		vertical-align: top;
		display: inline-block;

		color: white;
		width: 1.86vw;
		line-height: 3.5vh;
		font-size: 2vh;
	}

	& > .player-container,
	& > .banker-container {
		display: inline-block;

		width: 1.86vw;
		text-align: center;
	}

	& > .player-container {
		background: #d0dbeb;
	}

	& > .banker-container {
		background: #f1e0e1;
	}

	& > div > div {
		width: 1.06vw;
		height: 2vh;
		margin-top: 0.8vh;
		margin-left: 0.21vw;
		margin-right: 0.21vw;
		display: inline-block;
		vertical-align: middle;
	}

	& .circle-hollow {
		position: relative;
		background: transparent;
		border-radius: 50%;
	}

	& .circle-hollow > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		border-width: 2px;
		border-style: solid;
	}

	& .sl-roadmap-red {
		border-color: #b41b37;
	}

	& .sl-roadmap-blue {
		border-color: #1f5992;
	}

	& > div > .circle {
		border-radius: 50%;
	}

	& > div > .slash {
		border-bottom-style: solid;
		border-bottom-width: 2px;
		transform: translate(-30%, -30%) rotate(-45deg);
		background: transparent;
	}
}

.sl-single-mobile.portrait > .min .game-statistics table {
	width: 100%;
}

.sl-single-mobile.portrait > .min .game-statistics td {
	width: 50%;
	height: 3vh;
	font-size: 4vw;
}

//New Multiline table
.portrait .game-statistics.multiline table {
	width: fit-content;
	height: fit-content;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: block;
	table-layout: fixed;
	position: absolute;

	tr {
		display: block;
	}

	td {
		display: inline-block;
		vertical-align: top;
		position: relative;
		color: white;
		text-align: center;

		width: 14vw;
		// height: 6vw;
		margin: 0.2vh 0.5vw;

		img {
			position: relative;
			width: auto;
			height: 4vh;
			top: 1vh;
		}

		.sl-roadmap-circle,
		.sl-roadmap-circle-hollow {
			font-size: 2vh;

			position: relative;
			top: 1vh;
			left: 3vw;

			width: 8vw;
			height: 4vh;

			line-height: 4vh;

			& > div {
				font-size: 2vh;
			}
		}

		.value {
			font-size: 2vh;
		}
	}
}
