.sl-single-mobile-v2.landscape > .max > .chips-container,
.sl-single-mobile-v4.landscape > .max > .chips-container {
	position: fixed;
	width: 10vw;
	height: 68vh;
	left: 0.5vw;
	bottom: unset;
	right: unset;
	top: 30vh;

	background: transparent;

	border-radius: 1vh;
	border: unset;
	box-shadow: unset;
	text-align: center;

	transition: 0.4s;

	& > div > div {
		vertical-align: top;
	}

}