/* define width & height manually */
.flipping-card {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	transform-style: preserve-3d;
	transition: 0.5s all;
}

.flipping-card.lie {
	transform: rotateX(90deg);
}

.flipping-card.active {
	transform-origin: 50% 100%;
	transform: rotateY(180deg);
}

.flipping-card.lie.active {
	transform-origin: 50% 100%;
	
	/* Matrix transforms with relative translation must be written very specifically in their own class */
	/* transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg) translate(-1.1vw, 3vh); */
}

.flipping-card > img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	margin: auto;
}

.flipping-card.lie.active > img {
	width: 108%;
}

.flipping-card > img.frontside,
.flipping-card > img.backside {
	position: absolute;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.flipping-card > img.frontside {
	transform: rotateY(180deg);
	z-index: 1;
}

