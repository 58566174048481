@import
	"./tabs",
	"./unsubmitted_tickets";

.sl-single-game.machine-game {
	.table-desc {
		width: 100px;
	}

	& > .min > .stream-container {
		left: 8px;

		& > object {
			width: 306px;
			height: 172px;
		}
	}

	.video-off-notice-container	{
		width: 306px;
		height: 172px;
	}
}

.sl-single-game.machine-game .stream-container.bet-closed .video-off-notice-container {
    width: 760px;
    height: 460px;
}

.sl-single-game.machine-game > .min > .stream-container.bet-closed {
	left: 325px;
    top: 50px;

	z-index: 11;

	& > object {
		width: 755px;
		height: 420px;
	}

	&.m30 {
		left: 420px;
		top: 8px;
	
		& > object {
			width: 660px;
			height: 450px;
		}
	}
}


.sl-single-game.machine-game .group-timer {
	width: 100px;
	height: 80px;
	top: 430px;

	box-sizing: border-box;

	text-align: center;

	& .timer {
		width: unset;
		height: unset;

		font-size: 30px;

		border-radius: 25px;

		right: unset;
	}

	& > div {
		position: relative;

		left: unset;
		line-height: initial;
	}
}

.sl-single-game.machine-game .bet-label {
	font-size: 18px;
}

.sl-single-game.machine-game .table-desc {
    text-align: left;

    tr > td:first-child > div {
        margin-left: 6px;
    }

    tbody td {
        font-size: 14px;
    }

    tbody td.desc-heading {
        font-size: 20px;
    }
}

.sl-single-game.machine-game .details {
	top: 470px;
}

.sl-single-game.machine-game .details > .total-bet,
.sl-single-game.machine-game .details > .total-win {
	width: 378px;
	height: unset;
	padding-top: 0;
}

// History and Statistics container
.sl-single-game.machine-game .history-statistics-container {
	top: 190px;
}

/* m26, m29, m30 Running Tickets Container */
.sl-single-game.machine-game .history-statistics-container.m26,
.sl-single-game.machine-game .history-statistics-container.m29,
.sl-single-game.machine-game .history-statistics-container.m30 {
	top: 378px;
}

.sl-single-game.machine-game > .max > .table {
	position: absolute;

	bottom: 45px;
}

.sl-single-game.machine-game .sl-table {
	height: 60px;
}

.sl-single-game.machine-game > .max > .controller {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 8px;

	margin: auto;
}

@keyframes blink-background {
	0% { background: #41527e; }
	50% { background: black; }
	100% { background: #41527e; }
}
