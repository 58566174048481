.video-quality-button {
    color: white;
    z-index: 10;

    background: #26273d;
    border: 1px solid #41527e;

    box-sizing: border-box;

    cursor: pointer;
}

.popup-new {
    width: 240px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    //left: 105%;
    margin-left: -80px;   
    font-size: 1.5vh;
    line-height: 1.5;
}

.popup-new:after {
    content: " ";
    position: absolute;
    top: 25px;
    right: 100%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.closebtn {
    background-color: red;
    color: white;
    font-size: 2vh;
    position: absolute;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    top: -8px;
    right: -8px;
}

.sl-single-game .video-quality-button {
    position: absolute;

    left: 27%;
    width: 64px;
    line-height: 64px;

    // sementara dihilangkan
    .popup-new {
        display: none;
    }
}

.sl-single-game-v2 .video-quality-button,
.sl-single-game-v4 .video-quality-button {
    position: fixed;

    font-size: 2vh;
    width: 3vw;
    line-height: 4vh;

    border-radius: 2vh;

    top: 23vh;
    left: 3vw;

    .popup-new {
        top: -30%;
        left: calc(36px + 6vw);
    }
}

.portrait .video-quality-button {
    position: absolute;

    width: 10vw;
    line-height: 4vh;
}

.sl-single-mobile-v2.landscape .video-quality-button,
.sl-single-mobile-v4.landscape .video-quality-button {
    position: fixed;

    left: 10vw;
    top: 10vh;

    width: 5vw;
    line-height: 8vh;

    border-radius: 4vh;
    text-align: center;

    .popup-new {
        width: 15vw;
        font-size: 3vh;
        top: 0;
        left: calc(10% + 15vw);
    }

    .closebtn {
        font-size: 4vh;
    }
}

.sl-single-mobile-v2.iphone.landscape .video-quality-button,
.sl-single-mobile-v4.iphone.landscape .video-quality-button {
    left: 15vw
}
