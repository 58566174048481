.mobile-home.portrait .game-box {
	// width: 48vw;
	// margin: 0.5vh 0.75vw;

	text-align: center;
	background: #27283a;

	&.full-width {
		width: 96vw;
	}

	.left-button div img {
		width: 100%;
	}

	.history-container {
		width: 90%;
	}

	.history-container.history-balls {
		position: relative;
		left: 2%;
	}

	& > .game-3d {
		height: 11vh;
	}
	
	.time-left {
		font-size: 3vh;

		& > div {
			height: unset;
		}
	}

	.time-left-v5 {
		position: absolute;
		right: 1vw;
		bottom: 0.5vh;

		box-sizing: border-box;
		height: 4.5vh;
		border-radius: 12px;
		border: 1px solid #6c66fb;

		color: #21deab;
		background: #10111c;

		padding: 4px;

		display: flex;
		align-items: center;
		justify-content: center;

		margin: 0 5px;

		& > div {
			font-size: 12px;
		}
	}

}
