.roadmap-table .sl-tooltip {
    color: white;
    height: 7vh;
    text-align: center;
    background-color: rgba(0,0,0,0.8);
    border: 1px solid #870fff;
    border-radius: 1vh;
    position: absolute;
    margin: 0 auto;

    font-size: 1.4vh;
	
	cursor: default;
	
}

.roadmap-table td {
	cursor: default;

	&:hover .sl-tooltip {
		z-index: 3;
		opacity: 1;
	}
}

.roadmap-table .sl-tooltip:after,
.roadmap-table .sl-tooltip:before {
	top: 100%;
	left: 7.5%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
}

.roadmap-table .sl-tooltip:after {
	border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-left: -5px;
}

.roadmap-table .sl-tooltip:before {
	border-color: rgba(135, 15, 255, 0);
    border-top-color: #870fff;
    border-width: 6px;
    margin-left: -6px;
}

.roadmap-table .sl-tooltip > div {
	display: inline-block;
	vertical-align: top;
}

.roadmap-table .sl-tooltip > .value-container {
	position: relative;
	height: 4.5vh;
	top: 1.5vh;
}

.roadmap-container .roadmap-table .sl-tooltip > .value-container > .player-letter,
.roadmap-container .roadmap-table .sl-tooltip > .value-container > .banker-letter {
	font-size: 1.5vh;
	
	position: relative;
	left: unset;
	top: unset;
	right: unset;
	margin: unset;
	
	width: 1.2vw;
	height: 2vh;
}

.roadmap-table .sl-tooltip > .value-container > .letter-value {
	position: relative;
	font-size: 1.5vh;
	display: block;
	text-align: center;
}



.roadmap-table .sl-tooltip .separator {
	margin-top: 0.5vh;
	height: 80%;
	border: 1px solid white;
}

.roadmap-table .sl-tooltip .card-container {
	position: relative;
	top: 0.5vh;
}

.roadmap-table .sl-tooltip .card-container > div {
	display: inline-block;
	width: 2.5vw;
}

.roadmap-table .sl-tooltip .card-container .lie {
	transform: rotate(90deg);
	min-width: 3.5vw;
}

.roadmap-table .sl-tooltip .card-container img {
	width: 2vw;
}


.roadmap-table td:first-child .sl-tooltip {
	left: 0;
}

.roadmap-table td:first-child .sl-tooltip:after,
.roadmap-table td:first-child .sl-tooltip:before {
	left: 2.5%;
}



.roadmap-table .sl-tooltip.card-tooltip-m22 {
    width: 20vw;
    top: -8vh;
    left: -1vw;
    right: -9vw;
}

//Roadmap card hover
.roadmap-container .roadmap-table .row > div:hover {
	.card-tooltip-m22,
	.card-tooltip-m23,
	.card-tooltip-m27 {
		opacity: 1;
		z-index: 1;
	}
}

.roadmap-table .sl-tooltip.card-tooltip-m23,
.roadmap-table .sl-tooltip.card-tooltip-m27 {
    width: 10vw;
    top: -8vh;
    left: -1vw;
	right: -9vw;
}

.roadmap-table .sl-tooltip.card-tooltip-m23 {
	.extra-container {
		width: 1vw;
		margin: 0 0.2vw;
	}
	
	.separator {
		margin: 0.5vh 0.4vw;
	}
	
	.sl-roadmap-circle-hollow {
		width: 75%;
	}
}

.roadmap-table .sl-tooltip.card-tooltip-m27 {
	.sl-roadmap-circle {
		width: 3.5vw;
		height: 7vh;
		line-height: 7vh;
	}

	.winner {
		width: 3vw;
		line-height: 3.5vh;

		& > div:last-child {
			border-radius: 1.75vh;

			&.di {
				background: #b41b37;
			}

			&.tie {
				background: green;
			}

			&.tian {
				background: #1f5992;
			}
		}
	}

	img {
		display: inline-block;
		vertical-align: middle;

		width: auto;
		height: 5vh;
	}
}
