.sl-single-game-v4 .m24-history-container {
	position: relative;
	background: white;
	width: 100%;

	& > div {
		vertical-align: top;
	}

	.number {
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		border-right: 1px solid #333;
		border-bottom: 1px solid #333;
		width: 4vw;
		height: 4vh;
		vertical-align: top;

		& > div:first-child {
			font-weight: bold;
		}
	}

	.number-container {
		display: inline-block;
		width: 40vw;
		overflow-x: scroll;
		white-space: nowrap;
		overflow-y: hidden;
		vertical-align: top;
	}

	.name {
		height: 4vh;
		line-height: 4vh;

		box-sizing: border-box;

		border-bottom: 1px solid #333;
	}

	.name-container {
		display: inline-block;
		width: 5vw;
		border-right: 0.05vw solid #333;
	}

	.blue {
		color: #25baf1;
	}
	
	.win {
		position: absolute;
		bottom: 0;
		width: 100%;
	
		font-family: Lato-Bold;
		font-size: 1vh;
		background: #21deab;
	}
}

.sl-single-game-v4 .dark .m24-history-container {
	.number {
		border-right: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}
	
	.name {
		border-bottom: 1px solid #eee;
	}
	
	.name-container {
		border-right: 1px solid #eee;
	}
}
