.mobile-home.landscape .game-box {
	height: 35vh;
	width: 32vw;
	margin: 0.5vh 0.5vw;

	& > .game-3d {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		height: 22vh;
	}

	&.full-width {
		height: 42vh;
	}

	.left-button {
		text-align: center;
		width: 5.4vw;

		div {
			height: 11vh;
		}
	}

	.lobby-roadmap-table > tbody {
		tr,
		td {
			height: 5.4vh;
		}

		td {
			width: 2.4vw;
		}
	}

	&.full-width .time-left {
		bottom: 4.5vh;
	}

	.time-left {
		width: 5.5vw;
		min-width: 5.5vw;
		height: 5.5vw;
		min-height: 5.5vw;

		div {
			font-size: 6vh;
		}

		div.betclose {
			font-size: 2.7vh;
		}
	}

	.time-left-v5 {
		height: 5.5vw;
		padding: 0 4px;

		box-sizing: border-box;

		border-radius: 12px;
		border: 1px solid #6c66fb;

		color: #21deab;
		background: #10111c;

		display: flex;
		align-items: center;
		justify-content: center;

		margin: 0 5px;

		& > div {
			font-size: 16px;
			line-height: 1;
		}
	}
}
