/* 
	Base template:
	@keyframes chipglow {
		from { filter: drop-shadow(0 0 2px #5bc9ff); }
		to { filter: drop-shadow(0 0 10px #5bc9ff); }
	}
 */

 @mixin colourglow($name, $colour) {
	@keyframes chipglow_#{$name} {
		from {
			filter: drop-shadow(0 0 1px $colour);
		}
		to {
			filter: drop-shadow(0 0 5px $colour);
		}
	}
}

//Note: get chip glow colour from base colour, turn the brightness to 100%
@include colourglow(blue, #5bc9ff);
@include colourglow(green, #89ff99);
@include colourglow(red, #ff2424);
@include colourglow(yellow, #ffce00);
@include colourglow(orange, #ff7f00);
@include colourglow(purple, #a32cbf);
@include colourglow(white, white);
@include colourglow(brown, #ffc88d);
@include colourglow(dark-blue, #4242ff);

$colours: blue, green, red, yellow, orange, purple, brown, dark-blue;

.portrait .chips-container .chips-container-inside .chip.active,
.landscape .chips-container .chips-container-inside .chip.active,
.chips-container > .chip.active {
	@each $colour in $colours {
		&.#{$colour} {
			animation: chipglow_#{$colour} 0.4s alternate infinite;
		}
	}

	//Default
	& > img {
		animation: chipglow_white 0.4s alternate infinite;
	}
}

// GENERIC HISTORY COLOURS
.side-result-history-panel {
	.result-history-table .bg-black {
		box-sizing: border-box;
	}
	
	//Taken from DT
	.bg-red {
		background: #ff715f;
	}
	
	.bg-yellow {
		background: #ffff5f;
		color: black;
	}
	
	.bg-blue {
		background: #124682;
	}
	
	.bg-green {
		background: #23be35;
	}
	
	.bg-black {
		background: black;
		border: 1px solid white;
	}
	
	.bg-white {
		background: white;
		color: black;
	}
}

.sl-black-sh {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3c3c3c+0,191818+100 */
	background: #3c3c3c; /* Old browsers */
	background: -moz-linear-gradient(top,  #3c3c3c 0%, #191818 72%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #3c3c3c 0%,#191818 72%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #3c3c3c 0%,#191818 72%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c3c3c', endColorstr='#191818',GradientType=0 ); /* IE6-9 */
}

.sl-blue-sh{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#21c6d4+0,178891+49,0e545a+100 */
	background: #21c6d4; /* Old browsers */
	background: -moz-linear-gradient(top, #21c6d4 0%, #178891 49%, #0e545a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #21c6d4 0%,#178891 49%,#0e545a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #21c6d4 0%,#178891 49%,#0e545a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21c6d4', endColorstr='#0e545a',GradientType=0 ); /* IE6-9 */
}

.sl-blue-sh:hover{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#21c6d4+0,178891+49,0e545a+100 */
	background: #3fe2f0; /* Old browsers */
	background: -moz-linear-gradient(top, #3fe2f0 0%, #2aaeb8 49%, #0e545a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #3fe2f0 0%,#2aaeb8 49%,#0e545a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #3fe2f0 0%,#2aaeb8 49%,#0e545a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3fe2f0', endColorstr='#0e545a',GradientType=0 ); /* IE6-9 */
}

.sl-green-sh{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#319116+0,1e590e+50,17440a+100 */
	background: #319116; /* Old browsers */
	background: -moz-linear-gradient(top, #319116 0%, #1e590e 50%, #17440a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #319116 0%,#1e590e 50%,#17440a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #319116 0%,#1e590e 50%,#17440a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#319116', endColorstr='#17440a',GradientType=0 ); /* IE6-9 */
}

.sl-green-sh:hover{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#319116+0,1e590e+50,17440a+100 */
	background: #4eb930; /* Old browsers */
	background: -moz-linear-gradient(top, #4eb930 0%, #388c21 50%, #17440a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #4eb930 0%,#388c21 50%,#17440a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #4eb930 0%,#388c21 50%,#17440a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4eb930', endColorstr='#17440a',GradientType=0 ); /* IE6-9 */
}

.sl-red-sh{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b60711+0,7f050c+50,500307+100 */
	background: #b60711; /* Old browsers */
	background: -moz-linear-gradient(top, #b60711 0%, #7f050c 50%, #500307 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #b60711 0%,#7f050c 50%,#500307 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #b60711 0%,#7f050c 50%,#500307 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b60711', endColorstr='#500307',GradientType=0 ); /* IE6-9 */
}

.sl-red-sh:hover{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b60711+0,7f050c+50,500307+100 */
	background: #e10814; /* Old browsers */
	background: -moz-linear-gradient(top, #e10814 0%, #b5040e 50%, #500307 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #e10814 0%,#b5040e 50%,#500307 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #e10814 0%,#b5040e 50%,#500307 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e10814', endColorstr='#500307',GradientType=0 ); /* IE6-9 */
}

.sl-yellow-sh{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3c92e+0,b79622+50,6d5914+100 */
	background: #f3c92e; /* Old browsers */
	background: -moz-linear-gradient(top, #f3c92e 0%, #b79622 50%, #6d5914 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f3c92e 0%,#b79622 50%,#6d5914 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f3c92e 0%,#b79622 50%,#6d5914 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3c92e', endColorstr='#6d5914',GradientType=0 ); /* IE6-9 */
}


.sl-yellow-sh:hover{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3c92e+0,b79622+50,6d5914+100 */
	background: #fcd855; /* Old browsers */
	background: -moz-linear-gradient(top, #fcd855 0%, #daba48 50%, #6d5914 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #fcd855 0%,#daba48 50%,#6d5914 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #fcd855 0%,#daba48 50%,#6d5914 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcd855', endColorstr='#6d5914',GradientType=0 ); /* IE6-9 */
}

.sl-green-light-sh{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7fbe28+0,56831b+50,324c10+100 */
	background: #7fbe28; /* Old browsers */
	background: -moz-linear-gradient(top, #7fbe28 0%, #56831b 50%, #324c10 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #7fbe28 0%,#56831b 50%,#324c10 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #7fbe28 0%,#56831b 50%,#324c10 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7fbe28', endColorstr='#324c10',GradientType=0 ); /* IE6-9 */
}

/* ----------------- COLORS -------------------------- */
.sl-black{
	background-color: #000000;
}

.sl-black-lighten{
	background-color: #1f2022;
}

.sl-semiblack {
	background-color: rgba(0,0,0, 0.5);
}

.sl-white{
	background-color: #ffffff;
}

.sl-green{
	background-color: #225f1a;
}

.sl-green-lighten{
	background-color: #6bae47;
}

.sl-blue{
	background-color: #09437f;
}

.sl-blue-lighten{
	background-color: #155068;
}

.sl-bluegreen{
	background-color: #17a48a;
}

.sl-gray{
	background-color: #878787;
}

.sl-gray-darken{
	background-color: #343333;
}

.sl-red{
	background-color: #ff0000;
}

.sl-red-darken{
	background-color: #8b1111;
	/*background-color: #b83432;*/
}

.sl-red-lighten{
	background-color: #b83432;
}

.sl-yellow {
	background-color: #ffcc00;
}

.sl-green-old {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#369729+0,1f5818+100 */
	background: #225c1f;
/* 	background: -moz-radial-gradient(center, ellipse cover, #225c1f 0%, #225c1f 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #225c1f 0%,#225c1f 100%);
	background: radial-gradient(ellipse at center, #225c1f 0%,#225c1f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#225c1f', endColorstr='#225c1f',GradientType=1 ); */
}

.sl-yellow-alternating {
	animation: sl-yellow-transition 0.25s infinite alternate;
}

//Custom for FanTan
.portrait .m25 .col-xs-4.yellow.sl-yellow-alternating {
	animation: custom-fantan-yellow-transition 0.25s infinite alternate;
}

.portrait .m25 .col-xs-4.white.sl-yellow-alternating {
	animation: custom-fantan-white-transition 0.25s infinite alternate;
}

.portrait .m25 .col-xs-4.red.sl-yellow-alternating {
	animation: custom-fantan-red-transition 0.25s infinite alternate;
}


.sl-green-new {
	background: #24A74A;
}

.sl-cyan-new {
	background: #119190;
}

.sl-gongball-blue {
	/* background: #2155A8; */
	background: #3565f1;
}

.sl-gongball-red {
	/* background: #BC3535; */
	background: #f11818;
}

.sl-text-gongball-blue {
	color: #3565f1;
}

.sl-text-gongball-red {
	color: #f11818;
}

@keyframes sl-yellow-transition {
	to {
		background: #ffcc00;
		border-color: #ffcc00;
	}
}

//Custom for FanTan
@keyframes custom-fantan-yellow-transition {
	to {
		border-left-color: #ffcc00;
	}
}

@keyframes custom-fantan-white-transition {
	to {
		border-right-color: #ffcc00;
	}
}

@keyframes custom-fantan-red-transition {
	to {
		border-bottom-color: #ffcc00;
	}
}
