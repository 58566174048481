
.sl-single-mobile.portrait .stream-container {
	display: block;
	position: absolute;
	width: 100vw;
	height: 26vh;
	left: 0;
}

.sl-single-mobile.portrait .stream-filler {
	position: absolute;
	top: 0;

	width: 100%;
	height: 26vh;
	
	color: white;
}

.loading-text > img:first-child {
	animation: rotation 1s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(359deg);
	}
	
	to {
		transform: rotate(0deg);
	}
}



.video-off-notice-container {
    width: 100%;
    height: 100%;
	
	&.loaded {
		background: transparent;
		pointer-events: none;
	}
	
	&.is-loading,
	&.no-streaming {
		background: url(img_video_background.jpg);
		background-size: cover;
		background-position: center;
	}
	
	& > div {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+50,0+100 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 50%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		
		line-height: initial;
		display: inline-block;
		vertical-align: middle;
	}

	& > div > div {
		white-space: pre;
	}
}

.landscape.sl-single-mobile .video-off-notice-container.hugestreaming {
	width: 70vw;
	height: 90vh;
	
	.loading-text {
		width: 70vw;
		height: 90vh;
		top: 50vh;
		
		& > img {
			position: absolute;
			top: 30vh;
			left: 0;
			right: 0;
			margin: auto;
		}
		
		& > div {
			position: absolute;
			top: 50vh;
			width: 100%;
		}
	}
}

.sl-single-game .video-off-notice-container {
    width: 558px;
    height: 297px;
	
	font-size: 16px;
	
	& > div {
		height: 130px;
		color: white;
	}
	
	& > div > img {
		width: 80px;
	}
}

.sl-single-game-v2 .video-off-notice-container,
.sl-single-game-v4 .video-off-notice-container {
    width: 100vw;
    height: 90vh;
	
	font-size: 4vh;
	
	& > div {
		height: 50vh;
		color: white;
	}

	& > div > img {
		width: 10vw;
	}
}

.sl-multi-table .video-off-notice-container {
    width: 11vw;
	height: 11vh;
	
	font-size: 1.6vh;

	position: absolute;
	top: 0;
	
	& > div {
		height: 10vh;
		color: white;
	}

	& > div > img {
		width: 2.5vw;
	}
}


.sl-single-mobile-v2.landscape .video-off-notice-container,
.sl-single-mobile-v4.landscape .video-off-notice-container {
	&.is-loading, &.no-streaming {
		width: 100vw;
		height: 90vh;
		
		position: fixed;
	
		left: 0;
		top: 10vh;
	}
}

.sl-single-mobile.portrait .video-off-notice-container {
	font-size: 3vh;
	
	& > div {
		height: 18vh;
	}

	& > div > img {
		height: 8vh;
	}
}

.landscape .video-off-notice-container {
	font-size: 5vh;
	
	
	& > div {
		height: 30vh;
	}

	& > div > img {
		height: 15vh;
	}
}
