@mixin centre {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

// GENERIC ACCESSORIES
.game-history-buttons {
	cursor: pointer;
}

// The small roadmap icon by the descriptive text
.sl-single-game .roadmap-icon {
	width: 10px;
	height: 10px;
	margin-left: 4px;
	margin-right: 4px;
	position: relative;
	display: inline-block;
}

// BEGIN CONTROLLER BUTTONS

.controller {
	width: 19.15vw;
	padding-top: 0.6vh;
	display: grid;
	grid-gap: 0.53vw;
	grid-template-columns: repeat(4, 1fr);
}

.controller > button {
	font-family: "Lato-Regular";
	font-weight: bold;
	font-size: 1.4vh;

	width: 4.36vw;
	height: 2.8vh;

	border: none;
	color: white;
	border-radius: 0.85vw;
	cursor: pointer;

	text-shadow: 1px 2px 2px #262626;

	outline: none;
}

.sl-single-game-v4 .controller {
	width: 19.15vw;
	padding-top: 0;
	display: grid;
	grid-gap: 0.53vw;
	grid-template-columns: repeat(4, 1fr);
}

.sl-single-game-v4 .controller > button {
	font-family: "Lato-Regular";
	font-weight: bold;
	font-size: 1.4vh;

	width: 7vw;
	height: 4vh;
	border: none;
	color: white;
	border-radius: 0.85vw;
	cursor: pointer;

	text-shadow: 1px 2px 2px #262626;
}

// END CONTROLLER BUTTONS

.sl-container {
	text-align: center;
	padding-top: 1.2vh;
}

.sl-wrapper > .sl-container {
	padding-bottom: 10vh;
	text-align: left;
}

.sl-game {
	width: 32vw;
	height: 27vh;
	margin: 0 0 1vh 0.5vw;
	display: inline-block;
	vertical-align: top;
	position: relative;
	text-align: center;
	// transition: 0.4s;
}

.sl-game > .header {
	text-align: left;
	background: #41527e;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	position: relative;
	height: 3vh;
}

.sl-game > .header > .logo {
	max-width: 1.5vw;
	max-height: 2vh;
	margin: 0.5vh 0.5vw;
	display: inline-block;
	vertical-align: middle;
}

.sl-game > .header > .name {
	font-weight: bold;
	font-size: 1.3vh;
	color: #fff;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: middle;
}

.sl-game > .header > .periode {
	font-size: 1.3vh;
	color: #fff;
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	text-align: center;
	line-height: 3vh;
	pointer-events: none;
}

.sl-game > .header > .restore,
.sl-game > .header > .maximise,
.sl-game > .header > .close {
	top: 0.5vh;
	font-size: 1.8vh;
	cursor: pointer;
	position: absolute;
	text-decoration: none;
}

.sl-game > .header > .maximise {
	right: 1.8vw;
	top: 0.7vh;
	height: 1.4vh;
}

.sl-game > .header > .restore {
	right: 2vw;
	top: 0.7vh;
	height: 1.4vh;
}

.sl-game > .header > .close {
	right: 0.5vw;
}

.sl-single-mobile.landscape {
	text-align: left;
}

.sl-game .max > .controller > button {
	outline: none;
}

/* SINGLE GAME */
.sl-single-game {
	position: relative;
	width: 1200px;
	height: unset;
}

.sl-single-game > .header {
	height: 30px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	position: relative;
}

.sl-single-game > .min {
	position: relative;
	width: 100%;
	height: 368px;
}

.sl-single-game > .min > div {
	position: absolute;
}

.sl-single-game > .min > .history-container {
	left: 8px;
	top: 30px;
	border: 1px solid #41527e;
	padding-left: 5px;
}

.sl-single-game .table-desc {
	position: absolute;

	right: 8px;
	top: 8px;
	font-size: 11px;

	background-color: #26273d;

	width: 300px;
	height: 288px;

	border-collapse: collapse;
}

.sl-single-game .table-desc tbody td {
	font-size: 16px;
}

.sl-single-game .table-desc tr,
.sl-single-game .table-desc td {
	border: 1px solid #41527e;
}

.sl-single-game .table-desc tr:first-child > td:first-child > div,
.sl-single-game .table-desc tr:nth-child(2) > td:first-child > div {
	margin-left: 8px;
	text-align: left;
}

.sl-single-game .table-desc tr:first-child > td:nth-child(2) > div,
.sl-single-game .table-desc tr:nth-child(2) > td:nth-child(2) > div {
	text-align: right;
	margin-right: 8px;
}

.sl-single-game .table-limit-bet {
	font-size: 14px;
}

.sl-single-game .desc-heading {
	font-size: 14px;
	color: #8b8bb3;
}

.sl-single-game .desc-content {
	font-size: 14px;
	color: #21deab;
}

.sl-single-game > .max {
	height: 256px;
	width: 100%;
	vertical-align: top;
	text-align: center;

	& > .table {
		padding-top: 10px;
	}

	& > .controller {
		width: 584px;
		padding-top: 6px;
		display: grid;
		grid-gap: 12px;
		grid-template-columns: repeat(4, 1fr);

		margin-left: auto;
		margin-right: auto;
	}

	& > .controller > button {
		width: 138px;
		border: none;
		color: white;
		font-weight: bold;
		font-size: 14px;
		height: 28px;
		border-radius: 12px;
		cursor: pointer;

		text-shadow: 1px 2px 2px #262626; /* #525252; */
	}
}

/* SINGLE GAME STREAM CONTAINER */
.sl-single-game > .min > .stream-container {
	position: absolute;
	left: 27%;
	top: 8px;
	transition: all 0.4s;
	z-index: 10;

	& > object {
		width: 554px;
		height: 303px;
		position: absolute;
		border: 1px solid #41527e;
		transition: all 0.4s;
		border: 2px solid #333d5e;
		left: 0px;
	}

	&:hover {
		left: 13.5%;
	}

	&.no-streaming:hover {
		left: 27%;
	}

	& > object:hover {
		border: 1px solid #41527e;
		box-shadow: 0 8px 20px 8px rgba(0, 0, 0, 0.5);
		width: 900px;
		height: 500px;
	}
}

/* End of stream-container */

.sl-single-game .details {
	/* left: 228px; */
	left: 27%;
	top: 312px;
	font-size: 16px;
}

.sl-single-game .details > .total-bet,
.sl-single-game .details > .total-win {
	width: 277px;
	height: 50px;
	text-align: center;
	border: 1px solid #41527e;
	padding-top: 5px;
	background: #26273d;
}

.sl-single-game .details > div {
	display: inline-block;
}

/* Group Timer */
.sl-single-game .group-timer {
	border-left: 1px solid #41527e;
	border-right: 1px solid #41527e;
	border-bottom: 1px solid #41527e;
	background-color: #1d1e2f;

	right: 9px;
	top: 296px;
	text-align: center;
	width: 298px;
	height: 72px;
}

.sl-single-game .timer {
	border-radius: 16px;
	border: 1px solid #384468;
	background: #10111c;

	text-align: center;

	width: 57px;
	height: 31px;

	position: relative;
	top: 8px;

	line-height: 36px;
}
.sl-single-game .timer:not(.bet-closed) {
	font-size: 24px;
}

.sl-single-game .timer.bet-closing,
.sl-single-game .timer.bet-closed {
	color: #e04f4b;
}

.sl-single-game .group-timer > div {
	position: absolute;
}

.sl-single-game .group-timer > div:first-child {
	left: 8px;
	line-height: 72px;
	font-size: 14px;
}

.sl-single-game .group-timer > div.timer {
	margin: auto;
	top: 0;
	bottom: 0;
	right: 8px;

	line-height: 31px;
}

.sl-single-game .timer.bet-open,
.sl-single-game .bet-open-text {
	color: #0ed503;
}

.sl-single-game .bet-closing-text {
	color: #e04f4b;
}

.sl-single-game .bet-closed-text {
	color: #8b8bb3;
}

/* SINGLE GAME ROADMAP CONTAINER */
.sl-single-game .roadmap-container {
	left: 8px;
	top: 155px;
	width: 308px;
}

.sl-single-game .roadmap-tabs {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

.sl-single-game .roadmap-tabs tr,
.sl-single-game .roadmap-tabs td {
	border: 1px solid #41527e;
}

.sl-single-game .roadmap-tab {
	font-size: 10px;
	color: #8b8bb3;
	background: #26273d;
	height: 18px;
	text-align: center;
	cursor: pointer;

	& > div {
		font-size: 8px;
	}
}

.sl-single-game .roadmap-tab:last-child {
	border: none;
}

.sl-single-game .roadmap-tab.active {
	color: white;
	background: #41527e;
}

.sl-single-game .roadmap-table {
	background: white;
	border-collapse: collapse;
}

.sl-single-game .roadmap-table.m11 {
	background: transparent;
}

.sl-single-game .roadmap-table tr,
.sl-single-game .roadmap-table td {
	border: 1px solid #e9e9e9;
}

.sl-single-game .roadmap-table td {
	width: 25.5px;
	height: 21px;
}

.sl-single-game .sl-roadmap-square,
.sl-single-game .sl-roadmap-circle {
	font-size: 14px;
	color: white;
	text-align: center;
}

/* History & Statistics container */
.sl-single-game .history-statistics-container {
	left: 8px;
	top: 8px;
	width: 308px;
	border: 1px solid #405280;

	background: #151624;

	.game-statistics {
		height: 114px;
	}

	.game-statistics table {
		width: 100%;
		background: #181a29;
	}

	.game-statistics tr {
		height: 33px;
	}

	.game-statistics:not(.m32) td:first-child {
		padding-left: 40px;
		width: 50%;
		text-align: left;
	}

	.game-statistics td:nth-child(2) {
		text-align: center;
	}

	.game-statistics tr:nth-child(even) {
		background: #26273c;
	}

	/*Special container for m7 and m19*/
	&.m7-m19 {
		top: 190px;
	}

	&.m33 {
		top: 160px;
	}

	&:not(.m17-m19) .history-container.m7,
	&:not(.m17-m19) .history-container.m19 {
		height: 150px;
	}

	&.m7-m19 .game-statistics {
		height: 155px;
	}

	&.m33 .game-statistics {
		height: 185px;
	}
}

.sl-single-game.machine-game .history-statistics-container {
	/* m26 Ticket Container */
	&.m26, &.m42 {
		top: 378px;
	}
}

/* ----------------- GAME HEADER -------------------------- */
.sl-single-game > .header {
	& > .logo {
		font-size: 30px;
	}

	& > .name {
		font-size: 13px;
	}

	& > .periode {
		font-size: 13px;
		line-height: 30px;
	}

	& > .close {
		top: 5px;
		font-size: 18px;
	}
}

.sl-single-game > .toggle-more-bet {
	position: absolute;
	bottom: 15px;
	left: 10px;

	cursor: pointer;

	display: block;
	background: transparent;
	border: none;

	& > img {
		left: 0;
		top: 0;
		height: 28px;
	}

	& > p {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;

		font-weight: bold;
		height:fit-content;

		font-size: 15px;
	}
}

/* MULTI TABLE */
.sl-multi-table .desc-heading {
	color: #717171;
}

.sl-multi-table .desc-content {
	color: #6c66fb;
}

.sl-multi-table .total-bet .desc-content {
	color: #21deab;
}

.sl-single-mobile.landscape > .header {
	background: #41527e;
}

.sl-game:not(.sl-single-mobile) > .header > div {
	display: inline-block;
	margin: 0;
	vertical-align: middle;
}

/* ----------------- GAME LEFT SIDE -------------------------- */

.sl-multi-table {
	background: #151624;
	border-top-left-radius: 0.69vw;
	border-top-right-radius: 0.69vw;
}

.sl-multi-table .stream-container {
	width: 11.06vw;
	height: 10.4vh;
	padding-top: 0.4vh;

	position: absolute;
	margin: 0;

	transition: all 0.4s;

	&:hover {
		top: 0.8vh;
		z-index: 12;
	}

	& > object {
		width: 10.64vw;
		height: 10vh;
		transition: all 0.4s;

		&:hover {
			border: 1px solid #41527e;
			box-shadow: 0 0.43vw 2vh 0.43vw rgba(0, 0, 0, 0.5);
			width: 19.15vw;
			height: 18.5vh;
		}
	}
}

@mixin dead-centre {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: fit-content;
}

.sl-multi-table > .min > .normalsize,
.sl-multi-table > .min > .fullsize {
	position: absolute;
	bottom: 1vh;
	background: white;
	height: 8vh;

	& > .roadmap {
		@include dead-centre;
	}
}

.sl-multi-table > .min > .normalsize {
	left: 2.7vw;
	width: 8.7vw;
}

.sl-multi-table > .min > .fullsize {
	left: 1.9vw;
	width: 9.5vw;
}

.sl-multi-table > .min,
.sl-single-mobile.landscape > .min {
	width: 11.5vw;
	height: 23vh;
	text-align: center;
	display: inline-block;
	padding-top: 1vh;
	padding-left: 0.4vw;
	position: relative;

	& > .details {
		display: inline-block;
		position: absolute;
		top: 11vh;
		left: 0.43vw;
		width: 7.45vw;
		height: 2.5vh;
		margin: 0.5vh 0;
		text-align: left;
	}

	& > .details > .total-bet {
		display: inline-block;
		width: 7.18vw;
		position: relative;
	}

	& > .details > .total-bet > p {
		margin: 0;
		display: inline-block;
		line-height: 2.5vh;
	}

	& > .details > .total-bet > .desc-heading {
		font-size: 1.2vh;
		margin-left: 0.16vw;
		color: #8b8bb3;
	}

	& > .details > .total-bet > .desc-content {
		font-size: 1.3vh;
		margin-left: 0.5vw;
	}

	& > .group-timer {
		position: absolute;
		width: 4.1vw;
		top: 11.7vh;
		left: 7.66vw;
	}

	// & > .group-timer > .timer {
	// 	height: 100%;
	// 	font-size: 1.8vh;
	// 	color: #e04f4b;
	// 	text-align: center;
	// 	vertical-align: top;
	// 	line-height: 2.5vh;
	// }

	& > .group-timer > .timer.bet-closed {
		color: rgb(255, 160, 160);
		font-weight: bold;
	}

	& > .group-timer > .timer.bet-closed > img {
		height: 3vh;
	}
}

.sl-multi-table > .min {
	& > .group-timer > .timer {
		height: 100%;
		font-size: 1.8vh;
		color: #e04f4b;
		text-align: center;
		vertical-align: top;
		line-height: 2.5vh;
	}
}

.sl-game .min-max-container {
	position: absolute;
	left: 2.66vw;
	bottom: 0.5vh;
	width: 8.72vw;
	height: 8.4vh;

	.table-desc {
		width: 100%;
		height: 100%;
		font-size: 1.1vh;
		color: #717171;
	}
}

/* ----------------- GAME HISTORY BUTTONS --------------------- */
.sl-game:not(.sl-single-game) .game-history-buttons {
	left: 0.43vw;
	bottom: 0.8vh;
	position: absolute;

	& > div {
		color: white;
		width: 1.38vw;
		height: 2.4vh;
		border-bottom: 2px solid #151624;
	}

	& > div > img {
		width: 1.38vw;
		max-height: 100%;
	}
}

/* ----------------- GAME RIGHT SIDE -------------------------- */

.sl-game.sl-multi-table > .max {
	position: relative;
	width: 19.68vw;
	height: 24vh;
	display: inline-block;
	vertical-align: top;

	& > .table {
		padding-top: 1vh;
	}
}

// SINGLE MOBILE LANDSCAPE

.sl-game.landscape > .header > .back-to-lobby,
.sl-game.landscape > .header > .back-to-lobby > img {
	height: 6vh;
}

.sl-game.sl-single-mobile.landscape > .header > .back-to-lobby {
	position: absolute;
	top: 2vh;
	left: 1vw;
}

// SINGLE MOBILE PORTRAIT

.sl-single-mobile > .min .fullsize .roadmap {
	width: 100%;
}

.sl-single-mobile.portrait > .min .fullsize .roadmap {
	background: white;
	position: absolute;
	margin: auto;
	width: 100%;
	height: 20vh;
	top: 0;
	left: 0;
	bottom: 0;
}

.sl-single-mobile.portrait > .max > .table {
	padding-top: 0;
}

.sl-game.portrait > .header > .back-to-lobby,
.sl-game.portrait > .header > .back-to-lobby > img {
	height: 4vh;
}
