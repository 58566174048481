/* SINGLE MOBILE PORTRAIT */
.sl-single-mobile.portrait .virtual-cards {
	position: fixed;
	z-index: 10;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%);
	top: 32vh;
	height: 32vh;

	.cards {
		top: 12vh;
	}

	.cards > div {
		margin: 0 0.75vw;
		width: 11vw;
		height: 8vh;
	}

	.cards > div.lie {
		margin: 0 3vw;
		border-radius: 4px;
		width: 11vw;
		height: 8vh;
	}

	.cards > div.lie.active {
		transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg) translate(-7.5vw, 4.125vh);
	}

	.cards > div.lie > img {
		margin-top: -0.4vh;
		margin-left: -0.21vw;
		height: 105%;
	}

	.cards > div > img.backside {
		border-radius: 0.5vh;
	}

	.left.cards {
		left: 4vw;
	}

	.right.cards {
		right: 4vw;
	}

	.left-letter,
	.right-letter {
		font-size: 2vh;
		width: 8vw;
		height: 4vh;
		line-height: 4vh;
		top: 7vh;
	}

	.left-letter {
		left: 25vw;
	}

	.right-letter {
		right: 25vw;
	}

	.left-letter.win {
		width: unset;
	}

	.right-letter.win {
		width: unset;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		margin-top: 0.75vh;
		width: 28vw;
		height: unset;
	}

	.tie-container {
		top: 1vh;
		left: 0;
	}

	.m27 .tie-container {
		top: 4.5vh;
	}

	.tie-container > img {
		width: 70vw;
	}

	.left-value,
	.right-value {
		top: 6vh;
		font-size: 4vh;
	}

	.left-value {
		left: 15vw;
	}

	.right-value {
		right: 15vw;
	}
}

// Dragon Tiger
.sl-single-mobile.portrait .virtual-cards.m23 {
	.cards > div {
		margin: 0 15vw;
	}

	.left-letter,
	.right-letter {
		top: 8vh;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		width: 40vw;
	}
}

// Shio Fights
.sl-single-mobile.portrait .virtual-cards.m27 {
	.left-letter {
		left: 5vw;
	}

	.right-letter {
		right: 5vw;
	}
	
	.left-letter.dragon,
	.right-letter.tiger {
		width: 10vw;
		border-top-left-radius: 4vh;
		border-top-right-radius: 4vh;
		border-bottom-right-radius: 4vh;
		border-bottom-left-radius: 4vh;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		width: 30vw;
	}

	.cards > div {
		width: 14vw;
		height: 10vh;
	}
}
