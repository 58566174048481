$color0: #6c66fb;
$color1: #47c9f7;
$color2: #21deab;
$color3: #8b8bb3;
$color4: #41527e;
$color5: #d44c49;
$color6: #151624;
$color7: #b5b5d2;
$dimen-top: 14vh;

@mixin middle {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba($color: #000000, $alpha: 0.8);
    text-align: center;
    color: #fff;
    line-height: 10vh;
    overflow: hidden;
}

.popup-news {
    @include middle;
    position: fixed;
    z-index: 25;
    background-color: #151624;
    width: 80vw;
    height: 80vh;

    .popup-header {
        position: fixed;
        z-index: 25;
        width: 80%;
        height: 3%;
        font-size: 2vh;
        font-weight: bold;
        background-color: $color0;
        /*border-radius: .5em .5em  0 0;*/
        text-align: center;

        span {
            position: fixed;
        }
    }

    .topic {
        z-index: 25;
        position: fixed;
        top: 15%;
        width: 12%;
        height: 75%;
        background-color: #222336;
        border-right: 1px solid #b5b5d2;

        & > div {
            display: block;
            text-align: left;
            border-bottom: 1px solid #b5b5d2;
        }

        & > div > div:first-child {
            padding: 2vh 0 2vh 1vw;
            border-bottom: 1px solid #b5b5d2;
            cursor: default;
        }

        & > div > div {
            padding: 2vh 0 2vh 3vw;
            border-bottom: 1px solid #b5b5d2;
            cursor: pointer;
        }

        .active {
            border-right: 5px solid #21deab;
            color: #21deab;
        }
    }

    .content {
        z-index: 25;
        width: 65%;
        height: 80%;
        margin-left: 18%;


        section {
            display: none;
            margin-top: 5vh;
            position: fixed;
            width: 63.5%;
            text-align: justify;
            overflow-y: auto;
            overflow-x: auto;
            padding-top: 5vh;
            padding-right: 1.9%;
            padding-bottom: 1vh;

            p {
                font-size: 11pt;
                line-height: 3vh;
            }

            p:after {
                content: "\A";
            }
        }

        .active {
            display: block;
        }
    }

    .popup-header .sl-ic-close {
        float: right;
        margin-top: 0.5vh;
        margin-right: 0.5vw;
        cursor: pointer;
    }
}
