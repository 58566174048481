// only for glow
@keyframes animate {
	0% {
		background-position: 0%;
	}

	100% {
		background-position: 400%;
	}
}

body.machine {
	.lobby-game-preview {
		// width: 24%;
		width: 100%;
		height: 26vh;
		// margin: 0.5vh 0.5%;
		margin: unset;

		.time-left,
		.time-left-toto {
			right: 0;
		}
	}

	.game-group-container-content {
		.lobby-game-preview {
			width: 20vw;

			.time-left,
			.time-left-toto {
				right: 1vw;
			}
		}
	}
}

.lobby-game-preview {
	position: relative;
	display: inline-block;
	vertical-align: top;
	background: #1d1e2f;

	border-radius: 8px;

	width: 20vw; /* 356px */
	height: 26vh; /* 256px */

	margin: 0.6vh 0.3vw;

	cursor: pointer;
	outline: 0;

	box-sizing: border-box;

	transition: 0.2s;
	border: 1px solid black;
	bottom: 0;
	box-shadow: none;

	&.active {
		border-color: #9592EB; // same as roadmap options
		bottom: 4px;
		box-shadow: 0 4px 8px black;
	}

	& > img,
	& > p > img {
		position: absolute;
	}

	& > img.bottom {
		right: 0;
		bottom: 0;
	}

	& > .game-3d {
		overflow: visible;
		top: 0;
		right: 0;
		width: 40%;
	}

	& > .animation {
		position: absolute;
		top: 0;
		right: 0;
		width: 8vw;
		height: 10vh;

		& > .game-3d {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;

			max-width: 100%;
			max-height: 100%;
		}
	}

	& > .special-multitable {
		overflow: visible;
		right: 0;
	}

	.how-to-play {
		position: absolute;
		top: 0.4vh;
		right: 0.21vw;
		cursor: pointer;
	}

	& > .preview {
		width: 100%;
		// top: 10vh;
		bottom: 5vh;
		height: 11vh;
	}

	& > .lobby-play-button-overlay {
		width: 100%;
		height: 62%;
		top: 38%;
		background: rgba(0, 0, 0, 0.5);
		position: absolute;
		border-radius: 0 0 8px 8px;

		opacity: 0;
		transition: 0.2s;

		&.active {
			opacity: 1;
		}

		& > img,
		& > div {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}

		& > img {
			width: 8.56vw;
			height: 7vh;
		}

		& > div {
			font-family: CircularStd-Black;
			font-size: 1.4vh;
			width: 160px;
			height: 68px;
			line-height: 68px;
			text-align: center;
		}
	}

	& > .fav-box {
		position: absolute;
		background: #27283a;
		text-align: center;
		height: 5vh;
		width: 3vw;
		border-bottom-left-radius: 1vh;
		bottom: 0;

		& > img {
			height: 60%;

			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}

	& > .desc {
		position: relative;
		height: 5vh;
		width: 17vw;
		display: flex;
		align-items: center;
		margin-left: 3vw;
		top: calc(100% - 5vh);

		display: flex;
		align-items: center;

		& > img {
			position: relative;
			left: 0.5vw;
			max-width: 1.6vw;
			max-height: 2.4vh;
		}
	}

	.game-name {
		color: white;
		position: relative;
		margin-left: 0.8vw;
		font-size: 1.9vh;
	}

	.game-new {
		position: absolute;

		color: white;

		top: 10vh;

		background-color: #ed554d;
		font-size: 1.4vh;
		border-radius: 0.8vh;
		padding: 0 0.5vw;
	}
}

.lobby-game-preview.glow {
	background-size: 400%;

	&:hover {
		background: linear-gradient(
			to right,
			rgba(0, 195, 216, 1) 0%,
			rgba(132, 127, 255, 1) 33%,
			rgba(33, 222, 171, 1) 67%,
			rgba(0, 195, 216, 1) 100%
		);
		animation: animate 8s linear infinite;
	}

	&:before {
		content: "";
		position: absolute;
		top: -20px;
		left: -20px;
		right: -20px;
		bottom: -20px;
		z-index: -1;
		background: linear-gradient(
			to right,
			rgba(0, 195, 216, 1) 0%,
			rgba(132, 127, 255, 1) 33%,
			rgba(33, 222, 171, 1) 67%,
			rgba(0, 195, 216, 1) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		background-size: 400%;
		border-radius: 40px;
		opacity: 0;
		transition: 0.5s;
	}

	&:hover:before {
		filter: blur(20px);
		opacity: 1;
		animation: animate 8s linear infinite;
	}
}

.lobby-game-preview.multibet-dealer {
	transition: 1s;

	img {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		width: 100%;
		transition: 1s;
	}

	&.empty-game-list {
		top: 0;
	}

	.box-background-dealer {
		overflow: hidden;
	}

	.image-dealer {
		width: 15vw;
	}

	.desc {
		left: 1vw;

		background: #1d1e2f;
	}

	.sl-hover-hidden {
		top: -20%;
		left: 0;
		height: 120%;
	}

	.box-dealer {
		position: absolute;
		top: -2vw;
		left: 2vw;
	}

	&:hover .image-dealer {
		-webkit-transform: scale(0.75);
		transform: scale(0.8) translateY(2vw);
	}

	&:hover .background-dealer {
		transform: scale(1.25);
	}

	& > .desc {
		position: absolute;
		bottom: 0;
		height: 5vh;
		width: 17vw;
		margin-left: 0;
	}
}

.lobby-game-preview .time-left {
	position: absolute;
	height: 6vh;
	width: 6vh;
	border-radius: 50%;
	border: 1px solid #6c66fb;
	color: #21deab;
	background: #10111c;
	right: 1vw;
	bottom: 1vw;

	& > img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 80%;
	}

	& > div {
		position: absolute;
		width: 100%;
		text-align: center;
		line-height: normal;
		margin: auto;
		top: 0;
		bottom: 0;

		&.bet-close {
			font-size: 1.25vh;
			height: 4vh;
		}

		&:not(.bet-close) {
			font-size: 2.8vh;
			line-height: 6vh;
			margin: 0;
		}
	}
}

// Time v5
.lobby-game-preview .time-left-toto {
	position: absolute;
	right: 1vw;
	bottom: 1.25vw;

	width: 5.3vw;
	height: 4.5vh;
	justify-self: right;

	border-radius: 12px;
	border: 1px solid #6c66fb;
	color: #21deab;
	background: #10111c;

	display: flex;
	justify-content: center;
	align-items: center;

	div:not(.bet-close) {
		font-size: 2.2vh;

		line-height: 1;
	}

	div.bet-close {
		font-size: 1.5vh;
		color: #bf2824;
		text-align: center;
	}
}
/*
 circle outline: #6c66fb
 background: #10111c
 */

.guidelines .lobby-history-container {
	box-sizing: border-box;
	border: 1px solid cyan;
}

.guidelines .lobby-game-preview {
	border: 1px solid lime;
	border-radius: 0;

	& > .animation {
		box-sizing: border-box;
		border: 1px solid white;
	}

	& > .desc {
		box-sizing: border-box;
		border: 1px solid orange;
	}

	& > .fav-box {
		box-sizing: border-box;
		border: 1px solid purple;
	}
}
