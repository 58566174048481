.slidecontainer {
	position: relative;
    width: 100%;
	height: 24px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #aaa;
    outline: none;
	position: absolute;
}

.slider:hover {
    opacity: 1;
}

.slider-left {
	height: 5px;
    background: #8898c2;
    border-radius: 5px;
    position: absolute;
    top: 2px;
	pointer-events: none;
} 

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #8898c2;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #8898c2;
    cursor: pointer;
}

.popup-v2 .slider {
	height: 3px;
	background: #40465E;
}

.popup-v2 .slider-left {
	height: 3px;
	background: #46C9F5;
}

.popup-v2 .slider::-webkit-slider-thumb {
	background: #46C9F5;
}

.popup-v2 .slider::-moz-range-thumb {
	background: #46C9F5;
}
