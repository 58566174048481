.left-side-button-container {
	position: absolute;
	left: 0;
	top: 0;
	
	display: flex;
	flex-direction: column;
}

.left-side-button-container > button {
	position: relative;
	font-family: Lato-Regular;
	border: 0.1vh solid #645fe5;
	border-radius: 1vh;
	background: #2c2c38;
	width: 2.82vw;
	height: 5.3vh;
	padding: 0;
	margin: 0.5vh 0;
	cursor: pointer;
	outline: none;
}

.left-side-button-container > button > img {
	width: 1.5vw;
}

.left-side-button-container .tooltip {
	position: absolute;
	color: #8b8bb3;
	background: #2c2c38;
	
	left: 125%;
	top: 0;
	bottom: 0;
	margin: auto;
	
	width: 8vw;
	height: 3vh;
	line-height: 3vh;
	
	opacity: 0;
	transition: 0.5s;
}

.left-side-button-container .tooltip:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	
	height: 0;
	margin-left: -0.5vw;
	border-width: 0.27vw;
	border-style: solid;
	border-color: transparent #2c2c38 transparent transparent;
}

.left-side-button-container > button:hover > .tooltip {
	opacity: 1;
}

.left-side-button-container .table-information {
	box-sizing: border-box;
	background: #2c2c38;
	border-radius: 1vh;
	border: 1px solid #645fe5;
	
	position: absolute;
	left: 125%;
	
	width: 15vw;
}

.left-side-button-container .table-information > div {
	height: 3vh;
	line-height: 3vh;
	padding: 1vh 1vw;
}

.left-side-button-container .table-information > div > div {
	display: inline-block;
	width: 50%;
}

.left-side-button-container .table-information .desc-heading.nocontent {
	text-align: center;
}

.left-side-button-container .table-information .desc-heading {
	color: white;
	text-align: left;
}

.left-side-button-container .table-information .desc-content {
	color: #22deae;
	text-align: right;
}