@import "./desktop_single", "./multi_table_landscape", "./portrait";

.m10-row {
	display: flex;
	align-items: stretch;
	justify-content: center;
}

// .sl-bet {
// 	font-family: Lato-Light;
// 	display: table-cell;
// 	vertical-align: middle;
// 	border-right: 0.05vw solid #083d23;
// }

.sl-table.m10 {
	box-sizing: border-box;
	// border: 1px solid red;

	.sl-bet {
		font-family: Lato-Light;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #083d23;
	}

	.m10-top-row {
		height: 35%;

		& > div:not(.m10-corners) {
			flex-grow: 1;
		}

		& > .inner-top {
			display: flex;
			flex-direction: column;

			& > .m10-top-text {
				position: relative;
				color: white;

				& > div:first-child {
					position: absolute;
					width: 100%;
					bottom: 0;
				}
			}

			& > div:first-child {
				height: 35%;
			}

			& > div:last-child {
				display: flex;
				flex-grow: 1;
				width: 100%;

				& > div {
					width: 50%;
				}
			}
		}
	}

	.m10-middle-row {
		height: 45%;

		.big-coin {
			flex-direction: column;
		}

		.inner-middle {
			flex-grow: 1;

			.sl-bet {
				width: 25%;
			}

			& > div {
				height: 50%;
			}
		}
	}

	.m10-bottom-row > .sl-bet {
		width: 50%;
	}
}

// .m10-row > .sl-bet:last-child {
// 	border-right: none;
// }

/* COLOURS */
.m10-green-darken {
	background-color: #094628;
}

.m10-grey {
	background-color: #1f2022;
}

.m10-grey-darken {
	background-color: #222222;
}

.m10-blue {
	background-color: #14365a;
}

.m10-blue-darken {
	background-color: #12273d;
}

.m10-red {
	background-color: #b83432;
}

.m10-red-darken {
	background-color: #4a1e1e;
}
