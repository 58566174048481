@mixin commonThirds {
    width: percentage(1 / 3);
    display: inline-block;
    vertical-align: top;
}

@mixin singleTableHeight($seventh) {
    height: 200px * $seventh / 7;
    line-height: 200px * $seventh / 7;
}

@mixin singleLessTableHeight($fifth) {
	height: 200px * $fifth / 5;
    line-height: 200px * $fifth / 5;
}

@mixin multiTableHeight($seventh) {
    height: 19vh * $seventh / 7;
    line-height: 19vh * $seventh / 7;
}

@mixin landscapeTableHeight($seventh) {
    height: 190px * $seventh / 7;
    line-height: 190px * $seventh / 7;
}

@mixin bet-break-word {
	.bet-label {
		word-wrap: break-word;
		width: 100%;
	}
}

.sl-table.m39 {
    color: white;

    // Generic declaration
    .red { background: #b93332; }
    .green { background: #32972f; }
    .blue {
		background: #2155a8;

		&:hover {
			background: cornflowerblue !important;
		}
	}
	
    .orange { background: #D88326; }
    .purple { background: #7242C9; }
    .grey { background: #929292; }
    .yellow { background: #CAAA33; }

    .sl-bet {
        box-sizing: border-box;
    }

    .top {
        & > .left, & > .middle, & > .right {
            display: inline-block;
            vertical-align: top;

            & > div {
                display: inline-block;
                vertical-align: top;
            }
        }

        & > .left, & > .right {
            width: percentage(4 / 11);

            & > div {
                width: 50%;
            }
        }

        & > .middle {
            width: percentage(3 / 11);

            & > div {
                @include commonThirds;
            }
        }
    }

    & > .middle {
        & > div {
            display: inline-block;
            vertical-align: top;
        }

        & > .left, & > .right {
            width: percentage(3 / 11);

            & > div {
                @include commonThirds;
            }
        }

        & > .middle {
            width: percentage(5 / 11);

            & > div {
                display: inline-block;
            }

            .red, .blue {
                width: 40%;
            }

            .green {
                width: 20%;
            }
        }
    }

    .number-row > div {
        width: percentage(1/11);
        display: inline-block;
    }

    .bottom {
        & > div {
            display: inline-block;
            vertical-align: top;
        }

        & > .left, & > .right {
            width: percentage(4/11);

            & > div {
                width: 25%;
                display: inline-block;
            }
        }

        & > .middle {
            width: percentage(3/11);

            & > div {
                @include commonThirds;
            }
        }
    }
}

.sl-single-game .sl-table.m39 {
    .sl-bet {
        border: 1px solid #093B22;
    }

    .bet-label {
        font-size: 14px;
    }

    // & > .middle > .middle,
    // & > .bottom > .left,
    // & > .bottom > .right {
    //     .bet-label {
    //         font-size: 20px;
    //     }
    // }
    & > .middle > .middle {
        .bet-label {
            font-size: 20px;
        }
    }

    & > .top {
        & > .left, & > .middle, & > .right {
            & > div {
                @include singleTableHeight(1);
            }
        }
    }

    & > .middle {
        @include singleTableHeight(2);

        & > .left, & > .right {
            @include singleTableHeight(1);
        }
    }

    & > .number-row > div {
        @include singleTableHeight(1);
    }

    & > .bottom {
        & > .left, & > .right {
            & > div {
                @include singleTableHeight(2);
            }
        }

        & > .middle {
            & > div {
                @include singleTableHeight(1);
            }
        }
    }
}

.sl-single-game .sl-table.m39.less-table {
    .bet-label {
        font-size: 16px;
    }

    & > .middle > .middle,
    & > .bottom > .left,
    & > .bottom > .right {
        .bet-label {
            font-size: 24px;
        }
    }

	& > .top {
        & > .left, & > .middle, & > .right {
            & > div {
                @include singleLessTableHeight(1);
            }
        }
    }

    & > .middle {
        @include singleLessTableHeight(2);

        & > .left, & > .right {
            @include singleLessTableHeight(1);
        }
    }

    & > .number-row > div {
        @include singleLessTableHeight(1);
    }
}

.sl-multi-table .sl-table.m39 {
    .sl-bet {
        border: 1px solid #093B22;
    }

    & > .top {
        & > .left, & > .middle, & > .right {
            & > div {
                @include multiTableHeight(1);
            }
        }
    }

    & > .middle {
        @include multiTableHeight(2);

        & > .left, & > .right {
            @include multiTableHeight(1);
        }
    }

    & > .number-row > div {
        @include multiTableHeight(1);
    }

    & > .bottom {
		@include bet-break-word;

        & > .left, & > .right {
            & > div {
				font-size: 7.5px;
                @include multiTableHeight(2);
            }
        }

        & > .middle {
            & > div {
                @include multiTableHeight(1);
            }
        }
    }
}


.landscape .sl-table.m39 {
    .sl-bet {
        border: 1px solid #093B22;
    }

    & > .top {
        & > .left, & > .middle, & > .right {
            & > div {
                @include landscapeTableHeight(1);
            }
        }
    }

    & > .middle {
        @include landscapeTableHeight(2);

        & > .left, & > .right {
            @include landscapeTableHeight(1);
        }
    }

    & > .number-row > div {
        @include landscapeTableHeight(1);
    }

    & > .bottom {
		@include bet-break-word;

        & > .left, & > .right {
            & > div {
                font-size: 40%;
                @include landscapeTableHeight(2);
            }
        }

        & > .middle {
            & > div {
                @include landscapeTableHeight(1);
            }
        }
    }
}

// Portrait
.portrait .sl-table.m39 {
    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .container-one, .container-two {
        & > div {
            flex: 1
        }
    }

    .container-four {
        & > div {
            font-size: 90%;
        }
    }
}
