.roadmap-options {
	position: relative;
	color: #B0AAFF;
	text-align: center;
	display: inline-block;

	& > div {
		// &:not(:nth-last-child(2)) {
		border-bottom: 1px solid #9592EB;
		// }

		&.active {
			color: white;
		}
	}
}

.sl-multi-table > .min > .roadmap-options {
	position: absolute;
	left: 1.8vw;
	top: 15vh;
	background: rgba(0, 0, 0, 0.5);
	color: #b0aaff;
	font-size: 1vh;
	text-align: center;
	cursor: pointer;

	& > div {
		width: 0.85vw;
		border-bottom: 1px solid #9592eb;
		line-height: 2.6vh;

		&:last-child {
			border-bottom: none;
		}
	}

	.active {
		color: white;
	}

	&.tip span {
		left: -9.84vw;
		top: 0;
		width: 9vw;

		&.active {
			opacity: 1;
			z-index: 2;
			color: #8b8bb3;
			font-size: 1.1vh;
			background: #3e4058;
		}
	}
}

.lobby-history-container > .roadmap-options {
	font-size: 2vh;
	width: 1.05vw;
	height: 9.7vh;

	& > div > div {
		line-height: 3vh;
	}
}

.mobile-home .game-box {
	.roadmap-options {
		display: inline-block;
		width: 4.8vw;
		vertical-align: top;
	}

	.roadmap-options div {
		/* background: #6C66FC; */
		background: #1d1e2f;
		color: #9291ee;
		box-sizing: border-box;
		line-height: 4vh;
		text-align: center;
		border-bottom: 1px solid #6c66fc;
	}

	.roadmap-options .active div {
		color: white;
	}
}

.mobile-home.portrait .game-box {
	.roadmap-options div {
		line-height: 3.5vh;
	}
}

.mobile-home.landscape .game-box {
	.roadmap-options {
		width: 2.8vw;

		div {
			height: 7.5vh;
			line-height: 7.5vh;
		}
	}
}