.mobile-home.landscape .game-game {
	height: 12.8vh;

	.icon-game {
		padding-left: 5%;
		vertical-align: middle;
		line-height: 6.5vw;
	}

	p {
		font-size: 3.8vh;
		padding-left: 2%;
		vertical-align: middle;
		line-height: 6vw;
	}

	.game-game-right > .fav-box {
		width: 6vw;
		min-width: 6vw;

		& > img {
			height: 6vh;
		}
	}
}

.mobile-home.landscape .full-width .game-game {
	height: 20vh;

	& > .game-game-right {
		height: 20vh;

		& > .game-statistics.mobile-home {
			position: absolute;
			bottom: 0;

			background: transparent;
			border: 0;
		}

		& > p,
		& > .icon-game {
			margin-bottom: 7vh;
		}
	}
}
