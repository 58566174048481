/* SINGLE MOBILE LANDSCAPE */
/* All mobile result elements (for landscape) top is added by 12.4vh */

.sl-single-mobile-v2.landscape .virtual-cards {
	position: fixed;

	&.overlay {
		top: 10vh;
		width: 100%;
		height: 100%;
	}
	
	.cards {
		top: 26.8vh;
	}

	.cards > div {
		margin: 0 0.75vw;
		width: 5.5vw;
		height: 14vh;
	}

	.cards > div.lie {
		box-sizing: border-box;
		margin: 0 1.2vw;
		border-radius: 4px;
		width: 5.5vw;
		height: 14vh;
	}

	.cards > div.lie.active {
		transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg) translate(-3.5vw, 7.5vh);
	}

	.cards > div.lie > img {
		margin-top: -0.4vh;
		margin-left: -0.21vw;
		height: 14vh;
	}

	.cards > div > img.backside {
		border-radius: 0.5vh;
	}

	.left.cards {
		left: 20vw;
	}

	.right.cards {
		right: 20vw;
	}

	.left-letter,
	.right-letter {
		font-size: 4.2vh;
		width: 3.2vw;
		height: 6vh;
		/* top: 7.6vh; */
		top: 20vh;
	}

	.left-letter.win,
	.right-letter.win {
		padding-left: 0.4vw;
		padding-right: 0.4vw;
		width: unset;
	}

	.tie-container {
		top: 44vh;
		left: 0;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		margin-top: 1.2vh;
		width: 14.63vw;
		height: 4.8vh;
	}

	.tie-container > img {
		width: 36.17vw;
		height: 6vh;
	}

	.left-value,
	.right-value {
		top: 18.8vh;
		font-size: 5vh;
	}

	.left-letter {
		left: 25vw;
	}

	.left-value {
		left: 20vw;
	}

	.left-value.win {
		left: 20.5vw;
		font-size: 6.4vh;
	}

	.right-letter {
		right: 25vw;
	}

	.right-value {
		right: 20.5vw;
	}

	.right-value.win {
		right: 21vw;
		font-size: 6.4vh;
	}
}

.sl-single-mobile-v2.landscape .virtual-cards.m23 {
	.cards > div {
		margin: 0 8vw;
	}
	
	.left-letter,
	.right-letter {
		top: 18vh;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		width: 20vw;
		height: auto;
	}

	.cards > div {
		width: 7.5vw;
		height: 20vh;
	}

	.left-value,
	.right-value {
		font-size: 12vh;
	}
}

/* Shio Fights */
.sl-single-mobile-v2.landscape .virtual-cards.m27 {
	.left-letter,
	.right-letter {
		top: 16vh;
	}

	.left-letter:not(.win),
	.right-letter:not(.win) {
		width: 6vw;
		height: 8vh;
		line-height: 8vh;
		font-size: 6vh;
	}

	.left-letter {
		left: 20vw;
	}

	.right-letter {
		right: 20vw;
	}
	.left-letter.dragon,
	.right-letter.tiger {
		width: 12vw;
		border-top-left-radius: 4vh;
		border-top-right-radius: 4vh;
		border-bottom-right-radius: 4vh;
		border-bottom-left-radius: 4vh;
	}

	.left-letter.win > img,
	.right-letter.win > img {
		width: 25vw;
		height: auto;
	}
}
