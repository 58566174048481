.sl-single-mobile.landscape {
	.chips-container {
		background: unset;
		border: unset;

		& > .chips-container-inside {
			width: 100%;
			height: 100%;
			overflow: visible;
			text-align: center;
			white-space: initial;

			.chip {
				height: unset;
				margin-top: 1vh;
				padding: 0 0.2vw;

				& > p {
					font-size: 3vh;
					line-height: 9vh;
				}

				& > img {
					height: 10vh;
				}
			}

			.chip.active {
				margin-top: 0;

				& > p {
					font-size: 4vh;
					line-height: 11vh;
				}

				& > img {
					height: 12vh;
				}
			}
		}
	}

	& > .max {
		& > .chips-container {
			position: fixed;
			bottom: 0;
			right: 0;
			width: 60vw;

			// border: 1px solid cyan;

			& > .chip {
				& > img {
					height: 10vh;
				}

				& > p {
					line-height: 11vh;
					font-size: 2.5vh;
				}

				&.active > p {
					line-height: 14vh;
					font-size: 3.5vh;
				}

				&.active > img {
					height: 13vh;
				}
			}
		}

		& > .chips-container.pane-hidden {
			bottom: -1vh;
			width: 10vw;
			height: 91vh;

			overflow-y: scroll;

			.chips-container-inside {
				height: 100%;
			}

			.chip {
				height: 10vh;
			}
		}
	}

	.change-chip-right div {
		width: 6vw;
		height: 6vh;
	}

	& > .max.hugestreaming .chips-container {
		visibility: hidden;
		z-index: -1;
	}
}
