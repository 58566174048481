.sl-container.v5 {
    padding-bottom: 0;
}
.sl-game.v5.sl-single-game-v2 {
    position: relative;
	left: 0;
	top: 0;
	width: 100vw;
	height: unset;
    margin: 0;

    .container-gamev5 {
        height: 88vh;
        position: relative;
    }
    .content-gamev5 {
        width: 98%;
        height: 100%;
        border: 0;
    }

    .loading-iframe-v5 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.6);

        .toto-loading-ring {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .toto-loading-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #fff;
            border-radius: 50%;
            animation: toto-loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
        }
        .toto-loading-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .toto-loading-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .toto-loading-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

.sl-game.v5.sl-multi-table {
    .content-gamev5 {
        margin-top: 1vh;
        width: 98%;
        height: 22vh;
        border: 0;
    }
}

@keyframes toto-loading-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}