// Done: collect .game-game and .game-box
@import "./game_game", "./game_box_portrait", "./game_box_landscape";

.mobile-home .game-box {
	height: 17vh;
	box-sizing: border-box;
	background: #2c2d40;
	border-radius: 2vh;
	border: 1px solid #6b66ff;
	position: relative;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;

	& > .new-game-badge {
		width: 25%;
		position: absolute;
		top: 0;
		right: 0;
	}

	.time-left {
		box-sizing: border-box;
		height: 5vh;
		width: 5vh;
		border-radius: 50%;
		border: 1px solid #6c66fb;

		color: #21deab;
		background: #10111c;

		display: flex;
		justify-content: center;
		align-items: center;

		margin: 0 0.5vw;

		.betclose {
			font-size: 60%;
			margin-top: 5px;
			line-height: normal;
		}

		& > img {
			width: 80%;
		}
	} // .time-left

	.roadmap {
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}

	.history-container {
		display: inline-block;
		width: 82%;
		height: 100%;
	}

	.lobby-roadmap-table {
		tbody > tr {
			display: block;
		}

		tbody > tr,
		tbody td {
			height: 2.7vh;
		}

		tbody td {
			width: 4.2vw;
			display: inline-block;
			vertical-align: top;
		}
	}

	.left-button {
		display: inline-block;
		width: 8vw;
		vertical-align: top;
		border-right: 1px solid #847fff;

		div {
			height: 6vh;
			background: #363650;
			position: relative;

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}

		.active {
			background: #6c66fc;
		}
	}

	.history-m14.history-container {
		text-align: center;
		width: 100%;
	}

	&.full-history .history-container {
		width: 100%;
	}
}
