.samgong-virtual-balls { 
    position: absolute;

    & > div {
        position: relative;
        display: inline-block;
        background: rgba(0,0,0,0.5);
        color: white;
    }

    .value {
        position: absolute;
    }

    //.player {
    //    color: #2155a8;
    //}

    //.banker {
    //    color: #b83432;
    //}

    transition: 0.4s;
    opacity: 1;
}

.samgong-virtual-balls:active,
.samgong-virtual-balls:hover {
    opacity: 0;
}

.sl-multi-table .samgong-virtual-balls,
.sl-single-game .samgong-virtual-balls {
    bottom: 43px;
    left: 0;
    right: 0;
    margin: auto;

    font-size: 13px;

    & > div {
        width: 5%;
        margin: 0 14%;
    }

    .value {
        font-size: 40px;
        top: 80px;
    }

    .player .value {
        left: 80px;
    }

    .banker .value {
        right: 80px;
    }

    img {
        width: 40px;
    }
}

.sl-multi-table .samgong-virtual-balls {
    transform: scale(0.9) translate(6.3vw, 1.5vh);

    & > div {
        width: 10%;
        margin: 0 13.2%;
    }
}

.portrait .samgong-virtual-balls {
    position: fixed;

    top: 43vh;
    width: 100%;

    & > div {
        width: 25%;
    }

    .player {
        position: absolute;
        left: 0;
    }

    .banker {
        position: absolute;
        right: 0;
    }

    .value {
        font-size: 5vh;
    }

    .player .value {
        top: -1vh;
        left: 37vw;
    }

    .banker .value {
        top: -1vh;
        right: 37vw;
    }

    img {
        width: 16vw;
    }
}

.landscape .samgong-virtual-balls.bet-closed {
    position: fixed;
    top: 14vh;
    left: 40vw;
    width: 60vw;

    text-align: center;

    font-size: 4vh;

    & > div {
        width: 11%;
        margin: 0 19.5%;
    }

    .player {
        position: absolute;
        left: 0;
    }

    .banker {
        position: absolute;
        right: 0;
    }

    .value {
        font-size: 15vh;
    }

    .player .value {
        top: 21vh;
        left: 8vw;
    }

    .banker .value {
        top: 21vh;
        right: 8vw;
    }

    img {
        width: 6vw;
        height: 12vh;
    }
}