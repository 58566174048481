$bet-open-colour: #00ff1e;
$bet-close-colour: #d52b2b;

$widescreen-ratio: 1.778;

@mixin centre {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.right-side-button-container {
	position: absolute;
	top: 0;
	right: 0;

	.group {
		padding: 1vh 0.5vw;
		box-sizing: border-box;

		background-image: linear-gradient(to bottom, rgba(54, 68, 106, 1) 0%, rgba(23, 28, 48, 1) 100%);
		border: 1px solid #7770ff;
		border-radius: 1vh;

		width: 10vw;

		display: flex;
    	flex-wrap: wrap;
    	justify-content: center;

		&:not(:first-child) {
			margin: 1vh 0;
		}

		.timer {
			line-height: initial;
			display: inline-block;
			vertical-align: middle;
			font-size: 8vh;

			&.bet-closed {
				font-size: 2.5vh;
			}

			&.bet-closed,
			&.bet-closing {
				color: $bet-close-colour;
			}

			&.bet-open {
				color: $bet-open-colour;
			}
		}

		& > .desc-heading {
			color: white;
		}

		& > .desc-heading:not(:first-child) {
			margin-top: 1vh;
		}

		& > .timer-box.patterned {
			padding: 0;
		}

		& > .patterned {
			border-radius: 0.5vh;

			box-sizing: border-box;

			padding: 0.5vh 0;

			// background-image: url("../../assets/img/v2icons/pattern.png");
			background-image: url("pattern.png");

			border-top: 1px solid #444e6b;
			border-left: 1px solid #313c5b;
			border-right: 1px solid #27314d;
			border-bottom: 1px solid #1e263c;

			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.35);

			color: #2bbfa0;
		}


		.timer-circle {
			margin-top: 10px;

			width: 8vw;
			// height: 8vh * $widescreen-ratio;
			height: 8vw;

			// width: 10rem;
			// height: 10rem;

			border-radius: 50%;

			position: relative;

			box-sizing: border-box;

			// display: flex;
			// justify-content: center;
			// align-content: center;

			&.bet-open {
				background-color: rgb(0,41,2);
				// background-image: linear-gradient(to bottom,  rgba(0,41,2,1), rgba(0,174,8,1));
				background-image: radial-gradient(ellipse at bottom, rgba(0,174,8,1) 0%, rgba(0,41,2,1) 75%);

				border: 4px solid $bet-open-colour;
				color: $bet-open-colour;
			}

			&.bet-closed,
			&.bet-closing {
				background-color: rgb(41,0,14);
				// background-image: linear-gradient(to bottom, rgba(41,0,14,1),rgba(174,0,61,1));

				// background-color: rgb(174,0,61);
				// @include filter-gradient(#ae003d, #29000e, horizontal);
				background-image: radial-gradient(ellipse at bottom, rgba(174,0,61,1) 0%, rgba(41,0,14,1) 75%);

				

				border: 4px solid $bet-close-colour;
				color: $bet-close-colour;
			}

			// & > .timer-arc {
			// 	@include centre;

			// 	content: "";

			// 	overflow: hidden;

			// 	width: 5vw;
			// 	height: 5vw;

			// 	border-radius: 50%;
			// 	border: 4px solid transparent;
			// 	border-right: 4px solid $bet-open-colour;
			// 	border-bottom: 4px solid $bet-open-colour;

			// 	transform: rotate(-45deg);
			// }

			// & > .timer-mask {
			// 	@include centre;
			// 	right: 2.5vw;
				
			// 	width: 2.5vw;
			// 	height: 5vw;

			// 	opacity: 1;
			// }

			& > #timerArc {
				@include centre;

				width: 7vw;
				// height: 7vh * $widescreen-ratio;
				height: 7vw;

				// width: 8.5rem;
				// height: 8.5rem;

				transform: rotate(-90deg);
			}

			& > .timer-number {
				@include centre;

				font-family: Arial;
				font-size: 2.5vw;
				// height: 5vh;

				// font-size: 4rem;
				height: fit-content;
			}

			&.bet-closed > .timer-number {
				font-size: 1.3vw;

				// font-size: 1.75rem;
			}
		}

		&:not(.circle-timer) > div {
			width: 100%;
		}

	}
}
