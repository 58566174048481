.sl-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: transparent;
	color: white;
	text-align: center;
	margin: 0px auto;
	z-index: 11;

	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-left {
	border-bottom: 1px solid #4e4aae;
	border-left: 1px solid #4e4aae;
	border-top: 1px solid #524eb7;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	margin-right: -1vw;
	padding-right: 1vw;

	min-width: 200px;

	& > button {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;

		margin: 0.5vh 0.5vw;
		background: transparent;
		border: none;
	}

	& > .footer-separator {
		display: inline-block;
		vertical-align: middle;
		height: 4vh;
		border-right: 2px solid #4847a6;
	}
}

.footer-right {
	border-bottom: 1px solid #4e4aae;
	border-right: 1px solid #4e4aae;
	border-top: 1px solid #524eb7;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;

	min-width: 250px;

	margin-left: -1vw;
	padding-left: 1vw;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.footer-left,
.footer-right {
	box-sizing: border-box;
	background-color: #11111d;
	text-align: center;
	height: 5.6vh;
	width: 23vw;
}


.value-footer {
	color:#22e4af;
}

.label-footer {	
	color:#8685ad;
	font-size: 1.2vh;
	text-align: left;
}

.caption-footer {
    margin: 5px 2vh 5px 1vh;
	display: inline-block;
}

.caption-footer .value-footer {
	font-size: 1.6vh;

	&.smaller {
		font-size: 1.3vh;
	}
}

.box-footer,
.icon-footer {
	height: 100%;
	display: flex;
    align-items: center;
}

.not-enough-balance {
	animation: red-fade-out 0.5s linear;
}

@keyframes red-fade-out {
	from {
		background: red;
	}
	
	to {
		background: transparent;
	}
}
