@import "./from_bootstrap.scss", "./mobile.scss", "./modals.scss", "./scrollbars.scss", "./customIOS.scss",
	"./styles/fonts.scss", "./styles/chips_container/index.scss", "./styles/desktop_header.scss",
	"./styles/desktop_footer.scss", "./styles/error_page.scss", "./styles/flipping_card.scss",
	"./styles/generic_slider.scss", "./styles/games.scss", "./styles/game_statistics.scss", "./styles/how_to_play.scss",
	"./styles/lotto_virtual_balls.scss", "./styles/maintenance_page.scss", "./styles/popup_result_history.scss",
	"./styles/settings.scss", "./styles/toggle_switch.scss", "./styles/tooltip.scss", "./styles/popup_info_news.scss",
	"./styles/popup_result_history_game_list.scss", "./styles/roadmaps.scss", "./styles/roadmap_options.scss",
	"./styles/roadmap_enlarge_button.scss", "./styles/roadmap_card_tooltip.scss", "./styles/bet_type.scss",
	// Todo: move this one to games
	"./styles/samgong_virtual_balls.scss",
	"./styles/niuniu_virtual_cards.scss", "./styles/streaming_quality_button.scss", "./styles/swipe_up_demo.scss",
	"./styles/video_off_notice.scss", "./styles/video_tutorial.scss", "./styles/virtual_cards.scss",
	"./styles/desktop/lobby_game_preview", "./styles/maximize_game/v1", "./styles/maximize_game/v2",
	"./styles/maximize_game/v4", "./styles/maximize_game/toto", "./styles/maximize_game/machine_game/index.scss",
	// only for Desktop V2 & V4 games
	"./styles/left_side_buttons.scss",
	"./styles/right_side_buttons/index.scss";

/*ROOT*/
body {
	user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none;

	margin: 0;
}

p {
	margin: 0;
	padding: 0;
}

button {
	outline: none;
}

.sl-wrapper {
	text-align: center;
	position: relative;
}

/* GENERIC FULLSCREEN MESSAGE */
.fullscreen-message {
	color: white;
	z-index: 100;
	opacity: 0;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	position: fixed;

	&.dev {
		opacity: 1;
	}

	&.important {
		opacity: 1;
		background: rgba(0, 0, 0, 0.7);
	}
}

.fullscreen-message img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;

	width: 10vw;
}

.fullscreen-message div {
	position: relative;

	top: 30vh;
	bottom: 0;
	left: 0;
	right: 0;

	text-align: center;
	margin: auto;
}

.fullscreen-message.landscape img {
	width: 6vw;
}

.fullscreen-message.landscape div {
	top: 30vh;
}

.sl-game > .max > .controller > button.sl-red-sh[disabled],
.sl-game > .max > .controller > button.sl-blue-sh[disabled],
.sl-game > .max > .controller > button.sl-yellow-sh[disabled],
.sl-game > .max > .controller > button.sl-green-sh[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}

/* HOW TO PLAY */
.iframe-container {
	position: fixed;
	color: white;
	width: 1024px;
	height: 600px;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-left: 0;
	margin: auto;
	z-index: 15;

	// background-color: #2f2f4b;
	background: black;

	&.transaction-history {
		overflow-y: scroll;
	}
}

.overlay-popup {
	overflow-x: hidden;
	overflow-y: scroll;
}

.overlay-popup.large {
	width: 1024px;
	height: 600px;
}

.overlay iframe {
	border: none;
}

// Todo: remove unnecessary how-to-play classes
/* How to play contents */
.how-to-play div.content {
	width: calc(100vw - 250px);
	display: table-cell;
	vertical-align: top;
	padding: 20px;
}

.how-to-play div.content img {
	max-width: 100%;
}

.how-to-play div.content img.game-logo {
	width: 50px;
	height: auto;
	display: inline-block;
	margin-right: 5px;
	vertical-align: middle;
}

.how-to-play div.content p {
	font-family: "arial";
	font-size: 16px;
	color: white;
	margin: 0;
	line-height: 23px;
}

.how-to-play div.content p.game-title {
	color: white;
	font-family: "Muli";
	font-size: 26px;
	margin: 0;
	display: inline-block;
	vertical-align: middle;
}

.how-to-play div.content h1 {
	color: white;
	font-family: "Muli";
	margin: 0;
	display: inline-block;
	vertical-align: middle;
	font-weight: normal;
}

.how-to-play div.content hr {
	border: 1px solid #272c3e;
}

.info-content .how-to-play div.content p.game-title,
.info-content .how-to-play div.content p {
	color: black;
}

.how-to-play div.p12 div.content > div > img {
	width: 25px;
}

.how-to-play .p12 table img {
	width: 100% !important;
	height: 100% !important;
}

.how-to-play div.content p.gold {
	color: #ffb400;
}

.how-to-play div.content p.violet {
	color: #e008c4;
}

.how-to-play div.content p.header {
	font-size: 25px;
}

.how-to-play div.content p.bold {
	font-weight: bold;
}

.how-to-play div.content table {
	width: 100%;
}

.how-to-play div.content td:first-child {
	width: 200px;
}

.how-to-play div.content td:last-child {
	width: auto;
}

.how-to-play div.content td img {
	width: 25px;
	height: 24px;
	vertical-align: middle;
	display: inline-block;
	margin-bottom: 8px;
	margin-right: 3px;
}

.how-to-play-close-btn {
	z-index: 9;
}

.how-to-play-close-btn.popup-large {
	right: -4%;
	top: -2%;
	position: absolute;
}

.how-to-play-close-btn.popup-large i {
	font-size: 3vh;
}

// GENERIC OVERLAY
.overlay {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 14;
}

.overlay.transparent {
	background: transparent;
}

.game-group-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 15;

	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

	// width: 62vw;
	// height: 27vh;

	// border: 1px solid #5a5b81;
	// box-shadow: 0px 0px 30px 0px rgba(90, 91, 129, 0.75);

	// background: #26273c;

	// border-radius: 2vh;
	// padding: 4vh 2vw;

	.game-group-container-content {
		position: relative;
		border: 1px solid #5a5b81;
		box-shadow: 0px 0px 30px 0px rgba(90, 91, 129, 0.75);

		background: #26273c;

		border-radius: 2vh;
		padding: 4vh 2vw;

		display: grid;
		grid-template-columns: repeat(3, 20vw);
		gap: 1vw;

		& > .lobby-game-preview {
			margin: 0;
			width: 20vw;
		}
	}

	.game-group-close-button {
		position: absolute;
		top: -2vh;
		right: -0.5vw;
		width: 3vw;
		height: 6vh;

		cursor: pointer;
	}
}

/* LOBBY HEADER ICONS */
.sl-icon-header {
	height: 3.5vh;
	display: inline-block;
	margin-right: 8px;
}

/* GAMES ICONS */
.sl-icon-footer {
	width: 2vw;
	display: inline-block;
	cursor: pointer;
}

.sl-lobby-container {
	margin-left: 2vw;
	margin-top: 16vh;
	margin-bottom: 24px;
}

body.machine {
	.sl-lobby-container {
		margin: unset;
		margin-top: 18vh;
		overflow-x: hidden;
		padding: 4vh 2vw;
		// width: 95vw;
		box-sizing: border-box;

		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 16px;
	}

	.categories {
		right: 2vw;
		padding-top: 8px;
	}
}

.categories {
	position: fixed;
	right: 16vw;
	top: 11vh;

	display: flex;

	& > div {
		cursor: pointer;
		position: relative;
		font-size: 1.5vh;

		display: flex;
		align-items: center;
		height: 3.5vh;
		margin: 0.7vh 0.25vw;

		background: #353750;
		border-radius: 4px;
		border: 2px solid #42445d;
		color: #8b8ab2;

		transition: 0.5s;
	}

	& > div > img {
		height: 2vh;
		margin: 0 0.4vw;
	}

	& > div > div {
		padding-right: 8px;
	}

	& > .active {
		background-color: #6c66fa;
		border-color: #847fff;
		color: white;
	}
}

/* Lobby History Container */
.lobby-history-container {
	position: absolute;
	width: 12vw;
	height: 10vh;

	display: flex;
}

.lobby-history-container > div.left-button {
	display: inline-block;
	vertical-align: top;
}

.lobby-history-container > div.left-button > img {
	width: 1.8vw;
	height: 3.4vh;
}

.lobby-history-container > .lobby-history-roadmap {
	position: relative;
	display: inline-block;
	vertical-align: top;
	background: #27283f;
	height: 100%;

	& > .history-container {
		width: 11.8vw;
		height: 8vh;

		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;

		text-align: center;

		&.history-m14 {
			text-align: left;
		}
	}
}

// MOBILE ROADMAP OPTIONS
.sl-single-mobile .roadmap-options {
	position: relative;
	display: inline-block;
	vertical-align: top;

	& > div {
		width: 8vw;
		height: 6.5vh;
		line-height: 6.5vh;
		font-size: 3vh;
	}
}

// .sl-single-mobile.portrait .roadmap-options {
// 	top: 3vh;
// }

.sl-single-mobile.landscape .roadmap-options {
	background: transparent;

	& > div {
		left: unset;
		width: 3.2vw;
		height: 10vh;
		font-size: 5vh;
		line-height: 10vh;

		&.active {
			color: white;
		}
	}
}

/* MULTI TABLE ROADMAP OPTIONS */
.landscape .min .roadmap {
	position: unset;
	left: unset;
	bottom: unset;
	width: 100%;
	height: 8.4vh;
}

.sl-multi-table > .min .roadmap-table.v3 .roadmap {
	left: 0;
	bottom: unset;
	width: 100%;
	height: 8.4vh;
}

.chip {
	color: #26273d;
	font-size: 1.6vh;
	font-weight: 600;
	cursor: pointer;
}

.sl-single-game .min,
.sl-single-game .max {
	background-color: #151624;
	border-left: 1px solid black;
	border-right: 1px solid black;
}

.sl-single-game .max {
	border-bottom: 1px solid #000000;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.sl-single-game .header {
	width: 100%;
	background: #41527e;
}

.sl-table {
	text-align: center;
	display: block;
	position: relative;
}

.sl-multi-table .sl-table {
	background-color: #0a3c21;
}

.sl-single-game .sl-table {
	width: 1185px;
	height: 200px;
	left: 8px;
}

.sl-single-game .table.machine {
	left: 317px;
}

.sl-single-game .table.machine .sl-table {
	width: 867px;
}

.sl-multi-table .sl-table {
	width: 19.15vw;
	height: 19vh;
}

.sl-single-mobile.landscape #game,
.landscape .modal-container {
	/* always align centrally, adjust scaling with Javascript */
	position: fixed;
	right: 0;
	left: 40vw;
	top: 0;
	bottom: 8vh;
	margin: auto;

	width: 360px;
	height: 190px;

	transition: all 0.2s;
}

.landscape .modal-container.win > img {
	width: 100%;
}

.landscape .modal-container.win .modal-win-amount {
	font-size: 4vh;
	bottom: 22%;
	right: 32%;
}

.sl-single-mobile.landscape .max.pane-hidden #game,
.landscape .pane-hidden .modal-container {
	left: 0;
	top: 2vw;
	bottom: 0;
}

.sl-single-mobile.landscape .max.hugestreaming #game,
.sl-single-mobile.landscape .max.hugestreaming .modal-container {
	position: absolute;
	left: -17vw;
	right: 0;
	top: 0;
	// bottom: 37vh;
	bottom: 56vh;

	@media (min-width: 600px) {
		& {
			left: -12vw;
		}
	}

	@media (min-width: 700px) {
		& {
			left: -9.5vw;
		}
	}

	@media (min-width: 800px) {
		& {
			left: -6.5vw;
		}
	}
}

.sl-single-game .modal-closed-backdrop {
	width: 1200px;
	height: 200px;
	z-index: 15;
}

.sl-single-game .betmodal-status-false {
	width: 1200px;
	top: 19%;
	padding: 1% 0;
}

.sl-single-game .betmodal-status-false > div:first-child {
	margin-bottom: 20px;
}

div.table > .sl-table-dim + .sl-table,
div.table > .sl-table-dim + .modal-closed-backdrop + .sl-table,
.sl-game:not(.sl-single-mobile) div.table > .sl-table-dim + .modal-container {
	margin-top: -227px;
}

div.table > div.modal-container {
	margin: 0;
}

.sl-flex {
	display: flex;
	flex-wrap: nowrap;
}

.bold {
	font-family: Lato-Bold;
}

.sl-bet {
	font-family: Lato-Light;
	font-size: 1.2vh;
	color: white;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	position: relative;
	box-sizing: border-box;

	// background-color: #225c1f;

	&.disabled {
		cursor: not-allowed;
	}

	& > .bet-label {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		line-height: initial;
	}
}

button.sl-bet {
	padding: 0;
	margin: 0;
	border: none;
	outline: none;
	display: inline-block;
}

.sl-bet > div {
	white-space: pre-line;
}

$start: #0856af;
@keyframes hover-blue {
	0% { background: $start; }
	50% { background: #0b6bd8; }
	100% { background: $start; }
}

.sl-bet:hover {
	animation: hover-blue 1s ease-in-out infinite;
	// background: #0856af !important;

	// background: linear-gradient(
	// 	135deg,
	// 	#0856af 0%,
	// 	rgba(0, 83, 173, 1) 100%
	// ) !important;
}

.m25 .sl-bet:hover {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cedbe9+0,aac5de+17,6199c7+50,3a84c3+51,419ad6+59,4bb8f0+71,3a8bc2+84,26558b+100;Blue+Gloss */
	background: linear-gradient(
		135deg,
		rgba(206, 219, 233, 1) 0%,
		rgba(170, 197, 222, 1) 17%,
		rgba(97, 153, 199, 1) 50%,
		rgba(58, 132, 195, 1) 51%,
		rgba(65, 154, 214, 1) 59%,
		rgba(75, 184, 240, 1) 71%,
		rgba(58, 139, 194, 1) 84%,
		rgba(38, 85, 139, 1) 100%
	) !important;
}

.sl-bet.hidden {
	border: none !important;
}

.sl-bet.hidden.disabled {
	visibility: hidden;
}

.sl-bet.hidden:hover {
	background: transparent !important;
}

.sl-hover-hidden {
	background: transparent;
	/*background: rgba(255, 255, 0, 0.4);*/
	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
	z-index: 6;
}

.sl-bet-hover {
	background-color: #299ecc !important;
}

.sl-table-dim {
	background-color: transparent;
	z-index: 8;
	height: 20.2vh;
	width: 100%;
	top: 0;
	position: absolute;
}

.sl-single-mobile.portrait .table .sl-table-dim {
	height: 100%;
}

.sl-single-game .table .sl-table-dim {
	top: unset;
	bottom: 6%;
	height: 210px;
}

/* WIN NUMBER GIF */
// .win-number-gif {
// 	pointer-events: none;
// }

.sl-game > .min > .win-number-gif {
	position: relative;
	background: rgba(0, 0, 0, 0.5);
	height: 0;
	transition: 0.5s all;

	left: 0;
	z-index: 12;

	& > img {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 10%;
	}

	& > p {
		position: absolute;
		top: 10%;
		left: 0;
		right: 0;
		color: white;
		opacity: 0;
		transition: 0.5s opacity;
		font-size: 1.5vh;
	}

	&.appear {
		height: 11.34vh;
	}

	&.appear > p {
		opacity: 1;
	}
}

.sl-game.sl-single-game > .min > .win-number-gif {
	left: 325px;
	top: 165px;

	width: 558px;

	& > img {
		width: unset;
	}

	& > p {
		font-size: 45px;
	}

	&.appear {
		top: 0;
		height: 315px;
	}
}

.sl-single-game.machine-game > .min > .win-number-gif {
	left: 8px;
	top: 8px;

	width: 310px;

	& > p {
		font-size: 30px;
	}

	&.appear {
		height: 182px;
	}
}

/* WIN NUMBER GIF */
.sl-single-mobile.portrait .win-number-gif,
.sl-single-mobile.landscape .win-number-gif {
	position: fixed;

	background: rgba(0, 0, 0, 0.5);
	top: 15vh;

	width: 100vw;
	height: 0;
	left: 0;

	z-index: 11;

	transition: 0.5s all;

	.bg-green {
		position: relative;

		display: inline-block;
		width: 20vw;
		height: 20vw;
		line-height: 20vw;
		background: #23be35;

		border-radius: 50%;
		margin: 0 0.5vw;

		color: white;

		top: 12vh;
		opacity: 0;
	}

	//Fantan, copied from bg-green
	.fantan-white,
	.fantan-green,
	.fantan-yellow,
	.fantan-red {
		position: relative;

		display: inline-block;
		width: 20vw;
		height: 20vw;
		line-height: 20vw;

		border-radius: 50%;
		margin: 0 0.5vw;

		color: white;

		top: 12vh;
		opacity: 0;

		font-size: 8vh;
	}

	.fantan-white {
		background: white;
		color: black;
	}

	.fantan-green {
		background: #57eb54;
	}

	.fantan-yellow {
		background: yellow;
		color: black;
	}

	.fantan-red {
		background: #ff4e60;
	}
}

.sl-single-mobile.portrait .win-number-gif {
	top: 13vh;
}

.sl-single-mobile.portrait .win-number-gif.appear {
	height: 30vh;

	.bg-green {
		opacity: 1;
	}

	.fantan-white,
	.fantan-green,
	.fantan-yellow,
	.fantan-red {
		opacity: 1;
	}
}

.sl-single-mobile.portrait .win-number-gif > p,
.sl-single-mobile.landscape .win-number-gif > p {
	position: absolute;
	top: 10%;
	left: 0;
	right: 0;
	color: white;
	font-size: 2em;

	opacity: 0;

	transition: 0.5s opacity;
}

.sl-single-mobile.portrait .win-number-gif.appear > p,
.sl-single-mobile.landscape .win-number-gif.appear > p {
	opacity: 1;
}

.sl-single-mobile.portrait .win-number-gif.no-left-pane,
.sl-single-mobile.landscape .win-number-gif.no-left-pane {
	opacity: 0;
}

.sl-single-mobile.portrait .win-number-gif > img {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	max-width: 100%;
}

.sl-single-mobile.landscape .win-number-gif {
	top: 19vh;
	left: unset;

	width: 36vw;
	height: 0;
}

.sl-single-mobile.landscape .win-number-gif.appear {
	top: 10vh;
	height: 40vh;
}

.sl-single-mobile.landscape .win-number-gif > p {
	text-align: center;
}

.sl-single-mobile.landscape .win-number-gif > img {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
}

/* WIN NUMBER GIF per game */
#wng_m7.win-number-gif > img,
#wng_m10.win-number-gif > img,
#wng_m11.win-number-gif > img,
#wng_m16.win-number-gif > img,
#wng_p12.win-number-gif > img,
#wng_m29.win-number-gif > img,
#wng_m31.win-number-gif > img,
#wng_m32.win-number-gif > img,
#wng_m33.win-number-gif > img,
#wng_m37.win-number-gif > img {
	height: 50%;
}

#wng_m7.win-number-gif > img:nth-child(2),
#wng_m10.win-number-gif > img:nth-child(2),
#wng_m11.win-number-gif > img:nth-child(2),
#wng_m16.win-number-gif > img:nth-child(2),
#wng_p12.win-number-gif > img:nth-child(2),
#wng_m29.win-number-gif > img:nth-child(2),
#wng_m31.win-number-gif > img:nth-child(2),
#wng_m32.win-number-gif > img:nth-child(2),
#wng_m33.win-number-gif > img:nth-child(2),
#wng_m37.win-number-gif > img:nth-child(2) {
	right: 50%;
}

#wng_m7.win-number-gif > img:nth-child(4),
#wng_m10.win-number-gif > img:nth-child(4),
#wng_m11.win-number-gif > img:nth-child(4),
#wng_m16.win-number-gif > img:nth-child(4),
#wng_p12.win-number-gif > img:nth-child(4),
#wng_m29.win-number-gif > img:nth-child(4),
#wng_m31.win-number-gif > img:nth-child(4),
#wng_m32.win-number-gif > img:nth-child(4),
#wng_m33.win-number-gif > img:nth-child(4),
#wng_m37.win-number-gif > img:nth-child(4) {
	left: 50%;
}

#wng_m19.win-number-gif > img:nth-child(2),
#wng_m39.win-number-gif > img:nth-child(2),
#wng_m42.win-number-gif > img:nth-child(2) {
	right: 30%;
}

#wng_m19.win-number-gif > img:nth-child(3),
#wng_m39.win-number-gif > img:nth-child(3),
#wng_m42.win-number-gif > img:nth-child(3) {
	left: 30%;
}

#wng_m8.win-number-gif > img {
	height: 60%;
}

#wng_p7.win-number-gif > img {
	height: 80%;
}

#wng_p9.win-number-gif > img,
#wng_p12.win-number-gif > img,
#wng_p6.win-number-gif > img,
#wng_m6.win-number-gif > img,
#wng_m13.win-number-gif > img,
#wng_m16.win-number-gif > img,
#wng_m19.win-number-gif > img,
#wng_m39.win-number-gif > img,
#wng_m20.win-number-gif > img,
#wng_m31.win-number-gif > img,
#wng_m32.win-number-gif > img,
#wng_m37.win-number-gif > img,
#wng_m42.win-number-gif > img {
	top: unset;
	bottom: 20%;
	height: 40%;
}

#wng_m11.win-number-gif > img,
#wng_m14.win-number-gif > img {
	bottom: 10%;
	height: 30%;
}

#wng_m14.win-number-gif > img:nth-child(2) {
	right: 60%;
}

#wng_m14.win-number-gif > img:nth-child(3) {
	right: 30%;
}

#wng_m14.win-number-gif > img:nth-child(5) {
	left: 30%;
}

#wng_m14.win-number-gif > img:nth-child(6) {
	left: 60%;
}

#wng_m26.win-number-gif > img,
#wng_m30.win-number-gif > img {
	width: 20%;
	right: unset;

	@for $i from 2 through 6 {
		&:nth-child(#{$i}) {
			left: ($i - 2) * 20%;
			top: 10%;
		}
	}

	@for $i from 7 through 11 {
		&:nth-child(#{$i}) {
			left: ($i - 7) * 20%;
			top: 80%;
		}
	}
}

.portrait #wng_m26.win-number-gif.appear,
.portrait #wng_m30.win-number-gif.appear {
	height: 48vh;
}

.portrait #wng_m26.win-number-gif > p,
.portrait #wng_m30.win-number-gif > p {
	top: 24%;
}

.sl-single-mobile #wng_m26.win-number-gif > img {
	width: 16%;

	@for $i from 2 through 6 {
		&:nth-child(#{$i}) {
			left: ($i - 2) * 20% + 2%;
			top: 0;
		}
	}

	@for $i from 7 through 11 {
		&:nth-child(#{$i}) {
			left: ($i - 7) * 20% + 2%;
			top: 60%;
		}
	}
}

//Custom Classes for Machine Games
#wng_m29.win-number-gif > img {
	height: 40%;
}

.portrait #wng_m29 {
	top: 25vh;
}

.portrait #wng_m29 > img {
	bottom: -10%;
}

.portrait #wng_m29 .kop-kepala-ekor {
	font-size: 4vh;
}

.landscape #wng_m29 > img {
	bottom: 16%;
}

.win-number-gif .kop-kepala-ekor {
	color: white;
	position: absolute;
	bottom: 0;
	width: 75%;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;

	& > div {
		width: percentage(1/3);
		display: inline-block;
	}
}

// ROADMAP SHAPES
.sl-roadmap-circle {
	border-radius: 50%;
}

.sl-roadmap-circle,
.sl-roadmap-square {
	width: 100%;
	height: 100%;

	font-size: 0.9vh;
	color: white;
	text-align: center;
}

.sl-roadmap-diamond {
	position: relative;
	transform: rotate(45deg);
	width: 100%;
	height: 100%;
}

.sl-table .sl-roadmap-diamond {
	width: 70%;
	height: 70%;
}

.sl-multi-table .roadmap-options,
.sl-single-mobile .lobby-roadmap-table {
	.sl-roadmap-diamond,
	.sl-roadmap-square,
	.sl-roadmap-circle {
		display: inline-block;
	}
}

/* ROADMAP */
.lobby-roadmap-table {
	background: white;
	border-collapse: collapse;
}

.lobby-roadmap-table > tbody tr,
.lobby-roadmap-table > tbody td {
	position: relative;
	box-sizing: border-box;
	display: block;
	height: 1.7vh;
	border: 1px solid #e9e9e9;
}

.lobby-roadmap-table > tbody td {
	display: inline-block;
	vertical-align: top;
	width: 0.8vw;
}

.lobby-roadmap-table .sl-roadmap-circle,
.lobby-roadmap-table .sl-roadmap-square {
	line-height: 1vw;
}

@mixin middle {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.mobile-home.portrait .sl-roadmap-circle,
.mobile-home.portrait .sl-roadmap-square,
.mobile-home.portrait .sl-roadmap-diamond {
	@include middle;

	width: 3vw;
	height: 1.5vh;
}

.mobile-home.portrait .roadmap-description .sl-roadmap-diamond {
	display: inline-block;
	position: relative;
}

//Mobile Roadmap Text
.mobile-home .lobby-roadmap-table > thead,
.sl-single-mobile.landscape .lobby-roadmap-table > thead,
.sl-single-mobile.portrait .lobby-roadmap-table > thead {
	position: absolute;
	bottom: 0;
	right: 0;
	display: block;

	width: 100%;
	text-align: right;

	opacity: 0.5;

	& > tr {
		display: block;
		width: 100%;
		height: unset;
	}

	& > tr > td {
		display: inline-block;
		width: 100%;
		height: unset;
		color: black;
	}
}

.mobile-home .lobby-roadmap-table > thead {
	.sl-roadmap-circle,
	.sl-roadmap-square,
	.sl-roadmap-diamond {
		position: relative;

		margin: unset;
		top: unset;
		left: unset;
		right: unset;
		bottom: unset;

		display: inline-block;
	}
}

.sl-single-game .roadmap-table .sl-roadmap-diamond {
	width: 18px;
	height: 18px;
	left: 3px;
}

.roadmap-options > span.active > div {
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	& > div {
		width: 1vh;
		height: 1vh;
	}
}

.sl-multi-table .lobby-roadmap-table tr,
.sl-multi-table .lobby-roadmap-table td {
	height: 1.7vh;
}

.sl-multi-table .lobby-roadmap-table td {
	width: 0.84vw;
}

.sl-multi-table .sl-roadmap-diamond,
.sl-multi-table .sl-roadmap-square,
.sl-multi-table .sl-roadmap-circle {
	left: 0.1vw;
	width: 1vh;
	height: 1vh;
}

//Basic Roadmap Colours
.sl-roadmap-red {
	background: #b41b37;

	& > img {
		width: 100%;
	}
}

.sl-roadmap-yellow {
	background: #d6d600;

	& > div {
		color: black;
	}
}

.sl-roadmap-black {
	background: black;
}

.sl-roadmap-green {
	background: #008000;

	& > img {
		width: 100%;
	}
}

.sl-roadmap-blue {
	background: #1f5992;

	& > img {
		width: 100%;
	}
}

.sl-roadmap-grey {
	background: #bec3c9;
}

/* New in V2 */
.sl-roadmap-slash {
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%) rotate(-45deg);
	background: transparent;
	border-bottom: 2px solid transparent;
}

.sl-roadmap-slash.sl-roadmap-red {
	border-bottom-color: #b41b37;
}

.sl-roadmap-slash.sl-roadmap-blue {
	border-bottom-color: #1f5992;
}

.sl-roadmap-slash.sl-roadmap-yellow {
	border-bottom-color: #d6d600;
}

.sl-roadmap-circle,
.sl-roadmap-circle-hollow {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.sl-roadmap-circle-hollow {
	background: transparent;
	box-sizing: border-box;
}

.sl-roadmap-circle-hollow.sl-roadmap-red {
	border: 2px solid #b41b37;
}

.sl-roadmap-circle-hollow.sl-roadmap-green {
	border: 2px solid #008000;
}

.sl-roadmap-circle-hollow.sl-roadmap-blue {
	border: 2px solid #1f5992;
}

.sl-roadmap-circle-hollow.sl-roadmap-yellow {
	border: 2px solid #d6d600;
}

.sl-roadmap-alternating {
	animation: sl-roadmap-transition 0.25s infinite alternate;
}

@keyframes sl-roadmap-transition {
	to {
		opacity: 0;
	}
}

/* Baccarat special */
.sl-baccarat-banker-pair,
.sl-baccarat-player-pair {
	position: absolute;
	width: 30%;
	height: 30%;
	border-radius: 50%;
}

.game-statistics .sl-roadmap-circle .sl-baccarat-banker-pair,
.game-statistics .sl-roadmap-circle .sl-baccarat-player-pair {
	border: 1px solid white;
}

.sl-roadmap-circle.sl-roadmap-red .sl-baccarat-banker-pair.sl-roadmap-red {
	border: 1px solid white;
	left: 0;
	top: 0;
}

.sl-roadmap-circle.sl-roadmap-blue .sl-baccarat-player-pair.sl-roadmap-blue {
	border: 1px solid white;
	bottom: 0;
	right: 0;
}

.sl-baccarat-banker-pair {
	top: 5%;
	left: 5%;
}

.sl-baccarat-player-pair {
	right: 5%;
	bottom: 5%;
}

.sl-baccarat-bead-value {
	font-size: 2vh;
	color: white;
}

.roadmap-table .sl-baccarat-bead-value {
	font-size: 1.5vh;
}

.roadmap-table .fantan .sl-baccarat-bead-value {
	font-size: 1.3vh;
}

.sl-hollow-value {
	font-size: 1.2vh;
}

.sl-roadmap-circle-hollow.sl-roadmap-red .sl-hollow-value {
	color: #b41b37;
}

.sl-roadmap-circle-hollow.sl-roadmap-blue .sl-hollow-value {
	color: #1f5992;
}

.sl-roadmap-circle-hollow.sl-roadmap-yellow .sl-hollow-value {
	color: black;
}

.dark .sl-roadmap-circle-hollow.sl-roadmap-red .sl-hollow-value {
	color: #e26c82;
}

.dark .sl-roadmap-circle-hollow.sl-roadmap-blue .sl-hollow-value {
	color: #649ad0;
}

.dark .sl-roadmap-circle-hollow.sl-roadmap-yellow .sl-hollow-value {
	color: #d6d600;
}

.sl-baccarat-diagonal-line.sl-roadmap-green {
	width: 100%;
	height: 120%;
	background: transparent;
	border-bottom: 2px solid #008000;
	transform: translate(-35%, -35%) rotate(-45deg);
}

.sl-baccarat-diagonal-line.sl-roadmap-green.tie {
	position: absolute;
	line-height: 2.8vh;
	font-size: 60%;
	top: 0;
}

.sl-single-mobile.portrait .sl-baccarat-diagonal-line.sl-roadmap-green {
	line-height: initial;
	height: 50%;
	transform: translate(-15%, 10%) rotate(-45deg);
}

/* End of New in V2 */

/* New in V3 */
.sl-baccarat-blue {
	background: #2155a8;
}

.sl-baccarat-bet-blue {
	background: #134b88;
}

.sl-baccarat-bet-dark-blue {
	background: #092646;
}

.sl-baccarat-red {
	background: #b83432;
}

.baccarat-blue-text {
	color: #00b4ff;
}

.baccarat-green-text {
	color: #23be35;
}

.baccarat-red-text {
	color: #ff452c;
}

/* End of new in V3 */

.sl-roadmap-white {
	box-sizing: border-box;
	border: 1px solid black;
	width: 95%;
	background: white;
}

/* ----------------- HISTORY -------------------------- */
.sl-game .history-container {
	width: 10vw;
	height: 8.4vh;
	margin: 0 auto;

	left: 1.9vw;
	bottom: 0.7vh;
	position: absolute;
}

.sl-single-game .history-container {
	width: 100%;
	margin: 0 auto;
	height: 115px;

	left: unset;
	bottom: unset;
}

.sl-single-game.machine-game .history-container {
	width: 50%;
}

.portrait .history-container {
	width: 100%;
	height: unset;

	left: unset;
	bottom: unset;
}

.history-circle {
	color: white;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
}

.sl-single-game .history-circle {
	width: 25px;
	height: 25px;
	margin-right: 10px;
	line-height: 25px;
	font-size: 13px;
}

.sl-multi-table .history-circle {
	width: 1.33vw;
	height: 2.5vh;
	margin: 0.8vh 0.25vw;
	line-height: 2.5vh;
	font-size: 1.2vh;
}

.lobby-history-container .history-circle {
	width: 1.3vw;
	height: 2.6vh;
	margin: 0 0.5vw;
	line-height: 2.5vh;
	font-size: 1.3vh;
}

.sl-single-mobile.portrait .history-circle {
	width: 12vw;
	height: 12vw;
	line-height: 12vw;
	font-size: 4vh;
	margin: 2% 3%;
}

.sl-single-mobile.landscape .history-container .history-circle {
	width: 5vw;
	height: 10vh;
	line-height: 10vh;
	margin: 3% 1vw;
	font-size: 5vh;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.ads {
	position: fixed;
	top: 12vh;
	right: 3vw;

	img {
		width: 11vw;
	}
}

.shadowed {
	filter: drop-shadow(-3px 0px 3px rgba(0, 0, 0, 0.5));
}

/* NEW IN FANTAN */

.roadmap-table .fantan > div {
	color: black;
	border: 1px solid gray;
	box-sizing: border-box;
	border-radius: 50%;
}

.sl-fantan-green {
	background: #57eb54;
}

.sl-fantan-red {
	background: #ff4e60;
}

.sl-fantan-yellow {
	background: yellow;
}

.sl-fantan-white {
	background: white;
}

.ios-experience-message {
	position: fixed;
	bottom: 4vh;
	color: white;
	z-index: 100;
}

.version-info {
	position: fixed;
	bottom: 0;
	right: 0;
	color: #8b8bb3;
	font-size: 1.2vh;
}

// animation blibking
@-webkit-keyframes flash {
	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

@keyframes flash {
	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

@keyframes flash2 {
	from,
	50%,
	to {
		opacity: 1;
		background-color: transparent;
	}

	25%,
	75% {
		opacity: 0;
		background-color: #ff9800;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.blinking {
	animation-name: flash;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.blinking2 {
	animation-name: flash2;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
}

.button-refresh-balance {
	display: inline-block;
	margin-left: 8px;
	padding: 0;
	position: relative;
	height: 16px;
	width: 16px;
	background: transparent;
	border: 0;
	cursor: pointer;

	& > img {
		display: block;
		width: 16px;
		height: 16px;
	}
}

.sl-footer .button-refresh-balance {
	vertical-align: top;
}

.mobile-home.landscape .box-header .button-refresh-balance,
.sl-game-v2.sl-single-mobile-v2.landscape .header .button-refresh-balance,
.sl-game-v4.sl-single-mobile-v4.landscape .header .button-refresh-balance {
	top: 2px;
}

.mobile-home.portrait .box-header .button-refresh-balance,
.sl-game.sl-single-mobile.portrait .header .button-refresh-balance {
	top: 3px;
}

.sl-game.sl-single-mobile.landscape .header .button-refresh-balance {
	top: 1px;

	& > img {
		width: 18px;
		height: 18px;
	}
}

.button-refresh-balance::active {
	background-color: red;
}

.button-refresh-balance.spin {
	animation-name: spin;
	animation-duration: 2s;
	animation-iteration-count: 1;
}
