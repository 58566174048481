@import "./virtual_cards/desktop_single", "./virtual_cards/desktop_multi_table", "./virtual_cards/mobile_landscape", "./virtual_cards/mobile_portrait";

$blueLetterColour: rgb(33, 85, 128);

@keyframes animateTextGlow {
	0% {
		text-shadow: 0 0 25px white;
		opacity: 0.4;
	}

	50% {
		text-shadow: 0 0 40px white;
		opacity: 1;
	}

	100% {
		text-shadow: 0 0 25px white;
		opacity: 0.4;
	}
}

.virtual-cards {
	position: absolute;
	pointer-events: none;
	text-align: center;
	opacity: 1;
	z-index: 1;
	transition: 0.5s all;

	&.overlay {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
		left: 0;
		right: 0;
		top: 12%;
		margin: auto;
		width: 100%;
		height: 88%;

		.sl-single-game-v2 & {
			position: fixed;
			top: 8vh;
			width: 76%;
			height: 40vh;
		}
	}

	&.hidden {
		opacity: 0;
		z-index: -1;
	}

	.cards {
		position: absolute;
		top: 2vh;

		& > div.lie > img {
			margin-top: -0.4vh;
			margin-left: -0.21vw;
		}
	}

	.left.cards {
		left: 12vw;
	}

	.right.cards {
		right: 3%;
	}

	.flipping-card {
		width: 1.8vw;
		height: 4.6vh;
		margin: 0 0.43vw;

		&.lie {
			margin-left: 0.5vw;
			margin-right: 0.5vw;
			border-radius: 4px;
			width: 1.6vw;
			height: 4.5vh;

			&.active {
				transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg) translate(-1.1vw, 3vh);
			}
		}
	}

	.left-letter,
	.right-letter {
		position: absolute;
		font-size: 4.2vh;
		color: white;
		border-radius: 50%;
		width: 3.2vw;
		height: 6vh;
		line-height: 6vh;
		top: 13vh;

		&.win {
			top: 8.5vh;
			width: unset;
			border-radius: unset;
			padding-left: 0.4vw;
			padding-right: 0.4vw;
			background: transparent;

			& > img {
				margin-top: 0;
				width: 14.63vw;
				height: 4.8vh;
			}
		}
	}

	.tie-container {
		position: absolute;
		top: 9.5vh;
		left: 11.5vw;
		right: 0;

		& > img {
			width: 18vw;
		}
	}

	.left-letter {
		left: 38%;
		background: $blueLetterColour;

		&.dragon {
			background: #ff715f;
		}
	}

	.left-value,
	.right-value {
		position: absolute;
		top: 12.5vh;
		font-size: 5vh;
		color: white;

		&.win {
			text-decoration: none;
			text-shadow: 0 0 25px white;
			animation: animateTextGlow 1s linear infinite;
		}
	}

	.left-value {
		left: 53%;

		&.win {
			left: 13vw;
		}
	}

	.right-letter {
		right: 3%;
		background: rgb(184, 52, 47);

		&.tiger {
			background: $blueLetterColour;
		}
	}

	.right-value {
		right: 17%;

		&.win {
			right: 2vw;
		}
	}
}
