.switch {
	position: relative;
	display: inline-block;
	/* width: 56px; */
	/* height: 34px; */
	width: 2.5vw;
	height: 3vh;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

.sliderthumb {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border: 2px solid black;
	background-color: white;
	transition: 0.4s;

	/* height: 26px; */
	height: 2.6vh;

	&:before {
		position: absolute;
		content: "";
		/* height: 22px; */
		/* width: 22px; */
		width: 1.17vw;
		height: 2.2vh;
		left: 0;
		border: 2px solid black;
	
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#323232+0,4b4b4b+50,ffffff+51,ffffff+94,ffffff+94,000000+95,000000+100 */
		background: radial-gradient(
			ellipse at center,
			#323232 0%,
			#4b4b4b 50%,
			#ffffff 51%,
			#ffffff 94%,
			#ffffff 94%,
			#000000 95%,
			#000000 100%
		);
	
		transition: 0.4s;
	}
}

input:checked + .sliderthumb {
	/* background-color: #2196F3; */
	background-color: #4b4b4b;
	border: 2px solid black;

	&:before {
		/* transform: translateX(26px); */
		transform: translateX(1vw);
	}
}

/* Rounded sliders */
.sliderthumb.round {
	/* border-radius: 34px; */
	border-radius: 3.4vh;

	&:before {
		border-radius: 50%;
	}
}
