.mobile-home.portrait .game-game {
	text-align: left;

	.game-game-right > .fav-box {
		height: 6vh;
		line-height: 8vh;
		width: 8vw;

		& > img {
			height: 3vh;
		}
	}

	p {
		width: 37%;
	}
}
