/* SINGLE GAME */
.sl-single-game .sl-table.m10 {
	.m10-top-row > .m10-corners,
	.m10-middle-row > .big-coin {
		width: 290px;
	}

	.m10-top-row {
		height: 35%;

		// .m10-corners {
		// 	height: 60px;
		// }

		.bet-label > img,
		.sl-bet > img {
			width: 30px;
		}

		& > .inner-top {
			width: 200px;

			& > .m10-top-text {
				font-size: 18px;
				padding: 2px 0;
			}
		}
	}

	.m10-middle-row {
		height: 45%;

		.big-coin {
			font-size: 20px;
			padding: 5px 0;

			& > img {
				width: 50px;
			}
		}

		.inner-middle .sl-bet > img {
			width: 24px;
			margin: 8px 3px;
		}
	}

	.m10-bottom-row {
		height: 20%;
	}

	.m10-bottom-row > .sl-bet {
		height: 40px;
		font-size: 24px;

		& > div:first-child {
			margin-top: 4px;
		}
	}
}
