.p6-table {
	.bet-label {
		position: unset;
	}

	.p6-diamond {
		border: none;
		transform: rotate(45deg);
		border-radius: 2px;

		position: absolute;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.sl-bet:not(.hidden) {
		box-sizing: border-box;
		border: 1px solid #083d21;
	}


	.sides {
		display: inline-block;
		vertical-align: top;
	}

	.sides > div {
		display: block;
	}


	.main-box-bet {
		display: inline-block;
		vertical-align: top;
	}

	.detail-bet {
		display: inline-block;
		vertical-align: top;
	}

	.left-column, .two-rows {
		position: relative;
	}

	.right-column > span:first-child {
		position: absolute;
		margin: auto;
		width: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		height: fit-content;
	}


	.detail-bet > div {
		display: inline-block;
		position: relative;
	}
	
	.bottomPart {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.bottomPart > div {
		display: inline-block;
	}

	.bottomPart > div > div:first-child {
		position: relative;
	}
}

/* SINGLE GAME */
.sl-single-game .p6-table {
	.detail-bet:first-child .sl-bet {
		font-size: 16px;
	}

	.detail-bet:last-child .sl-bet {
		font-size: 14px;
	}

	.left-column > div {
		height: 32px;
	}

	.right-column.sl-bet {
		height: 66px;
		line-height: 66px;
	}

	.detail-bet {
		width: 115px;
	}

	.detail-bet > div {
		width: 115px;
		height: 33px;
		line-height: 38px;
	}

	.main-box-bet .detail-bet .left-column {
		height: 26px;
	}

	.left-column,
	.two-rows {
		top: 33px;
	}

	.sides > div {
		width: 128px;
		height: 66px;
		line-height: 66px;
		
		font-size: 30px;
	}

	.p6-diamond {
		width: 32px;
		height: 32px;
		top: 20%;
	}

	.main-box-bet > div:first-child > div:not(:first-child) {
		width: 114px;
	}

	.main-box-bet > div > div {
		width: 115px;
	}

	.sides > div:nth-child(2) {
		height: 65px;
	}

	
	.bottomPart > div {
		width: 138px;
		height: 36px;
   	}

   	.bottomPart > div > div:first-child {
		font-size: 16px;
		line-height: 40px;
   	}
}

/* DESKTOP MULTI TABLE */
.sl-multi-table .p6-table {
	.p6-diamond {
		width: 1vw;
		height: 2vh;
		margin-top: 56%;
		margin-left: 22%;
	}
	
	.sides {
		width: 2vw;
		font-size: 1vh;
	}
	
	.sides > div {
		width: 100%;
		height: 6.3vh;
		line-height: 6.3vh;
	}
	
	.main-box-bet {
		width: 78%;
	}
	
	.detail-bet {
		width: 1.86vw;
		font-size: 1vh;
	}
	
	.detail-bet > div {
		width: 1.81vw;
		height: 3.2vh;
		line-height: 3.2vh;
	}

	.detail-bet > .number-cell > div:first-child {
		font-size: 2vh;
	}
	
	//Same as detail-bet > div first child
	.left-column, .two-rows {
		top: 3.2vh;
	}

	.left-column > div {
		height: 3.2vh;
	}
	
	.two-rows > .right-column {
		height: 6.4vh;
	}
	
	/*BOTTOM PART*/
	.bottomPart > div {
		width: 2.2vw;
		height: 3vh;
	}
	
	.bottomPart > div > div:first-child {
		font-size: 0.9em;
	}
}

/* SINGLE MOBILE LANDSCAPE */
.sl-single-mobile.landscape .p6-table {
	.p6-diamond {
		width: 20px;
		height: 20px;
	}

	.sides {
		font-size: 10px;
	}
	
	.sides > div {
		width: 38px;
		height: 62px;
		line-height: 62px;

		font-size: 12px;
	}
	
	.detail-bet {
		width: 35px;
		font-size: 10px;
	}
	
	.detail-bet > div {
		width: 34px;
		height: 31px;
	}
	
	//Only the number cells
	.detail-bet > .number-cell > div:first-child {
		font-size: 22px;
	}

	.left-column, .two-rows {
		top: 31px;
	}

	.left-column > .sl-bet > div:first-child {
		font-size: 12px;
	}
	
	.left-column {
		height: 26px;
	}
	
	.two-rows > .right-column {
		height: 62px;
	}
	
	.detail-bet > .sl-bet.tabs {
		height: 32px;
		line-height: 32px;
	}
	
	.detail-bet > .sl-bet.tabs > div:first-child {
		font-size: 12px;
		line-height: initial;
	}

	/*BOTTOM PART*/
	.bottomPart > div {
		width: 41px;
		height: 30px;
	}
	
	.bottomPart > div > div:first-child {
		font-size: 9px;
	}
}

// MOBILE PORTRAIT
.sl-single-mobile.portrait .sl-table.p6 {
	.taller {
		height: 7.16vh;
		line-height: 7.16vh;
		
		& > div:first-child {
			display: inline-block;
			vertical-align: middle;
			line-height: initial;
		}
	}
}
