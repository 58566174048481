@import
	"./v4_dark",
	"./m24_history_container",
	"./m24_roadmap_container";

.sl-multi-table.v4 > .table {
	position: absolute;
	left: 12.12vw;
	top: 4vh;
}

.lobby-history-roadmap .win,
.m24-history .win {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.sl-game.v4 > .controller {
	position: absolute;
	left: 12.13vw;
	bottom: 0.8vh;
}

.sl-multi-table.v4 .controller {
    width: 19.15vw;
    padding-top: 0.6vh;
    display: grid;
    grid-gap: 0.53vw;
    grid-template-columns: repeat(4, 1fr);
}

.sl-multi-table.v4 .controller > button {
	font-family: "Lato-Regular";
    width: 4.36vw;
    height: 2.8vh;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 0.85vw;
    cursor: pointer;
    text-shadow: 1px 2px 2px #262626;
    font-size: 1.2vh;
}

.sl-game.v4 .controller > button.sl-red-sh[disabled],
.sl-game.v4 .controller > button.sl-blue-sh[disabled],
.sl-game.v4 .controller > button.sl-yellow-sh[disabled],
.sl-game.v4 .controller > button.sl-green-sh[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}


.sl-multi-table.v4 .group-timer {
	position: absolute;
	top: 13.5vh;
	left: 0.6vw;
    width: 11.3vw;
	
	font-size: 1.2vh;
}

.sl-multi-table.v4 .group-timer > .timer {
	position: absolute;
	
	color: #e04f4b;
	text-align: center;
	vertical-align: top;
	line-height: 2.5vh;
	
	right: 0.3vw;
}

.sl-multi-table.v4 .group-timer > .timer.bet-closed {
	color: rgb(255,160,160);
	font-size: 1.3vh;
	font-weight: bold;
}

.sl-multi-table.v4 .group-timer > .total-bet {
	line-height: 2.2vh;
}

.sl-multi-table.v4 .group-timer > .total-bet .desc-heading {
	position: absolute;
}

.sl-multi-table.v4 .group-timer > .total-bet .desc-content {
	position: absolute;
	left: 0;
	right: 2vw;
}


/* SINGLE GAME DESKTOP */
.sl-game.v4.sl-single-game-v4 {
	position: relative;
	left: 0;
	top: 0;
	width: 100vw;
	height: unset;
	margin: 0;

	&.header-autohide .stream-container-fullscreen {
		top: 0;
		height: 100vh;
	}
	
	.stream-container-fullscreen {
		position: fixed;

		top: 8vh;
		width: 100vw;
		height: 92vh;

		z-index: -1;
		transform: translateZ(-100px);
		transform-style: preserve-3d;
	}
	
	.stream-container-fullscreen > object {
		width: 100%;
		height: 100%;
	}
}



.sl-game.v4.sl-single-game-v4 .m25-roadmap-container {
	position: fixed;
	left: -48.5vw;
	top: 30vh;

	width: 50.5vw;
	height: 21vh;

	background: white;
	
	transition: 0.5s all;
	z-index: 2;
	
	text-align: left;
}


.sl-game.v4.sl-single-game-v4 .m25-roadmap-container div {
	text-align: center;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.small .row,
.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.small .row > div {
	height: 1vh;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.small .row > div {
	width: 0.5vw;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.small.small-road .row > div,
.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.small.cockroach-road .row > div {
	width: 0.466vw;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-table.v3.bead-road {
	border: 1px solid black;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .dark.roadmap-table.v3.bead-road {
	border: 1px solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .enlarged .fantan .sl-baccarat-bead-value {
	line-height: 2.6vh;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container.active .roadmap-toggle-button {
    transform: translate(1.5vw, -10vh) rotate(90deg);
    height: 2vh;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .roadmap-toggle-button {
	position: absolute;
    right: 0;
    cursor: pointer;
    opacity: 1;
    display: block;
    transform: translate(1vw, -11vh) rotate(90deg);
}



.sl-game.v4.sl-single-game-v4 .m25-roadmap-container > div {
	position: relative;
	display: inline-block;
	vertical-align: top;
	opacity: 0;
	transition: 0.5s all;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container.active {
	left: 0;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container.active > div {
	opacity: 1;
	height: 100%;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container.active > .right-group {
	border-right: 0.1vw solid black;
}

.sl-game.v4.sl-single-game-v4 .m25-roadmap-container .right-group > div:nth-child(2) > div {
	display: inline-block;
	vertical-align: top;
	text-align: left;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container {
	position: relative;
	background: white;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.enlarged {
	border-top: 1px solid lightgray;
	top: 0;
}

.sl-game.v4.sl-single-game-v4 .dark .roadmap-container.enlarged {
	border-top: 1px solid black;
}


.sl-game.v4.sl-single-game-v4 > .baccarat-left-container {
	position: fixed;
	bottom: 8.6vh;
	border-radius: 1vh;
	border: 1vh solid #0c325c;
	z-index: 1;
}

.sl-game.v4.sl-single-game-v4 > .baccarat-left-container > .game-statistics {
	position: relative;
	bottom: unset;
}

.sl-game.v4.sl-single-game-v4 > .baccarat-left-container > .game-statistics .sl-baccarat-bead-value {
	font-size: 1.5vh;
}

.sl-game.v4.sl-single-game-v4 > .baccarat-left-container > .game-statistics td {
	width: 1.1vw;
	height: 2.2vh;
}

.sl-game.v4.sl-single-game-v4 > .roadmap-container {
	position: fixed;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.big-road {
	/* width: 30.2vw; */
	width: 100%;
	height: 8.6vh;
	border-bottom: 1px solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 > .roadmap-container.bead-road {
	bottom: 18vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.big-eye-road {
	display: inline-block;
	vertical-align: top;
	position: relative;
	text-align: center;

	border-right: 0.05vw solid black;
	border-left: 0.05vw solid black;
	border-bottom: 0.2vh solid black;
}

.sl-game.v4.sl-single-game-v4 .dark .roadmap-container.big-eye-road {
	border-right: 0.05vw solid #B41B37;
	border-left: 0.05vw solid #B41B37;
	border-bottom: 0.2vh solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.predictions {
	float: left;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.small-road {
	position: relative;
	display: inline-block;
	vertical-align: top;
	
	border-left: 0.05vw solid #B41B37;
	border-right: 0.05vw solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.cockroach-road {
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-right: 0.05vw solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.predictions .roadmap-table td {
	width: unset;
	height: unset;
}

.sl-game.v4.sl-single-game-v4 .baccarat-roadmap-container {
	position: fixed;
	right: 0;
	bottom: 9vh;
	width: 30.2vw;
}

.sl-game.v4.sl-single-game-v4 .baccarat-roadmap-container > * {
	position: relative;
	display: inline-block;
}

.sl-game.v4.sl-single-game-v4 .baccarat-roadmap-container > .roadmap-container.group {
	white-space: nowrap;
	width: 26.4vw;
	height: 12vh;
}

.sl-game.v4.sl-single-game-v4 .baccarat-roadmap-container > .roadmap-container.predictions tr {
	white-space: normal;
}

.sl-game.v4.sl-single-game-v4 .baccarat-roadmap-container > .roadmap-container {
	position: relative;
	display: inline-block;
	padding: 0;
	margin: 0;
	vertical-align: top;
}

/* GAME STATISTICS */
.sl-game.v4.sl-single-game-v4 .game-statistics {
	position: relative;
	background: white;
	height: 7vh;
}

.sl-game.v4.sl-single-game-v4 .game-statistics > table {
	width: 100%;
	height: 100%;
}

.sl-game.v4.sl-single-game-v4 .game-statistics tr {
	display: block;
	text-align: center;
}

.sl-game.v4.sl-single-game-v4 .game-statistics tr:first-child {
	margin-top: 1vh;
	height: 3vh;
}

.sl-game.v4.sl-single-game-v4 .game-statistics td {
	display: inline-block;
	position: relative;
	width: 1.4vw;
	height: 2.8vh;
	
	margin: 0 1vw;
	vertical-align: top;
}

.sl-game.v4.sl-single-game-v4 > .table-container .game-statistics td {
	margin: 0 1.4vw;
}

.sl-game.v4.sl-single-game-v4 .game-statistics tr:nth-child(2) td div {
	font-size: 2vh;
}

.sl-game.v4.sl-single-game-v4 > .baccarat-left-container .game-statistics {
	height: 8.4vh;
}

.sl-game.v4.sl-single-game-v4 > .baccarat-left-container .game-statistics tr:nth-child(2) td div {
	font-size: 1.6vh;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container {
	background: white;
	display: inline-block;
	width: 3.72vw;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > .baccarat-prediction-letter {
	vertical-align: top;
	display: inline-block;
	
	color: white;
	width: 1.86vw;
	font-size: 2vh;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > .player-container,
.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > .banker-container {
	display: inline-block;
	
	width: 1.86vw;
	text-align: center;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > .player-container {
	background: #d0dbeb;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > .banker-container {
	background: #f1e0e1;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > div > div {
	width: 1.06vw;
	height: 2vh;
	margin: 0.8vh 0.5vw;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container .circle-hollow {
	position: relative;
	background: transparent;
	border-radius: 50%;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container .circle-hollow > div {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	border-width: 2px;
	border-style: solid;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container .sl-roadmap-red {
	border-color: #B41B37;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container .sl-roadmap-blue {
	border-color: #1F5992;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > div > .circle {
	border-radius: 50%;
}

.sl-game.v4.sl-single-game-v4 .baccarat-prediction-container > div > .slash {
	border-bottom-style: solid;
	border-bottom-width: 2px;
	transform: translate(-30%, -30%) rotate(-45deg);
	background: transparent;
}


.sl-game.v4.sl-single-game-v4 .roadmap-table {
	display: inline-block;
	vertical-align: top;
}

.sl-game.v4.sl-single-game-v4 .predictions .roadmap-table > thead {
	visibility: hidden;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.fantan {
	border-bottom: 0.1vh solid black;
}

.sl-game.v4.sl-single-game-v4 .dark .roadmap-container.fantan {
	border-bottom: 1px solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .left-group {
	border-right: 0.05vw solid #B41B37;
}

.sl-game.v4.sl-single-game-v4 .roadmap-table thead .back-text {
	font-weight: normal;
	width: 28vw;
	height: 12vh;
	line-height: 12vh;
	font-size: 4.8vh;
	color: #ddd;
}

.sl-game.v4.sl-single-game-v4 .fantan .roadmap-table thead .back-text {
	height: 8vh;
	line-height: 8vh;
}


.sl-game.v4.sl-single-game-v4 .big-road .roadmap-table thead .back-text {
	line-height: 8vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-table.small .back-text {
	height: 6vh;
	line-height: 6vh;
	font-size: 3.6vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.fantan {
	height: 8.1vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.small-road .back-text,
.sl-game.v4.sl-single-game-v4 .roadmap-container.cockroach-road .back-text {
	width: 12.9vw;
	font-size: 2vh;
}


.sl-game.v4.sl-single-game-v4 .roadmap-table thead .back-text > span {
	display: inline-block;
	vertical-align: middle;
	line-height: initial;
	text-align: center;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.enlarged .roadmap-table tr {
	height: 3.3vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.enlarged .roadmap-table td {
	width: 1.53vw;
	height: 3.3vh;
}

.sl-single-game-v4 .roadmap-container.enlarged .sl-roadmap-slash {
	transform: translate(-40%, -40%) rotate(-45deg);
	border-bottom-width: 1vh;
}

.sl-single-game-v4 .roadmap-container.enlarged .sl-baccarat-diagonal-line.sl-roadmap-green.tie {
	font-size: 2vh;
	line-height: 2vh;
	height: 60%;
	top: 1vh;
	left: 0.3vw;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.bead-road .roadmap-table tr,
.sl-game.v4.sl-single-game-v4 .roadmap-container.fantan .roadmap-table tr {
	height: 2vh;
	display: block;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.bead-road .roadmap-table td,
.sl-game.v4.sl-single-game-v4 .roadmap-container.fantan .roadmap-table td {
	display: inline-block;
	vertical-align: top;
	width: 1.02vw;
	height: 2vh;
	border-right-width: 0.05vw;
	border-bottom-width: 0.1vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-container.fantan .roadmap-table td {
	width: 1.03vw;
}



.sl-game.v4.sl-single-game-v4 .roadmap-table.small tr {
	position: relative;
	display: flex;
	height: 1vh;
}

.sl-game.v4.sl-single-game-v4 .roadmap-table.small tr:nth-child(even) td {
	border-bottom: 1px solid lightgray;
}

.sl-game.v4.sl-single-game-v4 .roadmap-table.small td {
	width: 0.45vw;
	height: 0.9vh;
	border: none;
}

.sl-game.v4.sl-single-game-v4 .small-road .roadmap-table.small td,
.sl-game.v4.sl-single-game-v4 .cockroach-road .roadmap-table.small td {
	width: 0.467vw;
}

.sl-game.v4.sl-single-game-v4 .roadmap-table.small td:nth-child(even) {
	border-right: 1px solid lightgray;
}




.sl-game.v4.sl-single-game-v4 .details {
	position: absolute;
	width: 80%;
	height: 5.4vh;
	line-height: 5.4vh;
	left: 0;
	right: 0;
	margin: auto;
	
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0+0,0.85+30,0.85+70,0+99 */
	background: -moz-linear-gradient(left,  rgba(21,22,36,0) 0%, rgba(21,22,36,0.85) 30%, rgba(21,22,36,0.85) 70%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(21,22,36,0) 0%,rgba(21,22,36,0.85) 30%,rgba(21,22,36,0.85) 70%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(21,22,36,0) 0%,rgba(21,22,36,0.85) 30%,rgba(21,22,36,0.85) 70%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00151624', endColorstr='#00151624',GradientType=1 ); /* IE6-9 */
}

.sl-game.v4.sl-single-game-v4 .details .total-bet,
.sl-game.v4.sl-single-game-v4 .details .total-win {
	display: inline-block;
}

.sl-game.v4.sl-single-game-v4 .details .total-bet {
	margin-right: 15vw;
}

.sl-game.v4.sl-single-game-v4 .details .desc-heading {
	display: inline-block;
	color: #ffffff;
}

.sl-game.v4.sl-single-game-v4 .details .desc-content {
	display: inline-block;
	color: #2bbfa0;
}



.sl-game.v4.sl-single-game-v4 .group-timer {
	position: absolute;
	right: 0;
	width: 12vw;
	height: 58vh;
	text-align: center;
	
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0.9+0,0.9+50,0+99 */
	background: -moz-linear-gradient(top,  rgba(21,22,36,0.9) 0%, rgba(21,22,36,0.9) 50%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6151624', endColorstr='#00151624',GradientType=0 ); /* IE6-9 */
}

.sl-game.v4.sl-single-game-v4 .group-timer .bet-open-text,
.sl-game.v4.sl-single-game-v4 .group-timer .bet-closing-text,
.sl-game.v4.sl-single-game-v4 .group-timer .bet-closed-text {
	font-size: 3vh;
	color: white;
	margin-top: 3vh;
}


.sl-game.v4.sl-single-game-v4 .group-timer .timer {
	font-size: 11vh;
}

.sl-game.v4.sl-single-game-v4 .group-timer .timer.bet-open {
	color: #0ed503;
}

.sl-game.v4.sl-single-game-v4 .group-timer .timer.bet-closing {
	color: #d52b2b;
}

.sl-game.v4.sl-single-game-v4 .group-timer .timer.bet-closed {
	font-size: 4.8vh;
	text-align: center;
	color: #d52b2b;
}

.sl-game.v4.sl-single-game-v4 .group-timer .total-win .desc-heading,
.sl-game.v4.sl-single-game-v4 .group-timer .total-bet .desc-heading {
	color: white;
	font-size: 1.8vh;
}

.sl-game.v4.sl-single-game-v4 .group-timer .total-win,
.sl-game.v4.sl-single-game-v4 .group-timer .total-bet {
	margin-top: 2vh;
}

.sl-game.v4.sl-single-game-v4 .group-timer .total-win .desc-content,
.sl-game.v4.sl-single-game-v4 .group-timer .total-bet .desc-content {
	color: #2bbfa0;
	font-size: 1.8vh;
}


.sl-game.v4.sl-single-game-v4 .table-desc-container {
	position: absolute;
	left: 0;
	top: 0;
	height: 58vh;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151624+0,151624+100&0.9+0,0.9+50,0+99 */
	background: -moz-linear-gradient(top,  rgba(21,22,36,0.9) 0%, rgba(21,22,36,0.9) 50%, rgba(21,22,36,0) 99%, rgba(21,22,36,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(21,22,36,0.9) 0%,rgba(21,22,36,0.9) 50%,rgba(21,22,36,0) 99%,rgba(21,22,36,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6151624', endColorstr='#00151624',GradientType=0 ); /* IE6-9 */
}

.sl-game.v4.sl-single-game-v4 .table-desc-container .desc-heading {
	padding: 1vh 1.36vw;
	color: white;
}

.sl-game.v4.sl-single-game-v4 .table-desc-container .desc-content {
	text-align: right;
	padding: 0 1vw 0 0;
	color: #22deae;
}

.sl-game.v4.sl-single-game-v4 .table-desc span {
	display: block;
	height: 2vh;
}

.sl-game.v4.sl-single-game-v4 .border-bottom {
	border-bottom: 1px solid #353343;
}

.sl-game.v4.sl-single-game-v4 .table .sl-table,
.sl-game.v4.sl-single-game-v4 .table .sl-table-dim {
	position: fixed;
	width: 40vw;
	height: 17vh;
	text-align: center;
	display: block;
	
	margin: auto;
	left: 0;
	right: 3vw;
	top: 57vh;
	bottom: 0;
	
	opacity: 0.85;

	transition: 1s all;
    transform-origin: 50% 100%;
    
	background: transparent;
	/* background-color: #0a3c21; */
}

.sl-game.v4.sl-single-game-v4 .table .sl-table.relaxed,
.sl-game.v4.sl-single-game-v4 .table .sl-table-dim.relaxed {
	top: 64vh;
	transform: perspective(68.1vw) rotateX(45deg);
}

/* SINGLE DESKTOP (V4), CLONE OF BACCARAT V2 */

.sl-game.v4.sl-single-game-v4 .table-container {
	position: fixed;
	margin: auto;
	top: unset;
	left: 0;
	right: 0;
	bottom: 24vh;

	/* transition: 0.5s all; */
    transform-origin: 50% 100%;

	border-radius: 1vh 1vh 0 0;
	/* border: 1vh solid #0c325c; */
	/* background: black; */
	
	width: 60vw;
	height: 22vh;
    
	text-align: center;
}

.sl-game.v4.sl-single-game-v4.ipad .table-container.relaxed {
	-webkit-transform: perspective(68vw) rotateX(40deg) translateY(28%) translateZ(10vh) scale(0.9);
	transform: perspective(68vw) rotateX(40deg) translateY(28%) translateZ(10vh) scale(0.9);

	-webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


.sl-game.v4.sl-single-game-v4 .table-container.m25 {
	width: 60vw;
}

.sl-game.v4.sl-single-game-v4 .table-container.m25.complete {
	width: 82vw;
}

.sl-game.v4.sl-single-game-v4 .table-container .sl-table,
.sl-game.v4.sl-single-game-v4 .table-container .sl-table-dim {
	position: relative;
	width: 100%;
	height: 100%;
}

.sl-game.v4.sl-single-game-v4 .table-container .sl-table-dim {
	top: -115%;
	background: transparent;
}

.sl-game.v4.sl-single-game-v4 .table-container.relaxed {
	height: auto;
	bottom: 9vh;
	transform: perspective(68vw) rotateX(45deg);
}

.sl-game.v4.sl-single-game-v4 .table-container .bet-toggle {
    position: absolute;
    padding: 0.5vh 1vw 0.5vh 1vw;
    box-sizing: border-box;
    right: 2vw;
    top: -4.2vh;
    transition: 1s all;
	cursor: pointer;
}

.sl-game.v4.sl-single-game-v4 .table-container .bet-toggle:after {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	width: 100%;
	height: 120%;
	border-top-left-radius: 1vh;
	border-top-right-radius: 1vh;
	content: "";
	transition: all .2s ease;
	transform: perspective(1vh) rotateX(2deg);
	transform-origin: bottom;
	background: white;
}

.sl-game.v4.sl-single-game-v4 .table-container .bet-toggle > div {
	display: inline-block;
	vertical-align: top;
	font-size: 1.5vh;
}

.sl-game.v4.sl-single-game-v4 .table-container .bet-toggle > img {
	height: 1.5vh;
}



.sl-game.v4.sl-single-game-v4 .history-container {
	position: fixed;
	margin: auto;
	top: unset;
	left: 0;
	right: 0;
	bottom: 20.5vh;

	transition: 1s all;
    transform-origin: 50% 100%;

	border-radius: 1vh 1vh 0 0;
	border: 1vh solid #0c325c;
	background: #0c325c;
	
	width: 32vw;
	height: 4vh;
	transform: perspective(68vw) rotateX(45deg);
    
	text-align: center;

	margin-bottom: 4px;
}

.sl-game.v4.sl-single-game-v4 .history-container.m25 {
	bottom: 30vh;
}

/* SL-TABLE AGAIN */
.sl-game.v4.sl-single-game-v4 .history-container .sl-table,
.sl-game.v4.sl-single-game-v4 .history-container .sl-table-dim {
	position: relative;
	width: 40vw;
	height: 17vh;
}

.sl-game.v4.sl-single-game-v4 .history-container .sl-table-dim {
	top: -115%;
	background-color: rgba(0,0,0,0.5);
	/* background: transparent; */
}

/* .sl-game.v4.sl-single-game-v4 .history-container.relaxed {
	height: 18vh;
	bottom: 8.5vh;
	transform: perspective(68vw) rotateX(25deg);
}
 */

.sl-game.v4.sl-single-game-v4 .history-container.relaxed {
	// opacity: 0;
	bottom: 0;
	margin-bottom: 0;
}


/* Start of stream-container */

.sl-game.v4 .stream-container {
	position: absolute;
	left: 2%;
	top: 4vh;
	transition: all 0.4s;
	
	&:hover {
		z-index: 10;
	}
}

.sl-game.v4 .stream-container > object {
	width: 11vw;
	height: 9vh;
	position: absolute;
	border: 1px solid #41527e;
	transition: all 0.4s;
	border: 2px solid #333d5e;
	left: 0px;
}

.sl-game.v4 .stream-container:hover {
	left: 2%;
}

.sl-game.v4 .stream-container > object:hover {
	border: 1px solid #41527e;
	box-shadow: 0 8px 20px 8px rgba(0,0,0,0.5);
	width: 19.15vw;
    height: 18.5vh;
}

/* End of stream-container */

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .details {
	position: absolute;
	left: 0.43vw;
	top: 15vh;
	width: 7.45vw;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .details > .total-bet > .desc-heading {
	font-size: 1.2vh;
	color: #8b8bb3;
	position: absolute;
	left: 0;
	margin-top: 0.4vh;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .details > .total-bet > .desc-content {
	position: absolute;
	right: 0;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .group-timer {
	position: absolute;
	width: 4.1vw;
	top: 15vh;
	left: 7.66vw;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .group-timer > .timer{
	position: relative;

	height: 100%;
	font-size: 1.8vh;
	color: #e04f4b;
	text-align: center;
	vertical-align: top;
	line-height: 2.5vh;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .group-timer > .timer.bet-closed {
	color: rgb(255,160,160);
	font-size: 1.3vh;
	font-weight: bold;
}




/* Bet controls start here */
.sl-game.sl-single-game-v4 .controller {
	width: 31vw;
	display: grid;
	
	left: 0;
	right: 0;
	margin: auto;
	
	grid-gap: 0.5vw;
	grid-template-columns: repeat(4, 1fr);
	
	transition: 1s all;
}

.sl-game.sl-single-game-v4 .controller button[disabled] {
	opacity: 0.5;
}

.sl-controller-v4 {
	cursor: pointer;
	
	background: transparent;
	border: none;
	outline: none;
	width: 2.1vw;
	height: 4.2vh;

	align-self: center;
	justify-self: center;
	
	background-size: 8.4vw 4.2vh;
}

.sl-controller-v4[disabled] {
	cursor: not-allowed;
}

.sl-controller-v4.undo {
	background-position: 0;
}

.sl-controller-v4.clear {
	background-position: calc(100% - 4.2vw);
}

.sl-controller-v4.rebet {
	background-position: calc(100% - 6.3vw);
}

.sl-controller-v4.submit {
	background-position: calc(100% - 8.4vw);
	/*1 background unit for this pic: 2.1vw 4.2vh*/
}

/* SINGLE GAME v4 */
.sl-game.v4.sl-single-game-v4 > .table-container > .sl-table,
.sl-game.v4.sl-single-game-v4 > .table-container > .controller {
	position: relative;
	background: transparent;
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller {
	top: 0;
	bottom: unset;
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller > button {
	border-radius: 3vh;
	background-color: #727198;
	background-size: unset;
	background-position: unset;
    background-repeat: no-repeat;
    background-position: center;

	width: 4.2vw;
}

.sl-game.v4.sl-single-game-v4 > .table-container.relaxed > .controller {
	opacity: 0;
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller .sl-controller-v4.undo {
	background-image: url("../../assets/img/svg/controller/undo.svg");
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller .sl-controller-v4.clear {
	background-image: url("../../assets/img/svg/controller/clear.svg");
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller .sl-controller-v4.rebet {
	background-image: url("../../assets/img/svg/controller/rebet.svg");
}

.sl-game.v4.sl-single-game-v4 > .table-container > .controller .sl-controller-v4.submit {
	background-image: url("../../assets/img/svg/controller/submit.svg");
}
/* Bet controls end here */

.sl-multi-table.v4 .game-statistics {
	position: absolute;
	bottom: 3.5vh;
	left: 0.5vw;
	width: 11.3vw;
}

.sl-multi-table.v4 .game-statistics table {
	width: 100%;
}

.sl-multi-table.v4 .game-statistics .desc-heading,
.sl-multi-table.v4 .min-max-container .desc-heading {
	color: #8b8bb3;
}

.sl-multi-table.v4 .game-statistics .desc-content,
.sl-multi-table.v4 .min-max-container .desc-content {
	color: #21deab;
}


/* Multibet */
.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .table {
	padding-top: 1vh;
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .controller {
	width: 19.15vw;
	padding-top: 0.6vh;
	display: grid;
	grid-gap: 0.5vw;
	grid-template-columns: repeat(4, 1fr)
}

.sl-game.v4:not(.sl-game.v4.sl-single-game-v4) .controller > button {
	font-family: "Lato-Regular";
    width: 4.36vw;
    border: none;
    color: white;
    font-weight: bold;
    height: 2.8vh;
    border-radius: 16px;
    cursor: pointer;
	
	text-shadow: 1px 2px 2px #262626;
}

.sl-game.sl-multi-table.v4 .min-max-container {
	bottom: 0.8vh;
	left: 2.13vw;
	font-size: 1.2vh;
}

.sl-game.v4.sl-single-game-v4 .desc-heading,
.sl-game.v4.sl-single-game-v4 .desc-content {
	font-size: 1.4vh;
}

.sl-game.v4.sl-single-game-v4 .switch-container {
	position: absolute;
	right: 0.2vw;
	bottom: 0.2vh;
}
 
 
/* MULTI TABLE DESKTOP */
.sl-multi-table.v4 .fullsize {
	position: absolute;
	bottom: 1vh;
	left: 1.8vw;
	height: auto;
}

.sl-multi-table.v4 .roadmap-table {
	border-collapse: collapse;
}

.sl-multi-table.v4 .roadmap-table thead .back-text {
	font-weight: normal;
	width: 11vw;
	height: 7vh;
	line-height: 7vh;
	font-size: 3vh;
	color: #ddd;
}

.sl-multi-table.v4 .roadmap-table {
	background: white;
	border-collapse: collapse;
	position: relative;
}

.sl-multi-table.v4 .roadmap-table th,
.sl-multi-table.v4 .roadmap-table thead tr {
	height: 0;
	position: absolute;
	
}

.sl-multi-table.v4 .roadmap-table tr {
	display: block;
	height: 1.2vh;
	border-bottom: 1px solid #e9e9e9;
}

.sl-multi-table.v4 .roadmap-table .sl-roadmap-circle-hollow {
	height: 60%;
	width: 60%;
}

.sl-multi-table.v4 .roadmap-table td {
	display: inline-block;
	position: relative;
	width: 0.4vw;
	height: 1vh;
	border-right: 1px solid #e9e9e9;
	vertical-align: top;
}

/* Game result history */
.sl-multi-table.v4 > .roadmap-table thead .back-text {
	width: 9vw;
}

.sl-multi-table.v4 > .roadmap-table {
	position: absolute;
	bottom: 3.5vh;
	left: 0.4vw;
 	
	width: 11.5vw;
	height: 7vh;
}

@mixin dead-centre {
	position: absolute;
    top: 0;
	left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
}

.sl-multi-table.v4 > .roadmap-table.m25 > .roadmap {
	@include dead-centre;
}

.sl-multi-table.v4 > .roadmap-table .sl-baccarat-bead-value {
	font-size: 0.9vh;
	line-height: 1.4vh;
}

.sl-multi-table.v4 .game-history-buttons > div {
	width: 3.8vw;
	display: inline-block;
	vertical-align: top;
	background: #353751;
}

.sl-multi-table.v4 .game-history-buttons > div.active {
	background: #41527e
}

.sl-multi-table.v4 .min-max-container {
    bottom: 3vh;
    left: 0.5vw;
    width: 11.3vw;
}

.sl-multi-table.v4 .min-max-container > table {
	width: 100%;
	height: 100%;
}

.sl-multi-table.v4 .min-max-container > table tr { 
	display: block;
	height: 1.7vh;
}

.sl-multi-table.v4 .min-max-container > table td {
	display: inline-block;
}

.sl-multi-table.v4 .min-max-container > table td:first-child,
.sl-multi-table.v4 .min-max-container > table td:nth-child(3) {
	width: 45%;
}

.sl-multi-table.v4 .min-max-container .desc-heading,
.sl-multi-table.v4 .min-max-container .desc-content {
	font-size: 1vh;
}
