@mixin middle {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.game-result-history-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	z-index: 14;
	background: rgba(0,0,0,0.8);

	text-align: center;
	color: white;

	line-height: 10vh;

	& > img {
		position: relative;
	}

	&.landscape > img {
		top: 1vh;
		height: 8vh;
	}

	&.portrait > img:first-child {
		top: 1.5vh;
		height: 4vh;

		z-index: 25; //Same as BetModal
	}
}

$lightGreen: #22deae;

.lobby-mobile-game-result-history-popup-list {
	position: fixed;
	background: #222336;
	color: white;
	text-align: left;
	overflow-y: scroll;
	cursor: pointer;
	z-index: 14;

	& > div {
		border-top:  1px solid #b5b5d2;
		border-right: 1px solid #8b8bb3;

		&.active {
			border-right: 5px solid #21deab;
			color: #21deab;
		}

		&.long-text > div {
			line-height: initial;
			display: inline-block;
			vertical-align: middle;
			font-size: 80%;
		}
	}

	&.portrait {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 30vh;

		z-index: 15;
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		& > div {
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			
			border: 1px solid white;

			height: 6vh;
			font-size: 2vh;

			text-align: center;
		}
	}

	&.landscape {
		left: 0vw;
		top: 11vh;
		font-size: 3vh;
		height: 90vh;

		& > div {
			width: 8vw;
			padding: 2vh 0;
		}
	}


	&.mobile-home.portrait {
		height: 38vh;
	}

	&.mobile-home.landscape {
		overflow-y: scroll;
		text-align: center;
	}


	&.desktop {
		left: 10vw;
		top: 19vh;
		font-size: 1.7vh;
		height: 67vh;

		& > div {
			width: 10vw;
			padding: 2vh 1vw;
		}
	}
}


.side-transaction-history-panel.mobile-home.landscape,
.side-result-history-panel.mobile-home.landscape {
	top: 10vh;
}

.side-result-history-panel.mobile-home,
.side-result-history-panel.sl-single-mobile {
	overflow: hidden;
}



// RESULT HISTORY TABLE
.result-history-table {
	border: 1px solid white;
	border-collapse: collapse;
	color: white;
	text-align: center;

	th {
		border: 1px solid white;
		color: #22deae;
		padding: 0; //1vw
		background-color: #151624;
	}
	tr {
		background-color: #222336;
	}
	tr:nth-child(2n){
		background-color: #151624;
	}

	tr td {
		border: 1px solid white;
	}

	td {
		position: relative;
	}

	td > div {
		@include middle;

		border-radius: 50%;
	}
	
			
	.small-font {
		font-size: 60%;
	}
}

.landscape .result-history-table {
	width: 100%;
	height: 90%;
}

// RESULT HISTORY TABLE LOADING OVERLAY
.game-result-history-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;

	background: rgba(0,0,0,0.5);
	color: white;
}

// SIDE RESULT HISTORY PANEL
.side-result-history-panel {
	position: fixed;
	right: 0;

	background: #0D0D15;

	z-index: 14;
}



// SIDE RESULT HISTORY PANEL
// MOBILE LANDSCAPE
.side-result-history-panel.landscape {
	top: 10vh;
	height: 90vh;
	width: 85vw;
	overflow-y: auto;

	.result-history-table {
		font-size: 3vh;

		.header-result-history-win {
			min-width: 7vh;
		}

		//Circles
		td > div {
			width: 6vh;
			height: 6vh;
			line-height: 6vh;
		}

		img {
			height: 5vh;
			margin: 0 0.1vw;
		}
	}
}

.side-result-history-panel.portrait {
	overflow-y: scroll;
}

.side-result-history-panel.portrait .result-history-table .m27-img {
	height: 10vh;
}


.side-result-history-panel.landscape .result-history-table .m27-img {
	height: 20vh;
}

.side-result-history-panel.desktop {
	.btn-detail {
		border: none;
		outline: none;
		cursor: pointer;
		padding: 4px 8px;
		border-radius: 5px;

		&:hover {
			box-shadow: 0 2px 6px 0 rgba($color: #fff, $alpha: .4);
		}
	}
}

// DESKTOP
.side-result-history-panel.desktop.landscape {
	position: fixed;
    top: 19vh;
    right: 14vw;

	height: 62vh;
	width: 60vw;

	.result-history-table {
		height: 100%;

		font-size: 1.5vh;

		.header-result-history-win {
			min-width: 5vh;
		}

		//Circles
		td > div {
			width: 4vh;
			height: 4vh;
			line-height: 4vh;
		}
		
		td > .reset-width {
			width: unset;
			text-align: center;
		}
		
		td > div > div {
			width: 4vh;
			height: 4vh;
			line-height: 4vh;
			border-radius: 2vh;
			
			display: inline-block;
		}

		img {
			height: 4vh;
			margin: 0 0.1vw;
		}
	}

	&.history-detail {
		height: 67vh;
		top: 16vh;
		background: transparent;

		.btn-back {
			margin-bottom: 4px;
		}

		.result-history-table {
			height: calc(100% - 3vh - 4px);
		}
	}
}



// MOBILE PORTRAIT
.side-transaction-history-panel.portrait,
.side-result-history-panel.portrait {
	position: fixed;

	height: 86vh;
	width: 100vw;

	bottom: 0;

	.result-history-table {
		width: 100%;

		font-size: 1.6vh;

		td {
			height: 3vh;
		}

		td > div {
			width: 3vh;
			height: 3vh;
			line-height: 3vh;
		}

		img {
			height: 3vh;
			margin: 0 0.5vw;
		}
	}
}

.side-result-history-panel.mobile-home.portrait {
	height: 90vh;
}


//TRANSACTION HISTORY TABLE
.transaction-history-table {
	 border-collapse: collapse;
	color: #b5b5d2;
	text-align: center;
	width: 100%;
	tr:hover {
		background: #0D0D15;
	}
	th,
	tr > td {
		border: 1px solid white;
	}

	th {
		color: #22deae;
	}

	// Transaction history detail
	tr.transaction-detail-child {
		th, td {
			padding: 0;
		}

		& > td {
			padding: unset;
			height: unset;
			line-height: 0;
			transition: 0.5s;

			& > table {
				background: #4e6497;

				border-collapse: collapse;
				width: 100%;

				th, td {
					display: block;
					border: unset;

					font-size: 0;
					line-height: 0;
					height: 0;

					padding: 0;

					transition: 0.5s;
				}

				td > .converted {
					display: none;
				}

				img {
					display: none;
				}
			}
		}

		// Transaction history detail
		// Active
		&.active > td {
			border: initial;
			height: initial;

			& > table {
				th, td {
					display: table-cell;
					border: 1px solid white;

					font-size: inherit;
					line-height: initial;
					height: initial;

					padding: initial;
				}

				td > .converted {
					display: inline-block;

					width: 20px;
					height: 20px;

					margin: 1px;
				}

				img {
					display: initial;
				}
			}
		}
	}

	.red-text {
		color: #ff715f;
	}

	.blue-text {
		color: #00e5ff;
	}

	.yellow-text {
		color: #ffeb3b;
	}

	.green-text {
		color: lightgreen;
	}
}

//Basic shapes
.transaction-history-table {
	.circle {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		line-height: 30px;
		margin: 4px;

		display: none;
	}

	tr.transaction-detail-child.active {
		.circle {
			display: inline-block;
		}
	}

	.bg-red {
		background: red;
	}

	.bg-black {
		background: black;
		color: white;
	}
}


.transaction-history-table .detail-transaction img {
	display: inline-block;
	vertical-align: middle;
}


//Custom image size for mobile portrait and landscape Transaction History
.transaction-history-table tr.transaction-detail-child.active > td > table img {
	width: auto;
	height: 40px;
}

//Custom image size for desktop Transaction History
.transaction-history-table.desktop tr.transaction-detail-child.active > td > table div > img {
	height: 3vh;
	margin: 1vh 0.3vw;
	display: inline-block;
	vertical-align: middle;
}

.transaction-history-table.desktop tr.transaction-detail-child.active > td > table div > div {
	margin-left: 0.5vw; 
}



// TRANSACTION HISTORY TABLE OVERLAY
.game-transaction-history-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;

	background: rgba(0,0,0,0.5);
	color: white;
}



// TRANSACTION HISTORY PANEL
.side-transaction-history-panel {
	right: 0;

	z-index: 14;

	overflow-y: scroll;

	background: #0D0D15;
}

// MOBILE LANDSCAPE
// TRANSACTION HISTORY PANEL
.side-transaction-history-panel.landscape {
	position: fixed;
	top: 10vh;

	height: 90vh;
	width: 85vw;

	overflow-y: scroll;
	
	.popup-header  {
		display: none;
	}

	.pagination {
		position: fixed;
		left: 8vw;
		top: 10vh;

		height: 90vh;
		box-sizing: border-box;
	}

	//MOBILE LANDSCAPE
	//TRANSACTION HISTORY TABLE
	.transaction-history-table {
		font-size: 3vh;

		& > tbody > th,
		& > tbody > tr:not(.transaction-detail-child) > td {
			padding: 0.7vh 1vw;
		}

		tr.transaction-detail-child {
			&.active > td {
				padding: 1vh 1vw 3vh 1vw;
			}
		}
	}
}

// MOBILE PORTRAIT
// TRANSACTION HISTORY PANEL
.portrait {
	&.mobile-home {
		.transaction-history-table {
			position: relative;
			margin-top: 5vh;
		}
	}

	//MOBILE PORTRAIT
	//TRANSACTION HISTORY TABLE
	.transaction-history-table tr.transaction-detail-child.active > td > table img {
		height: 3vh;
	}
}

//DESKTOP
//TRANSACTION HISTORY TABLE
.transaction-history-table.desktop {
	position: relative;

	background: #0D0D15;

	cursor: pointer;

	font-size: 1.4vh;
	thead tr {
		background-color: #151624;
	}
	tr{
		background-color: #222336;
	}
	tbody tr {
		overflow-y: scroll;
	}
	tbody tr:nth-child(3),
	tbody tr:nth-child(7),
	tbody tr:nth-child(11),
	tbody tr:nth-child(15),
	tbody tr:nth-child(19),
	tbody tr:nth-child(23),
	tbody tr:nth-child(27){
		background-color: #151624;
	}
	& > tbody > tr:not(.transaction-detail-child):hover {
		transition: 0.3s;
		background: $lightGreen;
		color: black;
	}

	th,
	tr > td {
		padding: 0.8vh 1vw;
	}

	tr.transaction-detail-child {
		th, td {
			padding: 0;
		}

		&.active > td {
			padding: 1vh 1vw 3vh 1vw;
		}
	}
}


// MOBILE PORTRAIT
// TRANSACTION HISTORY PANEL
.side-transaction-history-panel.portrait {
	position: fixed;
	font-size: 1.5vh;
}

.side-transaction-history-panel.mobile-home.portrait {
	height: 90vh;
}



// TAB CONTAINER
// MOBILE PORTRAIT
.side-result-history-panel.portrait {
	.tab-container {
		display: table;
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		text-align: center;

		height: 6vh;
		line-height: 6vh;

		& > div {
			display: table-cell;
			line-height: normal;
			vertical-align: middle;
			
			color: white;
			border-right: 1px solid #22deae;

			transition: 0.4s;

			&.active {
				background-color: #22deae;
				color: black;
			}
		}
	}
}



// GENERIC PAGINATION
.pagination {
	background: #0D0D15;

	font-family: Lato-Regular;
	
	list-style-type: none;
	text-align: center;
	margin: 0;
	padding: 0;
	cursor: pointer;

	li {
		display: inline-block;
		text-decoration: none;
	}

	li.active {
		background-color: $lightGreen;
		color: black;
		vertical-align: top;
	}

	li.disabled {
		// color: #b0bec5;
		color: white;
	}

	li > .link {
		display: inline-block;
		width: 100%;
		color: #22deae;
	}

	li:hover .link {
		background-color: #313547;
	}
}

.portrait .pagination {
	margin-top: 1vh;
	width: 99vw;
}

.landscape .pagination {
	position: fixed;
	left: 8vw;
	top: 10vh;
	width: 7vw;
	height: 90vh;
	box-sizing: border-box;
}



// LANDSCAPE RESULT HISTORY PAGINATION
.side-transaction-history-panel.landscape,
.side-result-history-panel.landscape {
	.pagination {
		left: 8vw;

		width: 7vw;
		height: 90vh;

		li {
			width: 7vw;
			padding: 0.9vh 0;
		}
	}
}

// DESKTOP RESULT HISTORY PAGINATION
.side-result-history-panel.desktop.landscape {
	.pagination {

		position: fixed;
		text-align: center;
		width: 60%;
		height: 5%;
		left: 26%;
		padding: .4vw 0;
		top: 82%;

		li {
			margin: 0 1px;
			width: 4vw;
			padding: 1.2vh 0;
			font-size: 1.65vh;
		}
	}
}


// PORTRAIT RESULT HISTORY PAGINATION
.side-result-history-panel.portrait {
	li {
		// width: calc(100vw / 12);
		padding: 0.8vh 2vw;
	}
}

.side-transaction-history-panel.portrait {
	li {
		padding: 0.8vh 3vw;
	}
}



// MOBILE HOME LANDSCAPE
// TRANSACTION HISTORY PANEL PAGINATION
.side-transaction-history-panel.mobile-home.landscape .pagination {
	width: 15vw;
	left: 0;

	li {
		width: 15vw;
	}
}

//REFRESH BUTTON
.popup-header > .refresh {
	position: absolute;
	border: 1px solid white;
	box-sizing: border-box;
	cursor: pointer;
}

// DESKTOP RESULT HISTORY PAGINATION
.iframe-container.result-history {
	overflow: auto;
	z-index: 100;
	left: 10vw;
	right: 10vw;
	width: 80%;
	height: 80vh;
	display: block;
	padding-bottom: 1vw;
	background-color: #0D0D15;
	box-sizing: border-box;

	.popup-header {
		top: 10vh;
		left: 10%;
		right: 10%;
		width: 80%;
		position: fixed;
		/* padding: 1vh 0; */
		overflow: visible;
		font-size: 2vh;
		font-weight: bold;
		background-color: #6c66fb;
		/* border-radius: 0.5em 0.5em 0 0; */
		text-align: center;

		height: 40px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		padding: 0 0.5vw;

		& > p {
			flex: 1;
		}

		.sl-ic-close {
			margin-top: unset;
			margin-right: unset;
			float: unset;
		}
	}

	.lobby-mobile-game-result-history-popup-list.desktop {
		top: calc(10vh + 40px);
		height: calc(80vh - 40px);
	}

	.side-result-history-panel.desktop.landscape {
		.pagination {
			top: unset;
			bottom: calc(10vh + 4px);
		}
	}
}

// DESKTOP TRANSACTION HISTORY PAGINATION
.iframe-container.transaction-history {
	overflow: auto;
	z-index: 100;
	left: 10vw;
	right: 10vw;
	width: 80%;
	height: 80vh;
	display: block;
	// padding-bottom: 1vh;
	background-color: rgba(13, 13, 21, 1);
	box-sizing: border-box;
	padding-bottom: 60px;

	.popup-header {
		top: 10vh;
		width: 80%;
		position: fixed;
		padding: 1vh 0;
		overflow: visible;
		font-size: 2vh;
		font-weight: bold;
		background-color: #6c66fb;
		/*border-radius: .5em .5em  0 0;*/
		text-align: center;

		height: 40px;
		padding: 0 0.5vw;
		box-sizing: border-box;
	}
	
	//Refresh button
	.popup-header > .refresh {
		top: 1vh;
		right: 3vw;
		border-radius: 1vh;
		font-size: 1.5vh;
		line-height: 3vh;
	}
	
	.popup-header .sl-ic-close {
		position: absolute;
		top: 0.5vh;
		right: 0.5vw;
		cursor: pointer;
	}

	.pagination {
		position: fixed;
		text-align: center;
		width: 80%;
		height: 5%;
		z-index: 0;
		// top: 80%;
		bottom: 10vh;
		left: 10%;

	}

	.transaction-history-table.desktop {
		margin-top: 44px;
	}

	.pagination li {
		width: 2vw;
		height: 4vh;
		margin: 0 10px;
		line-height: 4vh;
		font-size: 1.5vh;
		// padding: 0.8vh 0.5vw;
	}
}

.desktop .iframe-container.transaction-history {
	.popup-header {
		display: flex;
		align-items: center;
		justify-content: center;

		& > .refresh {
			top: unset;
			// height: 3vh;
		}

		& > .sl-ic-close {
			top: unset;
			margin-top: 0;
			margin-right: unset;
		}	
	}	
}

.side-transaction-history-panel.portrait {
	.popup-header {
		color: white;
		width: 100%;
		left: 0;
		top: unset;
		bottom: unset;
	}
	
	.popup-header > .refresh {
		top: 1vh;
		right: 10vw;
		border-radius: 2vh;
		line-height: 3vh;
		font-size: 1.5vh;
	}
	
	.popup-header .sl-ic-close {
		display: none;
	}

	.transaction-history-table.desktop {
		margin-top: 44px;
	}
}