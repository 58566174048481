.maintenance-page {
	position: fixed;
	width: 100%;
	height: 100%;
}

.maintenance-page img.background {
	width: 100vw;
	height: 100vh;
}

.maintenance-page img.wrench {
	position: fixed;
	width: 15%;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 35%;
}

.maintenance-page.v1 img.wrench {
    width: 13vw;
    height: 14vh;
    bottom: 38vh;
}