.popup-header {
    top: 10vh;
    left: 10%;
    right: 10%;
    width: 80%;
    position: fixed;
    padding: 1vh 0;
    overflow: visible;
    font-size: 2vh;
    font-weight: bold;
    background-color: #6c66fb;
    /*border-radius: .5em .5em  0 0;*/
    text-align: center;
    
    .sl-ic-close {
        margin-top: 0.5vh;
		margin-right: 0.5vw;
        float: right;
        cursor: pointer;
    }
}

.iframe-container {
    width: 80%;
    left: 10%;
    right: 10%;
    height: 75%;
    background-color: #222336;
    padding-left: -10vw;
}