/* .sl-multi-table */
/* .sl-single-game */
.table-p7 .sl-bet {
	text-align: center;
    border: 1px solid #0a3c21;
}

.table-p7 .w-4{
    display: inline-block;
    width: 4%;
}

.table-p7 .w-8{
    display: inline-block;
    width: 8%;
}

.table-p7 .w-10{
    display: inline-block;
    width: 10%;
}

.table-p7 .w-23{
    display: inline-block;
    width: 23%;
}

.table-p7 .w-24{
    display: inline-block;
    width: 24%;
}

.table-p7 .w-33{
    display: inline-block;
    width: 33.33%;
}

.table-p7 .w-33 .sl-bet  {
	width: 25%;
}

.table-p7 .w-80{
    display: inline-block;
    width: 80%;
}

.table-p7 .w-83{
    display: inline-block;
    width: 83%;
}

.table-p7 .w-85{
    display: inline-block;
    width: 85%;
}

.table-p7 .w-100{
    display: inline-block;
    width: 100%;
}

.table-p7 .mainbox-bet .dp-flex{
    display: flex;
}

.table-p7 .sides {
	vertical-align: top;
}

.table-p7 .col-bet{
	white-space: nowrap;
}

.table-p7 .p7-diamond {
    width: 1.17vw;
    height: 2.34vh;
    transform: rotate(45deg);
    border-radius: 4px;
    margin-top: 17%;
    margin-left: 28%;
}


// DESKTOP MULTI TABLE
.sl-multi-table .table-p7 .mainbox-bet .sl-bet {
	height: 3.8vh;
	line-height: 3.8vh;
	font-size: 1.2vh;
}

.sl-multi-table .table-p7 .sides .trio {
	width: 1.6vw;
	height: 4vh;
	line-height: 4vh;
}

.sl-multi-table .table-p7 .sides:first-child .sl-bet {
	width: 1.5vw;
}

.sl-multi-table .table-p7 .sides .solo{
	height: 11.3vh;
	text-align: center;
	line-height: 11.3vh;
	font-size: 1.5vh;
}

.sl-multi-table .table-p7 .sides .trio {
	height: 3.9vh;
	line-height: 3.9vh;
	text-align: center;
	vertical-align: middle;
	font-size: 1.1vh;
}

.sl-multi-table .table-p7 .sides .trio > span {
	display: inline-block;
	vertical-align: middle;
	line-height: initial;
}


/* DESKTOP SINGLE */
.sl-single-game .table-p7 .w-33 .sl-bet {
    height: 37px;
}

.sl-single-game .sl-bet {
	font-size: 16px;
}

.sl-single-game .table-p7 .sides .solo {
	height: 119px;
	line-height: 119px;
}

.sl-single-game .table-p7 .p7-diamond {
	width: 22px;
	height: 22px;
    margin-top: 6%;
    margin-left: 44%;
}

.sl-single-game .w-100 .solo{
    height: 118px
}

.sl-single-game .table-p7 .sides .trio,
.sl-single-game .table-p7 .mainbox-bet .sl-bet {
	height: 39px;
	line-height: 39px;
	font-size: 18px;
}

// MOBILE PORTRAIT
.sl-single-mobile.portrait .sl-bet.p7-tall {
	height: 23.6vh;
	line-height: 23.6vh;
	float: right;
}

/* MOBILE LANDSCAPE */
.landscape .table-p7 .p7-diamond {
	width: 22px;
	height: 22px;
}

.landscape .table-p7 .sides .solo {
	font-size: 15px;
	line-height: 115px;
}

.landscape .table-p7 .mainbox-bet .sl-bet,
.landscape .table-p7 .sides .trio {
	font-size: 8px;
}


.landscape .table-p7 .sides:first-child .sl-bet {
	width: 29px;
	height: 112px;
}


.landscape .table-p7 .mainbox-bet .sl-bet {
	height: 37px;
	line-height: 37px;
}

.landscape .table-p7 .w-33 .sl-bet {
	width: 25%;
	height: 37px;
}

.landscape .table-p7 .sides .trio {
	height: 37px;
	line-height: 37px;
}
